.banner
  +i(1/1)
  height: 9.5vw
  border-radius: 10px
  overflow: hidden
  background-image: url('../../images/open-banner-no-btn.jpg')
  background-size: cover
  background-position: center
  transition: all ease-in-out .2s
  margin-bottom: 2em
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)

.info-banner
  @extend .fadeIn
  background: #FFFFFF
  border: 1px solid #F6F6FA
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.1)
  border-radius: 10px
  padding: 12px 16px
  display: flex
  align-items: center
  gap: 20px
  flex-wrap: wrap

  &__inner
    flex-grow: 1
    margin-left: 28px
    position: relative

  &__icon
    color: var(--color-primary)
    background-color: var(--color-primary-lightest)
    width: 22px
    height: 22px
    font-size: 1.2rem
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50px
    position: absolute
    left: -28px
    top: 0

  &__title
    margin-bottom: 0
    color: #000
    font-weight: 700
    font-size: 1.4rem
    margin-bottom: 0

  &__description
    margin-bottom: 0
    font-weight: 300
    font-size: 1.4rem
    color: var(--color-neutral)
