.end-scroll
	padding: 1em
	text-align: center
	width: 100%
	position: relative
	box-sizing: border-box

	img
		width: 32px

	&::after
		content: ""
		width: 30%
		height: 1px
		background: rgba(var(--color-primary-light), .3)
		position: absolute
		top: 50%
		right: 0px

	&::before
		content: ""
		width: 30%
		height: 1px
		background: rgba(var(--color-primary-light), .3)
		position: absolute
		top: 50%
		left: 0px
