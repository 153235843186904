.side-content
	background: white
	@extend .fadeIn
	box-shadow: $dropshadow
	border-radius: 10px
	padding: 2em
	+i(1/1, md 9/20)
	min-height: 75vh

	&--full
		+i(1/1, md 1/1)

	&--journey
		+i(1/1, md 10/20)

	&--success
		+g(full, middle, center)
		+i(1/1)

		img
			margin: 1em
			width: 40px
			height: 40px

	&--donate
		+i(1/1, md 9/20)
		background: white
		box-shadow: $dropshadow
		border-radius: 10px
		padding: 2em 2em
		min-height: 300px

	&__title
		font-size: 1.1em
		font-weight: 700
		color: var(--color-primary-dark)
		&--success
			+i(1/1)

			h1
				text-align: center
				color: var(--color-secondary)
		&--lg
			font-size: 2rem
			margin-bottom: 2rem

	&__description
		&--success
			+g(full, center)
			margin-top: 3em
			font-weight: 700
			strong
				text-align: center
				+i(1/1)
		&--sys
			+g(full, center)
			+i(1/1)
			margin-top: 3em
			font-size: 18px
			color: var(--color-primary)
			font-weight: 800

	&__subtitle
		font-size: .9em
		margin-bottom: 1.5em

	&--transparent
		padding: 0
		background: none
		box-shadow: none
