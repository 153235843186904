.new-survey
  height: 0
  flex-grow: 1
  overflow-y: auto
  display: flex
  flex-direction: column
  gap: 40px
  margin: 20px 0

  &__question
    display: flex
    flex-direction: column
    gap: 8px

  &__title
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.4rem
    font-weight: 700
    margin-bottom: 0
