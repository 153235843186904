@charset "utf-8"

// ========================================
//   application.
//   This file is automatically updated by itcsscli.
// ========================================

// [0] Requirements --- Vendor libraries
// [1] Settings ------- Sass vars, etc.
// [2] Tools ---------- Functions and mixins.
// [3] Generic -------- Generic, high-level styling, like resets, etc.
// [4] Base ----------- Unclasses HTML elements (e.g. `h2`, `ul`).
// [5] Objects -------- Objects and abstractions.
// [6] Components ----- Your designed UI elements (inuitcss includes none of these).
// [7] Trumps --------- Overrides and helper classes.
@import "./themes/theme.unisescoop"
@import "./themes/theme.default"
@import "./themes/theme.demo"
@import "./themes/theme.inkluziva"
@import "./themes/theme.maiseducacao"
// @import "./themes/theme.tjpara"
@import "./themes/theme.canaleducacao"
@import "./themes/theme.vegalabs"
@import "./themes/theme.egepi"
@import "./themes/theme.siape"
@import "./themes/theme.encceja"
@import "./themes/theme.pit"
// [0] Requirements
@import "./requirements/requirements.vendor"
@import "./requirements/requirements.react-select"
@import "./requirements/requirements.react-calendar"
@import "./requirements/requirements.react-daterange-picker"

// [1] Settings
@import "./settings/settings.grid.scss"
@import "./settings/settings.fonts"
@import "./settings/settings.global"
@import "./settings/settings.animations"
@import "./settings/settings.utilities"

// [2] Tools
@import "./tools/tools.intrinsic-ratio"
@import "./tools/tools.ellipsis"
@import "./tools/tools.motion"

// [3] Generic
@import "./generic/generic.reset"
@import "./generic/generic.animations"

// [4] Base
@import "./base/base.page"
@import "./base/base.type"

// [5] Objects
@import "./objects/objects.dropshadow"
@import "./objects/objects.buttons"
@import "./objects/objects.form"
@import "./objects/objects.tagline"
@import "./objects/objects.feed-banner"
@import "./objects/objects.donate-banner"
@import "./objects/objects.tags"
@import "./objects/objects.loader"
@import "./objects/objects.tab-menu"
@import "./objects/objects.infinite-scroll-component"
@import "./objects/objects.scrollbar"
@import "./objects/objects.breadcrumb"
@import "./objects/objects.progressbar"
@import "./objects/objects.tooltip"
@import "./objects/objects.toast"
@import "./objects/objects.infinite-scroll"

// [6] Components
@import "./components/components.navbar"
@import "./components/components.terms"
@import "./components/components.login"
@import "./components/components.main-sidebar"
@import "./components/components.main-nav"
@import "./components/components.registration"
@import "./components/components.home-content"
@import "./components/components.post"
@import "./components/components.post-comment"
@import "./components/components.post-form"
@import "./components/components.post-list"
@import "./components/components.question-card"
@import "./components/components.questions-options"
@import "./components/components.comming-soon"
@import "./components/components.end-scroll"
@import "./components/components.side-content"
@import "./components/components.donate-card"
@import "./components/components.subject-list"
@import "./components/components.empty-state"
@import "./components/components.chart-content"
@import "./components/components.profile-page"
@import "./components/components.magic-link"
@import "./components/components.archive-questions"
@import "./components/components.questions-sidebar"
@import "./components/components.banner"
@import "./components/components.page"
@import "./components/components.modal"
@import "./components/components.modal-taxonomy"
@import "./components/components.progress"
@import "./components/components.card"
@import "./components/components.filter-bar"
@import "./components/components.widget"
@import "./components/components.badge"
@import "./components/components.expanded-calendar"
@import "./components/components.sidebar"
@import "./components/components.new-questions"
@import "./components/components.calendar-task-modal"
@import "./components/components.open-register"
@import "./components/components.nav"
@import "./components/components.taxonomies"
@import "./components/components.dropdown"
@import "./components/components.user-dashboard-task-list"
@import "./components/components.open-ranking"
@import "./components/components.feedback"
@import "./components/components.pagination"
@import "./components/components.timeline"
@import "./components/components.multi-select"
@import "./components/components.matter-performance"
@import "./components/components.chart-performance"
@import "./components/components.notes"
@import "./components/components.notifications"
@import "./components/components.survey"
@import "./components/components.star-rating"
@import "./components/components.chart"
@import "./components/components.intervention-proposal"
@import "./components/components.data-grid"
@import "./components/components.course-card"
@import "./components/components.show-course"
@import "./components/components.show-course-main-content"
@import "./components/components.show-course-sidebar"
@import "./components/components.avatar"
@import "./components/components.quiz"
@import "./components/components.classroom-video-content"
@import "./components/components.main-content-base"
@import "./components/components.class-modules"
@import "./components/components.labeled-container"
@import "./components/components.schedule"
@import "./components/components.activity-log"
@import "./components/components.course-slider"
@import "./components/components.alert"
@import "./components/components.help-center"
@import "./components/components.profile"
@import "./components/components.error-404"
@import "./components/components.page-detail"
@import "./components/components.box-widget"
@import "./components/components.check-card"
@import "./components/components.kpi-widget"
@import "./components/components.new-login"
@import "./components/components.profile-resume"
@import "./components/components.success-modal"
@import "./components/components.medal"
@import "./components/components.certificate"
@import "./components/components.module-list"
@import "./components/components.filter-multi-select"
@import "./components/components.first-time"
@import "./components/components.list-alternatives"
@import "./components/components.student-response"
@import "./components/components.questions-menu"
@import "./components/components.answer-guide-kpis"
@import "./components/components.answers-guide"
@import "./components/components.kpi-cards"
@import "./components/components.module-card"
@import "./components/components.course-about"
@import "./components/components.requirements"
@import "./components/components.recommended-course-card"
@import "./components/components.admin-course-card"
@import "./components/components.module-card-admin"
@import "./components/components.accordion"
@import "./components/components.admin-lesson-card"
@import "./components/components.simple-modal"
@import "./components/components.mock_question_book-banner"
@import "./components/components.projects-header"
@import "./components/_components.accordion"
@import "./components/components.slide"
@import "./components/components.manage-content"
@import "./components/components.answer-comment"
@import "./components/components.chat-widget"
@import "./components/components.lesson-widget"
@import "./components/components.message-card"
@import "./components/components.printed-exam"
@import "./components/components.onboarding"
@import "./components/components.study-block-page"
@import "./components/components.flashcard"
@import "./components/components.live-classes"
@import "./components/components.schedule-alert"
@import "./components/components.warning-wall"
@import "./components/components.shortcut-widget"
@import "./components/components.my-progress-widget"
@import "./components/components.lesson-dg"
@import "./components/components.skill-evaluation"
@import "./components/components.instruction-widget"
@import "./components/components.nps-modal"
@import "./components/components.new-survey"
@import "./components/components.banner-challenge"
@import "./components/components.challenge-card"
@import "./components/components.log-conquest-activities"
@import "./components/components.challenge-modal"
@import "./components/components.spin-wheel"
@import "./components/components.challenge-question"
@import "./components/components.challenge-success"
@import "./components/components.frequent-shortcut-widget"
@import "./components/components.engagement-card"
@import "./components/components.search"
@import "./components/components.question-countdown"
@import "./components/components.finder"
@import "./components/components.icon"
@import "./components/components.generated-content"
@import "./components/components.access-map"
@import "./components/components.menu"
@import "./components/components.report"
@import "./components/components.report-card"
@import "./components/components.report-tab"
@import "./components/components.report-kpi-card"
@import "./components/components.report-form"
@import "./components/components.report-widget"
@import "./components/components.report-filter"
@import "./components/components.report-kpi"
@import "./components/components.report-position-card"
@import "./components/components.password-strength"
@import "./components/components.matter-btn"
@import "./components/components.profile-challenge"
@import "./components/components.result-challenge"
@import "./components/components.nav-week"
@import "./components/components.guide-card"
@import "./components/components.review-card"
@import "./components/components.guide-timeline"
@import "./components/components.study-guide"
@import "./components/components.tia"
@import "./components/components.nav-section"
@import "./components/components.section-article"
@import "./components/components.study-block"
@import "./components/components.user-metrics"
@import "./components/components.solve-questions"
@import "./components/components.flip-card"
@import "./components/components.concept-to-characteristic"
@import "./components/components.concept-matching-game"
@import "./components/components.custom-guide"
@import "./components/components.panorama"
@import "./components/components.panorama-section"
@import "./components/components.panorama-card"
@import "./components/components.progress-lvl"
@import "./components/components.progress-lvl-tag"
@import "./components/components.disclaimer"
@import "./components/components.guide-card-dashboard"
@import "./components/components.metrics-shortcut"
@import "./components/components.fast-access"
@import "./components/components.placeholder-modal"
@import "./components/components.completed-conquest"

.ck-body-wrapper + iframe, script + iframe, #rbd-hidden-text-7-hidden-text-7 + iframe, noscript + iframe, #rbd-hidden-text-11-hidden-text-37 + iframe, #rbd-hidden-text-3-hidden-text-3 + iframe, #rbd-hidden-text-1-hidden-text-1 + iframe
  z-index: 0 !important
