.nav
    display: flex
    flex-direction: column
    gap: 4px
    margin-bottom: 14px

    &__link
        appearance: none
        width: 100%
        +g(full, space-between, middle)
        cursor: pointer
        background-color: var(--color-bright)
        background-clip: padding-box
        border: 1px solid var(--color-primary-lightest)
        color: var(--color-neutral-darker)
        border-radius: 50px
        padding: .3em 1em
        margin-bottom: .5em
        font-size: .8em
        gap: .5em

        .c-dropdown__toggle svg
          color: var(--color-neutral-darker)

        &.active, &:hover
          background-color: var(--color-primary)
          color: #FFF

          .taxonomies__btn
            color: #FFF

          .c-dropdown__toggle svg
            stroke: #FFF
