.progress-lvl
  display: flex
  flex-direction: column
  gap: 48px

  &__item, &__tooltip
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 16px

  .review-card__img-wrapper
    flex-shrink: 0

  &__content
    flex-grow: 1
    width: 220px
    max-width: 100%

  &__title, &__subtitle
    margin-bottom: 0

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.6rem
    font-weight: 700

  &__subtitle
    color: var(--color-neutral-darker, #5D6A86)
    font-size: 1.4rem

  &__tooltip
    --rt-opacity: 1
    background-color: var(--color-bright, #F2F5FD)
    text-align: left
    padding: 20px
    border-radius: 10px
    box-shadow: 0px 4px 10px 3px rgba(93, 106, 134, 0.20)
    width: 520px
    max-width: 100%
