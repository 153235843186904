.chat-widget
  padding: 20px
  border-radius: 12px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: #FFF
  box-shadow: $dropshadow
  min-height: 200px
  flex-grow: 1
  display: flex
  flex-direction: column
  gap: 12px

  &__body
    height: 200px
    flex-grow: 1
    overflow-y: auto
    display: flex
    flex-direction: column
    padding-right: 12px

    .empty-state
      margin: auto

  &__comment-form
    display: flex
    align-items: center
    gap: 8px
    input::placeholder
      color: var(--color-neutral)

    .form__control
      width: 40px
      flex-grow: 1

    .btn
      flex-shrink: 0

  &__comment-wrapper
    display: flex
    flex-direction: column
    border-radius: 4px
    position: relative

    &:not(:first-of-type)
      border-top: 1px solid var(--color-bright)

    &--reply
      background-color: var(--color-bright)
      padding: 12px
      margin: 12px 0

      &::before
        content: ''
        background-color: var(--color-bright)
        width: 100%
        height: 1px
        position: absolute
        top: -12px
        left: 0
        right: 0

  &__comment
    display: flex
    align-items: flex-start
    gap: 8px
    padding: 12px 0

    .avatar
      flex-shrink: 0

    &-inside
      width: 60px
      flex-grow: 1

    &-header
      display: flex
      align-items: center
      gap: 8px

      .badge
        flex-shrink: 0

    &-title
      @extend .u-text-truncate
      color: var(--color-primary-dark, #110B3E)
      font-size: 1.4rem
      font-weight: 700
      margin-bottom: 0

    &-text
      color: var(--color-neutral-dark, #5D6A86)
      font-size: 1.2rem
      margin-bottom: 0

    &-actions
      flex-shrink: 0
      display: flex
      flex-direction: column
      gap: 8px

  &__icon
    width: 16px
    cursor: pointer

    &--danger
      color: var(--color-danger)

  &__footer
    display: flex
    flex-direction: column
    gap: 8px
    border-radius: 4px

    &--reply
      padding: 12px
      background-color: var(--color-bright)

  &__original-text
    @extend .u-text-truncate
    margin-bottom: 0
    color: var(--color-neutral, #8894AC)
    font-size: 1.2rem
    font-weight: 700

  &__file
    display: flex
    padding: 12px
    align-items: center
    gap: 12px
    border-radius: 4px
    border: 1px solid var(--color-neutral-lightest, #ECE9F8)
    background: #fff
    box-shadow: $dropshadow
    transition: .15s
    margin-bottom: 8px

    &:hover
      box-shadow: $dropshadow-md

    &-inside
      width: 40px
      flex-grow: 1
      display: flex
      flex-direction: column

    &-title
      @extend .u-text-truncate
      color: var(--color-primary-dark, #110B3E)
      font-size: 1.4rem
      font-weight: 700
      margin-bottom: 0

    &-meta
      @extend .u-text-truncate
      color: var(--color-neutral, #5D6A86)
      font-size: 1.2rem
      margin-bottom: 0

  .filter-bar__row, .filter-bar__select
    width: 100%
