.box-widget
  display: flex
  flex-direction: column
  gap: 0px
  padding: 4px 10px
  border: 1px solid var(--color-success)
  border-radius: 10px
  background: var(--color-success-lightest)
  cursor: pointer
  // overflow-y: auto
  min-height: 60px

  &--danger
    background: var(--color-danger-lightest)
    border-color: var(--color-danger)

    .box-widget__title, .box-widget__text
      color: var(--color-danger)

  &-wrapper
    display: flex
    flex-wrap: wrap
    gap: 16px
  
  &--funnel
    height: 500px

  &__col
    flex-basis: 0
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: flex-end
    gap: 1em
    &--third > div
      min-height: 185px

  &__title, &__text
    margin-bottom: 0

  &__title
    font-size: 1.2rem
    font-weight: 300
    color: var(--color-success)

  &__text
    font-size: 1.4rem
    font-weight: 700
    color: var(--color-success)
    span
      font-size: 1.4rem
      font-weight: 700
