.tia
  --rt-opacity: 1

  .avatar
    position: absolute
    animation: bounce 1.5s infinite alternate

    &:focus, &:hover
      animation: none

  &__tooltip
    max-width: 156px
    display: flex
    flex-direction: column

  &__title, &__text
    margin-bottom: 0

  &__title
    color: var(--color-secondary-dark, #46B0C3)
    font-size: 1.0rem
    font-weight: 800

  &__text
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.2rem
    line-height: 1.2
    font-weight: 300
    a
      color: inherit
      font-weight: 700
      text-decoration: underline

  &--login
    position: absolute
    left: 32px
    bottom: 32px

    .avatar
      position: relative

@keyframes bounce
  from
    transform: translateY(0)
    animation-timing-function: ease-out
  to
    transform: translateY(-12px)
    animation-timing-function: ease-in

.tia-results
  display: inline-flex
  flex-wrap: wrap
  align-items: flex-start
  gap: 20px

  .avatar
    flex-shrink: 0

  &__content
    background: #fff
    box-shadow: 0px 4px 10px rgba(93, 106, 134, 0.2)
    border-radius: 0 4px 4px 4px
    padding: 12px
    position: relative
    width: 220px
    max-width: 100%
    flex-grow: 1

  &__title, &__text
    margin-bottom: 0

  &__title
    color: var(--color-secondary-dark, #46B0C3)
    font-size: 1rem
    font-weight: 800

  &__text
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.2rem
    font-weight: 300
    margin-bottom: 8px

  &__kpi-wrapper
    display: flex
    flex-wrap: wrap
    gap: 16px
    padding: 8px
    align-items: flex-start
    border-radius: 10px
    background: var(--color-bright, #F1F5FA)
    text-align: center

  &__kpi
    flex-grow: 1

  &__kpi-title, &__kpi-value
    margin-bottom: 0
    color: var(--color-neutral-darker, #5D6A86)
    font-size: 1.2rem

  &__kpi-title
    font-weight: 700

  &__kpi-value
    font-weight: 300
