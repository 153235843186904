:root
   --color-primary-dark: #110B3E
   --color-primary: #442B8C
   --color-primary-light: #7B63BF
   --color-primary-lighter: #8376A9
   --color-primary-lightest: #ECE9F8

   --color-secondary-dark: #46B0C3
   --color-secondary: #5DC9DD
   --color-secondary-light: #74DBE1
   --color-secondary-lighter: #A7DDE0
   --color-secondary-lightest: #DEF6F8

   --color-third: #FF9153
   --color-third-light: #FF9B63

   --color-neutral-darker: #5d6a86
   --color-neutral-dark: #5d6a86
   --color-neutral: #8894AC
   --color-neutral-light: #BCC4D6
   --color-neutral-lighter: #CCD2E0
   --color-neutral-lightest: #ECE9F8

   --color-bright: #F1F5FA

   --color-success: #1CA388
   --color-success-light: #6FCF97
   --color-success-lightest: #DCFFEA
   --color-danger: #FD6B62
   --color-danger-lighter: #FFD0CD
   --color-danger-lightest: #FFF9F8
   --color-warning: #FDB462
   --color-warning-lightest: #FFF2E3

   --color-neutral-bright: #FAFAFA
   --color-neutral-bright-20: #FEFEFE

$bg-login: '../../images/oab/login-bg.svg'
