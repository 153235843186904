.study-guide
  display: flex
  flex-direction: column
  gap: 32px
  max-width: 100%
  @media (min-width: $xl)
    flex-grow: 1
    padding-top: 64px

  &__header
    @media (min-width: $xl)
      position: fixed
      top: 88px
      right: 45px
      left: 108px
      padding: 12px 0 16px 0
      background-color: var(--color-neutral-bright)
      display: flex
      align-items: center
      gap: 16px

  &__title
    flex-grow: 1
    color: #000
    font-size: 2.4rem
    font-weight: 700
    margin-bottom: 0

  &__actions
    flex-shrink: 0
    display: flex
    align-items: center
    gap: 16px

  &__body
    display: flex
    flex-direction: column
    gap: 60px 160px
    @media (min-width: $xl)
      flex-direction: row
      margin-right: 344px

  &__content
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 60px
    @media (min-width: $xl)
      flex-direction: row
      flex-grow: 1

    .guide-timeline
      flex-grow: 1

  &__sidebar
    @media (min-width: $xl)
      flex-shrink: 0
      width: 184px
      position: fixed
      right: 45px
