.warning-wall
  border-radius: 10px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: #FAFAFA
  box-shadow: $dropshadow-lg
  overflow: hidden
  display: flex
  flex-direction: column
  min-height: 260px
  position: relative
  margin-bottom: 40px
  @media (min-width: $xl)
    flex-direction: row

  &__img
    width: 100%
    height: 260px
    object-fit: cover
    flex-shrink: 0
    flex-grow: 1

    @media (max-width: 480px)
      &--egepi
        content: url('/app/images/egepi/EgepiBanner-mobile.png')

      &--default
        content: url('/app/images/canaleducacao/Boas_vindas_CanalEducacao_mobile.png')

    @media (min-width: $xl)
      width: 280px

  &__content
    flex-grow: 1
    padding: 24px
    display: flex
    flex-direction: column
    gap: 8px

  &__title
    margin-bottom: 0
    color: var(--color-primary, #442B8C)
    font-size: 3.2rem
    font-weight: 700
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden
    max-height: 76px

  &__body
    flex-grow: 1

  &__description
    margin-bottom: 0
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.8rem
    font-weight: 300
    display: -webkit-box
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    overflow: hidden
    max-height: 81px

  > .btn
    position: absolute
    right: 24px
    bottom: 16px
