.engagement-card
  flex-grow: 1
  width: 220px
  max-width: 100%
  display: flex
  flex-wrap: wrap
  align-items: center
  gap: 12px
  padding: 32px 24px
  border-radius: 10px
  border: 1px solid var(--color-neutal-lightest, #ECE9F8)
  background: #FFF
  box-shadow: $dropshadow

  &-wrapper
    display: flex
    flex-wrap: wrap
    gap: 16px

  &__header
    flex-shrink: 0
    width: 100%
    @media (min-width: $md)
      width: auto

  &__body
    flex-grow: 1
    width: 140px
    max-width: 100%

  &__img
    width: 100%
    height: 144px
    @media (min-width: $md)
      height: 72px
      width: 116px

  &__body
    display: flex
    flex-direction: column
    gap: 8px

  &__title, &__subtitle
    margin-bottom: 0

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.4rem
    font-weight: 700

  &__subtitle
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.2rem
    font-weight: 300

.engagement-widget
  &__header
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    gap: 4px

  &__title
    margin-bottom: 0
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.4rem
    font-weight: 300
    margin-bottom: 0
