.react-select {
  line-height: 1;

  &__control {
    border-radius: 5px;
    border-color: #ccd2e0;
    height: 40px;
  }

  &__value-container {
    padding: 0 16px;
  }

  &__indicators {
    padding-right: 16px;
  }

  &__single-value {
    color: #8894ac;
  }

  &__single-value {
    color: #8894ac !important;
  }
}
