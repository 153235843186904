.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.card {
  width: 140px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--color-neutral-lightest, #ece9f8);
  background: #fff;
  max-width: 100%;
}

.tag {
  flex-shrink: 0;
}

.title {
  flex-grow: 1;
  margin-bottom: 0;
  overflow: hidden;
  color: var(--color-neutral-darker, #5d6a86);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: 700;
}
