@keyframes moveUp
  0%
    opacity: 0
    transform: translate(-50%, -10%)
  100%
    opacity: 1
    transform: translate(-50%, -50%)

@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

.flip-wrapper
  perspective: 1000px

  // &:hover .flip
  //   transform: rotateY(180deg)

.flip
  position: absolute
  width: 100%
  height: 100%
  transition: transform 0.8s
  transform-style: preserve-3d

  &--turned
    transform: rotateY(180deg)

  &__front, &__back
    position: absolute
    width: 100%
    height: 100%
    backface-visibility: hidden
  
  &__back
    transform: rotateY(180deg)