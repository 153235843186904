.nav-week
  flex-shrink: 0
  display: inline-flex
  flex-direction: row
  gap: 10px
  padding: 5px
  border-radius: 50px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: #fff
  box-shadow: 0px 4px 10px 0px rgba(93, 106, 134, 0.05)
  max-width: 100%
  @media (min-width: $xl)
    flex-direction: column
    position: fixed
    top: 168px

  &__link
    display: inline-block
    padding: 5px
    border-radius: 50px

    &--is-active
      background: var(--color-secondary-lightest, #DEF6F8)

  &__progress
    cursor: pointer

    circle
      transition: .16s stroke-dashoffset

    text
      font-size: 1.4rem
      font-weight: 300
      fill: var(--color-neutral, #8894AC)
      dominant-baseline: middle
      text-anchor: middle

      .nav-week__link--is-active &
        fill: var(--color-secondary-dark, #46B0C3)
        font-weight: 700

      &:hover, &:focus
        fill: #000
        font-weight: 700

  &__circles
    transform-origin: center
    rotate: -90deg

  &__track
    stroke: var(--color-bright)

    .nav-week__link--is-active &
      stroke: #fff
