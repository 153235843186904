.requirements
  position: absolute
  top: 0
  right: 0
  bottom: 0
  position: absolute
  inset: 0
  z-index: 1
  overflow: hidden
  pointer-events: none

  &__inner
    position: absolute
    inset: 0 0 0 auto
    z-index: 1
    width: 80%
    background-color: #fff
    border-radius: 8px
    transition: transform .15s
    transform: translateX(0)

    &--close
      transform: translateX(100%)

  &__toggle
    all: unset
    position: absolute
    top: 50%
    left: -59px
    transform: rotate(-90deg)
    background-color: var(--color-neutral-lightest)
    border-radius: 10px 10px 0 0
    padding: 2px 12px
    font-weight: 700
    font-size: 1.2rem
    color: var(--color-primary)
    cursor: pointer
    pointer-events: auto

  &__content
    padding: 12px
    overflow-y: auto
    position: absolute
    inset: 0
    pointer-events: auto

  &__item
    border-bottom: 1px solid var(--color-neutral-lightest)
    display: flex
    align-items: center
    gap: 8px
    padding: 8px 0

  &__inner-item
    flex-grow: 1

  &__icon
    flex-shrink: 0
    width: 20px
    color: var(--color-primary)

  &__title
    margin-bottom: 0
    font-weight: 700
    color: var(--color-neutral-dark)
    font-size: 1.4rem

  &__subtitle
    margin-bottom: 0
    color: var(--color-neutral)
    font-size: 1.2rem

  &__backdrop
    background-color: rgba(0, 0, 0, .2)
    display: block
    position: absolute
    inset: 0
    z-index: 0
    border-radius: 8px
    pointer-events: auto
