.archive-section
    overflow: visible

    &-wrapper
        display: flex
        flex-wrap: wrap
        gap: 40px
        & > *
            min-width: 250px
            flex-grow: 1

    &:not(:last-child)
        margin-bottom: 56px

    &__title
        display: inline-block
        padding: 8px 12px
        border: 2px solid #CCD6EB
        border-radius: 4px
        color: var(--color-neutral)
        margin-bottom: 20px
        font-weight: 300

    &__container
        display: grid
        grid-template-columns: repeat(auto-fill, minmax(228px, 1fr))
        gap: 20px
        &.open-container
          grid-template-columns: repeat(auto-fill, minmax(368px, 1fr))
          .archive-card__footer a
            margin: 0 3px

.archive-card
    background: white
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
    border-radius: 12px
    padding: 20px
    font-size: 13px
    transition: all ease-in-out .2s
    &:hover
        box-shadow: 0px 4px 20px rgba(68, 43, 140, 0.25)

    &__header
        display: flex
        align-items: flex-start
        justify-content: space-between
        gap: 8px
        margin-bottom: 16px

    &__group
        min-width: 0

    &__title
        font-weight: 800
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        line-height: 1.2
        margin-bottom: 0
        font-size: 1.2em
        span
            font-weight: 300

    &__status
        display: inline-block
        color: var(--color-neutral)
        font-size: 10px
        line-height: 1.2
        transform: translateY(-4px)

    &__icon
        color: var(--color-danger)

        svg
            width: 14px

    &__body
        display: flex
        align-items: center
        gap: 16px
        margin-bottom: 20px

    &__info
        color: var(--color-neutral-darker)
        background: var(--color-bright)
        border-radius: 4px
        font-weight: 800
        text-align: center
        padding: .5em 0
        flex-grow: 1
        min-width: 45%
        margin-bottom: 0

    &__name
        color: var(--color-neutral)
        font-weight: 300

    &__footer
        display: flex
        align-items: center
        & > *
            flex-grow: 1
            display: flex
            place-content: center

        .btn
          font-size: 1.05em

    &__time
        color: var(--color-secondary)

        svg
            margin: 0 4px

        &--danger
            color: var(--color-danger)

    &--highlight
        color: white
        background: var(--color-secondary-light)
        border: 1px solid white

        .btn
            color: white
            border-color: white
            flex-grow: 0
            margin-left: auto

        .archive-card__status
            color: white

        .archive-card__title
            color: white

    &--add
        color: var(--color-primary)
        border: 1px solid white
        background: linear-gradient(253.87deg, #FFFFFF 5.22%, rgba(255, 255, 255, 0) 99.41%), var(--color-bright)
        text-align: center
        display: grid
        place-content: center
        transition: all ease-in-out .2s
        cursor: pointer

        .archive-card__icon
            transition: all ease-in-out .2s
            color: var(--color-primary)

            svg
                width: 20px
                height: 20px

    &__btn
        appearance: none
        background: transparent
        border: none
        padding: 0
        flex-shrink: 0
