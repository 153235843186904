.flashcard-modal
  display: flex
  flex-direction: column
  height: calc(100vh - 8vh)

.flashcard-modal-guide
  display: flex
  flex-direction: column
  justify-content: center
  gap: 5px
  width: 100%
  height: calc(100vh - 28vh)

.flashcard-wrapper
  height: 0
  flex-grow: 1
  overflow-y: auto
  display: flex
  flex-direction: column
  align-items: center

  .swiper-slide-shadow
    width: 560px !important
    max-width: 100%
    border-radius: 32px

  &__inner
    margin: auto 0
    max-width: 100%
    text-align: center
    display: flex
    flex-direction: column
    gap: 40px
    padding: 0

    .flip-wrapper
      flex-shrink: 0
      width: 560px
      max-width: 100%
      min-height: 340px
      position: relative

    .swiper
      max-width: 100%
      padding: 60px min(60px, 7vw)

    .swiper-wrapper
      @media (min-width: $xl)
        margin-left: 11%

  &__title
    color: var(--color-primary, #442B8C)
    font-family: Nunito
    font-size: 1.8rem
    font-weight: 700
    margin-bottom: 0

  &__subtitle
    margin-bottom: 0
    display: flex
    align-items: center
    justify-content: center
    gap: 4px
    color: var(--color-neutral, #8894AC)
    font-size: 1.4rem
    font-weight: 300

    svg
      flex-shrink: 0
      width: 16px

.flashcard
  height: 100%
  border-radius: 32px
  background: linear-gradient(319deg, #FFF 5.44%, #F5F5F5 100%)
  box-shadow: $dropshadow-md
  padding: 3.4vw
  display: flex
  flex-direction: column
  align-self: center !important
  gap: 16px

  &--back
    background: linear-gradient(47deg, #502DB3 0%, #5D2EE3 100%)

    .flashcard__header *, .flashcard__body *
      color: #fff

  &__header
    display: flex
    align-items: center
    justify-content: flex-end

  &__count
    margin-bottom: 0
    display: flex
    align-items: center
    justify-content: center
    gap: 4px
    color: var(--color-primary)
    font-size: 1.2rem
    font-weight: 300

    svg
      flex-shrink: 0
      width: 16px

  &__body
    flex-grow: 1
    display: flex
    flex-direction: column

  &__title
    margin: auto 0
    color: var(--brand-primary-dark, #110B3E)
    font-size: 1.8rem
    font-weight: 700

  &__text
    margin-bottom: 0

  &__footer
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: center
    gap: min(32px, 2.3vw)

.flashcard-success
  flex-grow: 1
  display: flex
  flex-direction: column
  text-align: center
  align-items: center

  &__inner
    margin: auto 0
    width: 500px
    max-width: 100%

  &__medal
    margin-bottom: 16px

  &__title
    margin-bottom: 22px
    color: var(--color-primary, #442B8C)
    font-size: 1.8rem
    font-weight: 700

  &__description
    margin-bottom: 32px
    color: var(--color-neutral-dark)
    font-size: 1.6rem
    font-weight: 300

.flashcard-box
  display: flex
  flex-direction: column
  padding: 28px 20px
  border-radius: 10px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: #fff
  box-shadow: $dropshadow

  &__title
    margin-bottom: 0
    color: var(--color-primary, #442B8C)
    font-size: 1.6rem
    font-weight: 700

  &__body
    display: flex
    flex-direction: column
    gap: 16px

  &__description
    margin-bottom: 0
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.4rem
    font-weight: 300
