.siape
   --color-primary-dark: #272539
   --color-primary: #334278
   --color-primary-light: #5B5F63
   --color-primary-lighter: #9D9FA1
   --color-primary-lightest: #E9F3F8

   --color-secondary: #334278
   --color-secondary-light: #667CCC
   --color-secondary-lighter: #BFCBF6

   --color-third: #667CCC
   --color-third-light: #BFCBF6

   --color-neutral-dark: #5d6a86
   --color-neutral: #8894AC
   --color-neutral-light: #BCC4D6
   --color-neutral-lighter: #CCD2E0
   --color-neutral-lightest: #ECE9F8

   --color-bright: #F1F5FA

   --color-success: #1CA388
   --color-success-lightest: #DCFFEA
   --color-danger: #FD6B62
   --color-danger-lightest: #FFE7E6
   --color-warning: #FDB462

   --color-neutral-bright: #F1F5FA
   --color-neutral-bright-20: #FEFEFE

$bg-login: '../../images/oab/login-bg.svg'
