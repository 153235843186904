.svg-container
  flex: 1
  display: flex
  justify-content: center

  &__holder
    position: relative

.GRE-item
  position: absolute
  background-color: white
  border-radius: 3px
  padding: 1px 3px
  border: solid 1px #ECE9F8
  font-family: $title
  font-weight: 700
  font-size: 11px
  color: var(--color-neutral-dark)
  display: none
  +bp(md)
    display: block
