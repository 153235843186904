.maiseducacao
  --color-primary-dark: #071A4A
  --color-primary: #254AA5
  --color-primary-light: #8EA7E4
  --color-primary-lighter: #C0B8D8
  --color-primary-lightest: #ECE9F8

  --color-secondary: #006B30
  --color-secondary-light: #01B251
  --color-secondary-lighter: #CAEFDB

  --color-third: #EFA91C
  --color-third-light: #F3BB4B

  --color-bright: #F2F5FD
