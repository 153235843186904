.schedule
	font-size: 1.4rem
	max-height: 340px
	overflow-y: auto
	position: relative

	&-wrapper
		background: #FFFFFF
		box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
		border-radius: 10px
		padding: 16px

		&__header
			@extend .widget__header

		&__title
			@extend .widget__title

		.select-empty-state
			margin-top: 0
			margin-bottom: 16px

	&__item
		display: flex
		gap: 12px
		position: relative

		&:not(:last-of-type)
			padding-bottom: 20px

		&::before
			position: absolute
			top: 0
			left: 30px
			bottom: 0
			content: ''
			width: 1px
			height: 100%
			background-color: var(--color-neutral-lightest)
			z-index: 0

		&--disabled
			pointer-events: none
			opacity: .5

	&__date
		flex-shrink: 0
		display: flex
		align-items: center
		justify-content: center
		background-color: var(--color-neutral-lightest)
		border-radius: 8px
		padding: 8px
		color: var(--color-primary)
		font-weight: 700
		border: 4px solid #fff
		position: relative
		z-index: 1

		&--success
			background-color: var(--color-success)
			color: #fff

	&__infos
		flex-grow: 1
		border-bottom: 1px solid var(--color-bright)
		padding-bottom: 8px

	&__header
		display: flex
		align-items: center
		gap: 12px

	&__title
		@extend .u-text-truncate
		width: 0
		flex-grow: 1
		margin-bottom: 0
		font-weight: 700
		color: var(--color-neutral-dark)

	&__hour
		font-weight: 300
		color: var(--color-neutral)
