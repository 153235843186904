.c-dropdown
    display: inline-block
    position: relative

    &__toggle
        appearance: none
        border: none
        background: none
        line-height: 0
        color: currentColor

        svg
            min-height: auto
            min-width: 16px
            stroke: #83919E

    &__content
        border-radius: 4px
        border: 1px solid var(--color-neutral-lighter)
        position: absolute
        z-index: 1
        right: 0
        top: 100%
        min-width: 100px
        animation: fadeIn
        animation-duration: .3s
        box-shadow: 0 10px 20px rgba(black, .1)
        background-color: #fff

    &__item
        display: block
        cursor: pointer
        color: var(--color-neutral-dark)
        font-size: 1.2rem
        font-weight: 300
        text-align: center
        background: white
        padding: 8px
        line-height: 1.2em
        transition: .16s
        position: relative

        &:first-of-type
            border-top-left-radius: 4px
            border-top-right-radius: 4px

        &:last-of-type
            border-bottom-left-radius: 4px
            border-bottom-right-radius: 4px

        &:not(:first-of-type)
            border-top: 1px solid transparent

            &::before
                content: ''
                position: absolute
                top: -1px
                left: 8px
                right: 8px
                height: 1px
                background-color: var(--color-neutral-lightest)

        &:hover
            background: var(--color-bright)
