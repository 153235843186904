.module-list
  +i(1/1)
  +g(full)
  gap: 4px
  position: relative

  &__item
    +g(full)
    +i(1/1)
    position: relative
    background-color: white
    border-radius: 10px
    border: 1px solid var(--color-neutral-lightest)
    padding: 1em
    display: flex
    align-items: flex-start
    gap: .5em
    transition: all ease-in-out .2s
    cursor: pointer
    &.active, &:hover
      background-color: var(--color-primary)
      background-clip: padding-box

      .module-list__title
        color: #fff !important

      svg
        stroke: #fff

    .c-dropdown
      position: absolute
      top: .5em
      right: .5em

  &__title
    width: 0
    flex-grow: 1
    margin-bottom: 0
    font-size: 1.4rem
    font-weight: 700
    color: var(--color-primary-dark)
    margin-right: 1em
    word-break: break-word

  &__avatars
    +i(1/1)
