.project-timeline
  margin-top: 2em
  list-style: none
  font-size: 1rem
  color: var( --color-neutral-dark)

  &__item
    padding: 6px
    border-radius: 5px
    position: relative
    transition: all .15s
    display: flex
    align-items: center
    gap: 4px
    margin-bottom: 10px
    min-height: 44px

    &:hover
      background-color: red

    &:not(.project-timeline__item--approved):not(.active)::before
      content: ''
      position: absolute
      top: 10px
      left: 21px
      bottom: -12px
      display: block
      width: 1px
      background-color: var( --color-neutral)
      opacity: .4
      z-index: 0

    // &:first-of-type
    //   &::before
    //     top: 20px !important

    //   &::after
    //     content: ''
    //     display: block
    //     border: 1px solid #8894AC
    //     width: 9px
    //     height: 9px
    //     border-radius: 50px
    //     position: absolute
    //     left: 15px
    //     background-color: var(--color-bright)

    //   &.active
    //     background-color: transparent
    //     .project-timeline__title
    //       color: var(--color-primary)
    //       text-decoration: underline

    &:last-of-type
      &::before
        bottom: 20px !important

    &:not(.project-timeline__item--approved)
      cursor: pointer

      &:hover:not(.active)
        background-color: rgba(255,255,255, .5)

    &.active, &:hover
      background-color: var(--color-primary-lightest)
      color: var(--color-primary)

      .project-timeline__version
        background-color: var(--color-primary)
        border-color: #fff

      & + .project-timeline__item::before
        top: -12px

    &--approved
      cursor: default
      background-color: var(--color-success)
      font-weight: 700
      color: #fff

  &__title
    margin-bottom: 0
    font-size: 1.2rem
    font-weight: 700
    color: #8894AC
    flex-basis: 0
    flex-grow: 1

  &__version
    border: 2px solid var(--color-bright)
    background-color: var(--color-primary-lighter)
    color: #fff
    font-weight: 700
    font-size: 9px
    display: inline-flex
    align-items: center
    justify-content: center
    width: 30px
    height: 30px
    border-radius: 50px
    z-index: 1
    flex-shrink: 0
    +g(full, middle, center)
    transition: all ease-in-out .2s

  &__group
    flex-grow: 1

  &__text
    display: block
    font-weight: 700
    font-size: 12px

.step-by-step-nav
  flex-grow: 1
  margin-top: 32px
  padding-top: 32px
  list-style: none
  border-top: 1px solid #E1E7F0
  font-size: 1rem
  color: #8894AC
  display: flex
  flex-direction: column

  &__item
    padding: 0 0 8px 40px
    border-radius: 5px
    position: relative
    transition: all .15s
    display: flex
    align-items: center
    gap: 4px
    margin-bottom: 20px
    min-height: 44px

    &:not(:last-of-type)::before
      content: ''
      position: absolute
      top: 34px
      left: 14px
      bottom: 0
      display: block
      width: 1px
      background-color: #8894AC
      opacity: .4
      z-index: 0

    &::after
      content: ''
      display: block
      border: 2px solid var(--color-secondary)
      width: 24px
      height: 24px
      border-radius: 50px
      position: absolute
      left: 0
      top: 0
      background-color: #fff

    &.active
      font-weight: 700

      &::after
        background-color: var(--color-secondary)

    &--completed
      opacity: .5

      &::after
        background-color: var(--color-secondary)
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>")
        background-size: 16px
        background-position: center
        background-repeat: no-repeat

  &__link
    font-size: 1.4rem
    line-height: 1.5
    color: var(--color-neutral-dark)
