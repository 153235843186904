.help-center
  &__bg
    position: fixed
    inset: 0
    z-index: -1
    overflow: hidden

    svg
      width: 100%

.help-header
  padding: 44px clamp(20px, 6vw, 88px)
  display: flex
  align-items: center
  justify-content: space-between
  gap: 12px

  &__logo
    font-size: 1.4rem
    display: flex
    align-items: center
    flex-wrap: wrap
    gap: 2px

  &__img
    width: 104px

  &__title
    margin-bottom: 0
    font-size: 1.4rem
    font-weight: 300
    color: var(--color-primary-dark)

  .main-nav__user
    flex-shrink: 0

.help-main
  margin-bottom: 96px

.help-container
  margin: 0 auto 88px auto
  width: 1200px
  max-width: calc(100vw - 40px)

.help-hero
  @extend .help-container
  background-color: #fff
  border-radius: 20px
  box-shadow: 3px 22px 50px -2px rgba(121, 120, 130, 0.15)
  padding: 60px
  text-align: center

  &__img
    margin-bottom: 48px
    width: 144px

  &__title
    color: var(--color-primary)
    font-weight: 700
    font-size: 2.8rem

.help-section
  @extend .help-container

  &__title
    font-size: 1.6rem
    font-weight: 300
    color: var(--color-neutral-dark)
    margin-bottom: 24px

  &__btns
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(344px, 1fr))
    gap: 2.2vw

  &__videos
    margin-bottom: 48px
    display: grid
    gap: 60px
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr))
    @media (min-width: $sm)
      grid-template-columns: repeat(auto-fill, minmax(544px, 1fr))

.help-btn
  appearance: none
  all: unset
  display: flex
  align-items: center
  gap: 16px
  justify-content: center
  color: var(--color-neutral-dark)
  font-size: 1.6rem
  font-weight: 700
  background-color: #fff
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
  border-radius: 10px
  padding: 20px
  cursor: pointer
  transition: .3s
  &:hover, &:focus
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.2)

  &__icon
    flex-shrink: 0
    display: inline-block
    width: 32px
    height: 32px
    background-color: var(--color-secondary-lightest)
    border-radius: 50px
    display: grid
    place-content: center
    color: var(--color-secondary-dark)

.help-video
  &__header
    display: flex
    align-items: center
    gap: 8px
    margin-bottom: 12px

  &__icon
    font-size: 1.6rem
    flex-shrink: 0
    display: inline-block
    width: 40px
    height: 40px
    background-color: var(--color-secondary-lightest)
    border-radius: 50px
    display: grid
    place-content: center
    color: var(--color-secondary-dark)

  &__title
    font-size: 1.6rem
    font-weight: 700
    color: var(--color-secondary-dark)
    margin-bottom: 0

  &__body
    position: relative
    cursor: pointer
    &::before
      content: ""
      background-color: rgba(0,0,0, .5)
      position: absolute
      inset: 0
      z-index: 1

  &__play
    width: 96px
    position: absolute
    left: 50%
    top: 50%
    z-index: 2
    transform: translate(-50%, -50%)

  &__wrapper
    position: relative
    padding-bottom: 56.25%
    margin-bottom: 100px
    iframe
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%

  &__videos
    display: grid
    gap: 44px 16px
    grid-template-columns: repeat(auto-fill, minmax(344px, 1fr))

.help-footer
  margin: 0 auto
  width: 1200px
  max-width: calc(100vw - 40px)

  &__inner
    @extend .help-container
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    gap: 13vw

  .form
    flex-grow: 1
    background-color: var(--color-secondary-lightest)
    padding: 40px
    border-radius: 20px

  &__contact
    flex-grow: 1
    color: var(--color-secondary-dark)

    a
      text-decoration: underline
      &:hover
        text-decoration: none

  &__about
    margin-bottom: 32px
    text-align: center
    font-weight: 300
    font-size: 1.6rem
    color: var(--color-primary-light)
