.recommended-course-card-wrapper
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(328px, 1fr))
  grid-gap: 20px
  gap: 20px

.recommended-course-card
  background-color: #fff
  padding: 12px
  box-shadow: 0px 4px 10px rgba(119, 119, 120, 0.15)
  border-radius: 12px
  position: relative

  &:hover, &:focus
    .recommended-course-card__img
      transform: scale(1.2)
    .recommended-course-card__infos
      height: 167px

  &__img-wrapper
    border-radius: 12px
    overflow: hidden
    position: relative

    &::after
      content: ''
      position: absolute
      inset: 0
      background: rgb(0,0,0)
      background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.6) 100%)

  &__img
    width: 100%
    height: 272px
    object-fit: cover
    transition: all 1s ease

  &__container
    padding: 20px
    position: absolute
    inset: 12px
    display: flex
    flex-direction: column
    gap: 16px

  &__body
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: flex-end

  &__infos
    overflow: hidden
    height: 38px
    transition: all .5s ease

    .star-rating
      width: 100px
      &__btn
        padding: 0

  &__title
    font-weight: 700
    font-size: 1.6rem
    color: #fff
    height: 2.4em
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden
    margin-bottom: 8px