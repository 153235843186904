.request {
  padding: 16px;
  background: white;
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05);
  border-radius: 1rem;
  min-width: 300px;
  margin-bottom: 24px;

  &-received {
    border: 1px solid #fdb462;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-tabs {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin: 10px 0;
    padding: 0 12px;

    &__item {
      font-weight: 700;
      color: var(--color-primary-dark);
      padding: 4px 8px;
      border-radius: 20px;
      cursor: pointer;
      font-size: 1.2rem;

      &:hover {
        color: #fff;
        background-color: var(--color-primary);
      }

      &--active {
        color: #fff;
        background-color: var(--color-primary);
      }
    }
  }

  &-title {
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--color-primary-dark);
  }

  &-duel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
    border: 1px solid var(--color-neutral-lightest);
    padding: 1rem;
    margin-top: 12px;
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05);

    &:hover {
      box-shadow: 0px 4px 20px rgba(68, 43, 140, 0.25);
    }

    &-players {
      display: flex;
      align-items: center;
      gap: 8px;

      &--img {
        position: relative;

        &__star-icon {
          position: absolute;
          bottom: 0;
          right: 0;
          background-color: #74dbe1;
          border-radius: 50%;
          padding: 2px;
          border: #74dbe1;
          fill: #fff;
          color: #fff;
          height: 15px;
          width: 15px;
        }
      }

      > svg {
        color: red;
      }
    }
  }
}
