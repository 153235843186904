.solve-questions
  display: flex
  padding: 32px
  flex-direction: column
  gap: 20px
  border-radius: 12px
  background: var(--color-bright, #F1F5FA)

  &__title
    margin-bottom: 0
    color: var(--color-primary)
    font-size: 1.6rem
    font-weight: 700

  &__text
    margin-bottom: 20px
    color: var(--color-neutral-darker, #5D6A86)
    font-size: 16px1.6rem
    font-weight: 300
