.subject-list
	margin: 1em 0

	&__item
		+g(full, middle, space-between)
		font-size: .85em
		padding: .5em 0
		background: var(--color-bright)
		border-radius: 5px
		margin: .3em 0
		padding: 1em
		border: 1px solid var(--color-bright)
		transition: all ease-in-out .2s
		&:hover
			border: 1px solid var(--color-neutral)
	&__item-name
		font-weight: 700
		+i(15/20)

	&__item-percent
		font-size: .9em

	&__performance
		font-weight: 700

		&:after
			transform: translateY(15%)
			display: inline-flex
			margin-left: .3em
			content: ""
			background-size: 7px 5px
			background-position: center
			background-repeat: no-repeat
			width: 11px
			height: 11px
			background-color: var(--color-neutral)
			background-image: url('../../images/icons/white-chevron--.svg')
			border-radius: 3px
		&.low
			color: var(--color-danger)
			&:after
				background-color: var(--color-danger)
				background-image: url('../../images/icons/white-chevron-up.svg')
		&.high
			color: var(--color-secondary)
			&:after
				background-color: var(--color-secondary)
				background-image: url('../../images/icons/white-chevron-up.svg')
