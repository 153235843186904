.comming-soon
	+g(full, center, middle)
	min-height: 80vh

	+i(1/1)

	&__title
		+i(1/1)
		text-align: center
		font-weight: 300

	img
		height: 70vh
