@keyframes enter-and-leave
  0%
    opacity: 0
  50%
    opacity: 1
  100%
    opacity: 0

.shortcut-widget
  display: flex
  flex-wrap: wrap
  gap: 16px
  color: #fff

  &__item
    width: 220px
    max-width: 100%
    flex-grow: 1
    display: flex
    align-items: center
    gap: 16px
    padding: 24px 32px
    border-radius: 20px
    border: 4px solid #FFF
    background: linear-gradient(47deg, #502DB3 0%, #5D2EE3 102.82%)
    box-shadow: $dropshadow

    &--study-plan
      background: linear-gradient(47deg, #2D8BB3 0%, #5D2EE3 102.82%)

    &--class-diary
      background: #5DC9DD

  &__icon
    flex-shrink: 0
    align-self: flex-start
    width: 32px
    height: 32px
    background-color: #fff
    border-radius: 100px
    color: var(--color-primary)
    display: flex
    align-item: center
    justify-content: center

    svg
      display: block
      width: 20px
      stroke-width: 2

  &__content
    flex-grow: 1
    line-height: 1.2
    width: 0

  &__title, &__text
    margin-bottom: 0

  &__title
    color: #FFF
    font-size: 1.8rem
    font-weight: 700

  &__text
    @extend .u-text-truncate
    font-size: 1.4rem
    font-weight: 300
    width: 100%
    max-width: 100%

  &__actions
    flex-shrink: 0
    display: flex
    flex-direction: column
    align-item: center
    justify-content: space-between
    svg
      width: 20px

  &__online
    animation: enter-and-leave 1.5s infinite
    width: 12px
    height: 12px
    border-radius: 100px
    background-color: var(--color-success)

  &__offline
    width: 12px
    height: 12px
    border-radius: 100px
    border: 2px solid var(--color-success)
