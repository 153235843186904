.labeled-container
	+g(full)
	+i(1/1)
	border-radius: 10px
	border: 1px solid var(--color-neutral-lightest)
	margin-top: 1em
	overflow: hidden

	&__body
		+g(full)
		gap: 1em
		padding: 1em

	&__header
		background: var(--color-bright)
		+i(1/1)
		padding: .5em 1em
		font-size: .8em
		font-weight: 700
		color: var(--color-neutral)
		border-bottom: 1px solid var(--color-neutral-lightest)

	&__card
		border: 1px solid var(--color-neutral-lightest)
		padding: 2em
