.certificate
  &__img
    width: 100%
    height: 132px
    object-fit: cover
    border-radius: 10px 10px 0 0
    margin-bottom: 32px

  &__logo
    width: 88px
    margin-bottom: 20px

  &__inner-header
    margin-bottom: 40px
    display: flex
    align-items: center
    flex-wrap: wrap
    gap: 8px

  &__title
    margin-bottom: 0
    color: var(--color-primary-dark)
    font-size: 3.2rem
    font-weight: 800

  &__icon
    background-color: var(--color-primary-lightest)
    color: var(--color-primary)
    width: 32px
    height: 32px
    border-radius: 50px
    display: flex
    align-items: center
    justify-content: center
    svg
      width: 20px

  &__body
    margin-bottom: 40px

  &__text
    color: var(--color-neutral-dark)
    small
      font-size: 1.2rem

  &__kpis
    display: flex
    flex-wrap: wrap
    gap: 12px
    margin-top: 40px
    margin-bottom: 10px

  &__kpi
    flex-basis: 0
    flex-grow: 1
    min-width: 150px
    padding: 10px 20px
    border-radius: 12px
    border: 1px solid var(--color-primary-lightest)
    display: flex
    flex-direction: column
    gap: 10px
    position: relative

    &-icon
      flex-shrink: 0
      width: 20px
      color: var(--color-primary)

    &-inner
      display: flex
      align-items: center
      gap: 8px

    &-value
      margin-bottom: 0
      color: var(--color-primary)
      font-size: 1.6rem
      font-weight: 700

    &-label
      color: #8894AC
      font-size: 1.4rem
      font-weight: 300
      line-height: 1
      margin-bottom: 0
