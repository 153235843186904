.page
    width: 100%
    display: flex
    align-items: flex-start
    justify-content: space-between
    flex-wrap: wrap
    gap: 60px 120px
    @media (min-width: 1600px)
        column-gap: 240px

    &--wrap
        flex-wrap: wrap

    &--wrap-reverse
        flex-wrap: wrap-reverse
        align-items: flex-end

    &__col
        flex-basis: 0
        flex-grow: 1
        min-width: 300px
        margin-bottom: 44px
        @media (min-width: $md)
            margin-bottom: 0

        &--box
            box-sizing: border-box
            flex-shrink: 0
            background: white
            box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
            border-radius: 10px
            padding: 2em 2em
            min-height: 300px

        &--center
            align-self: center

        &--lesson, &--live-class
            display: flex
            flex-direction: column

        &--live-class
            gap: 20px
            align-items: flex-start

    &__header
        margin-bottom: 28px

        & > :last-child
            margin-bottom: 0

    &__datetime
        color: #8894AC
        font-size: 1.2rem

    &__title
        color: var(--color-primary-dark)
        font-size: 1.6rem
        font-weight: 700
        margin-bottom: 16px

        &--ml
            margin-left: 20px

    &__soon
        background-image: repeating-linear-gradient(-45deg, transparent 0 3px, var(--color-bright) 3px 6px)
        min-height: 168px
        border-radius: 12px
        border: 1px solid #F6F7FA
        color: #7763AE
        text-transform: uppercase
        font-weight: 800
        display: grid
        place-content: center

    &__content
        background: white
        box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
        border-radius: 1rem
        flex-grow: 1
        flex-basis: 0
        padding: 1.5vw 3vw
        @media (min-width: $md)
            padding: 1.5vw 3vw

        &--transparent
            padding: 0
            background-color: transparent

        & > :last-child
            margin-bottom: 0

    &__aside
        margin: 0 auto
        flex: 0 0 100%
        max-width: 100%
        overflow: auto
        @media (min-width: $md)
            flex: 0 0 240px
            width: 240px
        @media (min-width: $xxl)
            flex: 0 0 344px
            width: 344px

        &--chat
            @media (min-width: $md)
                flex: 0 0 344px
                width: 344px
                display: flex
                flex-direction: column

        &--sticky
            position: sticky
            top: 0

        &--bg
            background-color: #fff
            box-shadow: $dropshadow
            border-radius: 10px
            padding: 20px

        &--forum
            @media (min-width: 814px)
                max-height: calc(100vh - 120px)

        &--calendar
            flex: 0 0 256px
            width: 256px

        &-title
            font-size: 2rem
            font-weight: 700
            margin-bottom: 8px
            color: var(--color-primary-dark)

        &-text
            font-size: 1.4rem
            font-weight: 300
            color: var(--color-neutral-dark)
            margin-bottom: 1.6rem

            &--tip
                background-color: var(--color-secondary-lightest)
                padding: 10px 16px

            &--excerpt
                display: -webkit-box
                -webkit-line-clamp: 2
                -webkit-box-orient: vertical
                overflow: hidden

            &--highlight
                border-radius: 10px
                background-color: var(--color-bright)
                padding: 10px

            &-group
                display: flex
                flex-wrap: wrap
                gap: 12px
                > *
                    margin-bottom: 0
                    flex-grow: 1

        &-icon
            width: 40px
            margin-bottom: 20px

        &-list
            list-style: disc
            li
                @extend .page__aside-text
                margin-left: 20px

.notebook
    &__side-header, &__side-body
        margin-bottom: 32px

    &__side-title
        padding: 12px
        text-align: center
        background: var(--color-bright)-gradient
        border: 2px solid var(--color-primary-lightest)
        border-radius: 6px
        margin-bottom: 0

    .form__check
        padding-left: 24px
        margin-bottom: 16px

        &-label
            font-size: 1.2rem
            font-weight: 800
            color: var(--color-neutral-darker) !important

        &-input
            width: 16px !important
            height: 16px !important
            margin-left: -24px
            border-color: #C4C4C4
            background-color: #C4C4C4

        &--success
            .form__check-label
                color: var(--color-secondary) !important

            .form__check-input
                border-color: var(--color-secondary)
                background-color: var(--color-secondary)
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>")
                background-size: 10px auto

.teacher-page
    position: relative

    &__box
        @extend .u-box
        padding: 3vw 3.5vw
        min-height: 520px

        &-header
            display: flex
            align-items: center
            justify-content: space-between
            gap: 2vw
            font-size: 1rem
            color: #9CA4B5
            margin-bottom: 16px

        &-body
            color: var(--color-neutral-darker)
            font-size: 1.2rem

        &-row
            padding: 8px 0
            display: flex
            align-items: center
            justify-content: space-between
            gap: 2vw
            &:not(:last-child)
                border-bottom: 1px solid var(--color-bright)

        &-course
            @extend .u-text-truncate
            flex: 1
            min-width: 0

    &__delay-title
        font-size: 1.2rem
        font-weight: 700
        margin-bottom: 0
        color: #9CA4B5
        position: absolute
        right: 16px
        top: -52px

.user-page
    &__nav
        display: flex
        align-items: center
        flex-wrap: wrap
        gap: 20px
        padding-bottom: 20px
        margin-bottom: 24px
        border-bottom: 1px solid var(--color-primary-lightest)
        width: 100%
    &__name
        color: var(--color-primary-dark)
        font-weight: 800
        line-height: 1.2
    &__since
        color: var(--color-neutral-darker)
        font-size: 1rem
        line-height: 1.2
