.accordion
  display: flex
  flex-direction: column
  gap: 12px

  &__item
    background-color: #fff
    border: 1px solid var(--color-neutral-lightest)
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
    border-radius: 8px

    &:hover
      box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.08)

    &.active
      .accordion__chevron, .card__icon-wrapper
        transform: rotate(-180deg)

  &__header
    padding: 12px 20px
    display: flex
    align-items: center
    gap: 20px
    cursor: pointer

  &__title
    @extend .u-text-truncate
    margin-bottom: 0
    flex-grow: 1
    font-size: 1.4rem
    font-weight: 700
    color: var(--color-neutral-dark)
    display: flex
    align-items: center
    gap: 12px
  
  .widget__count
    flex-shrink: 0

  &__chevron
    flex-shrink: 0
    stroke: var(--color-neutral-light)
    transition: transform .3s

  &__collapse
    height: 0
    transition: height .3s
    overflow: hidden

  &__content
    border-top: 1px solid var(--color-neutral-lightest)
    padding: 20px 0
    margin: 0 20px