.aside {
  width: 320px;

  &-competences {
    display: none;

    @media (min-width: 1280px) {
      display: block;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-border {
      padding-top: 24px;
      border-top: 1px solid var(--color-neutral-lightest);
    }

    P {
      color: var(--color-neutral-light);
      font-size: 1.2rem;
    }
  }
}

.page-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 32px;
  flex-wrap: wrap;

  &-main {
    max-width: 800px;
    flex: 1;
    margin: 0 auto;

    @media (max-width: 1024px) {
      flex: auto;
    }
  }
}

.annul-btn {
  margin-bottom: 24px;
  display: block;
  width: 100%;
  border-radius: 100px;

  &--only-xxl {
    display: block;

    @media (min-width: 1280px) {
      display: none;
    }
  }
}

.competences {
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05);
  border-radius: 10px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &-list {
    gap: 24px;
    display: flex;
    flex-wrap: wrap;
  }

  &-card {
    border: 1px solid var(--color-neutral-lightest);
    max-width: 300px;
    padding: 12px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-title {
      background-color: #fafafa;
      font-weight: 700;
      padding: 8px;
      display: block;
      text-align: center;
      border-radius: 8px;
      margin-bottom: 12px;
    }

    &-name {
      font-weight: 700;
      font-size: 1.4rem;
    }

    &-score {
      font-size: 1.6rem;
      line-height: 28px;
      margin-top: auto;
    }

    &-level {
      border-radius: 10px;
      margin-bottom: 12px;

      span {
        font-weight: 700;
      }
    }
  }
}

.tia-container {
  display: flex;
  gap: 24px;
  justify-content: center;
  font-size: 1.4rem;

  &-avatar {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
  }

  &-title {
    font-weight: 700;
    display: flex;
    justify-content: space-between;

    h2 {
      color: #46b0c3;
    }

    &-icon {
      width: 15px;
      height: 20px;
    }
  }

  &-text-balloon {
    background-color: #fff;
    border-radius: 10px;
    padding: 12px;
    position: relative;
    width: 100%;

    textarea {
      min-height: 190px;
      border: none;
      box-shadow: none;
      overflow-y: hidden;
      &:hover {
        border: 1px solid var(--color-neutral-bright);
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
      }
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      border-bottom: 20px solid transparent;
      border-top: 20px solid transparent;
      border-right: 20px solid #fff;
      top: 5%;
      right: 100%;
    }
  }
}

.score-card {
  border: 2px solid var(--color-neutral-bright, #f1f5fa);
  background: linear-gradient(10deg, #fff 20.02%, #f5f5f5 85.13%);
  box-shadow: 0px 4px 10px 0px rgba(119, 119, 120, 0.15);
  padding: 32px;
  text-align: center;
  margin-bottom: 12px;

  &-value {
    font-size: 3.5rem;
    font-weight: 800;

    &--success {
      color: var(--color-success);
    }

    &--warning {
      color: var(--color-warning);
    }
    &--danger {
      color: var(--color-danger);
    }
  }
}

.tab-only-xxl {
  display: flex;

  @media (min-width: 1280px) {
    display: none;
  }
}
