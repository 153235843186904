.game-container
  display: flex
  flex-direction: column
  padding: 10px
  padding-top: 20px
  background: #fafafa

.game-content
  display: flex
  gap: 20px
  margin-top: 20px

.concepts,
.meanings
  display: flex
  flex-direction: column
  gap: 10px
  flex: 1

.concepts
  width: 150px

.meanings
  width: 400px

.concept-wrapper
  height: 60px
  display: flex
  align-items: center

.draggable
  border: 2px solid var(--color-neutral-lightest, #ECE9F8)
  padding: 16px
  cursor: move
  transition: transform 0.2s, background-color 0.3s
  width: 100%
  box-sizing: border-box
  background-color: var(--color-bright, #F1F5FA)
  border-radius: 4px
  height: 100%
  color: var(--color-primary-dark, #110B3E)
  text-align: center
  font-size: 1.6rem
  font-weight: 700
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  position: relative

  .droppable__arrow
    left: -6px
    right: auto
    z-index: 0

  p
    margin: auto 0
  svg
    flex-shrink: 0
    width: 24px
    color: var(--color-primary)

  &--matching
    background-color: #6FCF97
    cursor: default
    color: #fff

.droppable
  background-color: #f8f8f8
  border: 2px solid #ccc
  padding: 10px
  min-height: 40px
  display: flex
  align-items: center

  &--matching
    border: 2px solid #6FCF97

.dragging
  opacity: 0.5

.drag-over
  background-color: #e0e0e0
  border-color: #4caf50

.congrats-message
  text-align: center
  font-size: 16px
  font-weight: 700
  color: #4caf50
  margin-top: 20px
  padding: 10px
  border: 2px solid #4caf50
  border-radius: 8px

.concepts
  width: 150px
  position: relative

.draggable.aligned
  background-color: #4caf50
  cursor: default

.droppable
  position: relative
  min-height: 60px

.game-content
  display: flex
  flex-direction: column
  gap: 20px

.concept-meaning-row
  display: flex
  flex-wrap: wrap
  align-items: center
  gap: 20px 0
  position: relative

  &--matching
    .concept-wrapper
      margin-left: 0

  &__icon
    position: absolute
    left: -22px

.concept-meaning-number
  flex-shrink: 0
  color: #000
  font-size: 1.6rem
  font-weight: 700
  margin-right: 20px

.concept-wrapper
  width: 264px
  max-width: 100%
  min-height: 148px
  margin-left: 36px

.droppable
  background-color: #fff
  padding: 24px
  flex: 1
  align-self: stretch
  border: 3px solid var(--color-neutral-lightest, #ECE9F8)
  border-left: 8px solid var(--color-primary-light)
  border-radius: 4px
  color: var(--color-neutral-darker, #5D6A86)
  font-size: 14px
  font-weight: 300
  cursor: default
  width: 264px
  max-width: 100%
  position: relative

  &__arrow
    position: absolute
    top: 0
    right: -16px
    height: 100%
    z-index: 1

    .arrow
      transition: fill 0.3s ease

    .highlight-dropzone &
      .arrow
        fill: #555

  &--matching
    border-left-color: #6FCF97

@keyframes shake
  0%,
  100%
    transform: translateX(0)

  20%,
  60%
    transform: translateX(-10px)

  40%,
  80%
    transform: translateX(10px)

@keyframes flashRed
  0%
    background-color: #4caf50

  50%
    background-color: #f44336

  100%
    background-color: #4caf50

.shake
  animation: shake 0.5s

.flash-red
  animation: flashRed 0.5s

/* Animação de shake negativa */
@keyframes shake-negative
  0%,
  100%
    transform: translateX(0)

  20%,
  60%
    transform: translateX(-10px)

  40%,
  80%
    transform: translateX(10px)

/* Animação de flash vermelho */
@keyframes flashRed
  0%
    background-color: var(--color-bright, #F1F5FA)

  25%, 75%
    background-color: #FF726C

  100%
    background-color: var(--color-bright, #F1F5FA)

/* Aplicando a combinação das duas animações */
.shake.flash-red
  animation: shake-negative 0.5s, flashRed 0.5s

.droppable
  transition: background-color 0.3s ease

.highlight-dropzone
  background-color: #555 /* Or a darker shade */
  color: white
