.question-countdown
  position: absolute
  inset: 0
  z-index: 1
  display: flex
  align-items: center
  justify-content: center
  padding: 28px max(16px, 2.6vw)
  background-color: rgba(255, 255, 255, .6)
  color: #fff
  text-align: center

  &__content
    width: 616px
    max-width: 100%
    min-height: 424px
    padding: 16px
    border-radius: 32px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 20px
    background-color: var(--color-primary)

  &__header
    position: relative
    display: flex
    flex-direction: column
    gap: 12px
    max-width: 100%

  .avatar
    position: absolute
    top: 0
    left: 0
    translate: -30% -30%
    width: clamp(56px, 7vw, 90px)
    height: clamp(56px, 7vw, 90px)
    aspect-ratio: 1/1
    border: 3px solid var(--color-primary-light)
    box-shadow: 0px 4px 4px 0px #00000040

  &__hat
    margin-bottom: 0
    color: #fff
    font-size: 1.2rem
    font-weight: 300
    line-height: 1.2

  &__title
    margin-bottom: 0
    color: var(--color-primary)
    font-size: 2.4rem
    font-weight: 800
    line-height: 1.2
    text-transform: uppercase
    width: 336px
    max-width: 100%
    padding: clamp(12px, 1.6vw, 20px) clamp(24px, 3.2vw, 40px)
    border-radius: 16px
    background: #fff
    border: 3px solid var(--color-primary-light)

  &__body
    text-align: center

  &__text
    margin-bottom: 20px
    font-size: 2rem
    font-weight: 700

  .btn
    --color-primary-lighter: #fff
    background-color: transparent
    color: #fff
    border-radius: 12px
    cursor: default
