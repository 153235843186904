.projects-header
  +g(full, space-between, middle)
  +i(1/1)
  background-color: var(--color-neutral-lightest)
  padding: 1em
  border-radius: 10px
  position: relative
  gap: 1em


  &__avatar
    +i(1/1)


  &__file
    @extend .u-text-truncate
    display: flex
    align-items: center
    gap: .5em
    background-color: white
    padding: .5em
    border-radius: 5px
    padding-right: 1em
    
    .documents-card__icon--with-bg
      flex-shrink: 0

  .post-comment__action-icon
    position: absolute
    top: .5em
    right: 1.5em

