.onboarding
  position: fixed
  inset: 0
  z-index: 999
  overflow: hidden
  pointer-events: none

  &__inner
    position: absolute
    inset: 0 0 0 auto
    z-index: 1
    width: 810px
    max-width: 80vw
    background-color: #fff
    border-radius: 8px
    transition: transform 0.15s
    transform: translateX(0)

    &--close
      transform: translateX(100%)

    &--open
      .onboarding__toggle
        background-color: var(--color-primary)

  &__toggle
    all: unset
    display: flex
    align-items: center
    gap: 8px
    position: absolute
    top: 50%
    left: -78px
    transform: rotate(-90deg)
    background-color: var(--color-primary-light)
    border-radius: 8px 8px 0 0
    padding: 2px 12px
    font-weight: 700
    font-size: 1.2rem
    color: #fff
    box-shadow: 0px 4px 10px 0px rgba(68, 43, 140, 0.05)
    transition: .15s
    cursor: pointer
    pointer-events: auto

    svg
      width: 16px

    &:hover
      background-color: var(--color-primary)
      box-shadow: 0px 4px 10px 0px rgba(68, 43, 140, 0.1)

  &__content
    padding: 32px
    overflow-y: auto
    position: absolute
    inset: 0
    pointer-events: auto
    background-color: #fff

  &__backdrop
    background-color: rgba(0, 0, 0, 0.2)
    display: block
    position: absolute
    inset: 0
    z-index: 0
    pointer-events: auto

.container-onboarding-list
  display: flex
  justify-content: space-around

.container-shadow
  display: flex
  gap: 18px

.onboarding-list
  list-style: disc
  margin-bottom: 1.6rem
  padding-left: 16px

.header-line
  border-bottom: 2px solid #ECE9F8

.title-option-onboarding
  color: var(--brand-primary-dark, #110B3E)
  font-family: Nunito
  font-size: 15px
  font-style: normal
  font-weight: 700
  line-height: 150%
  margin-bottom: 8px

.text-option-onboarding
  color: var(--brand-neutral-neutral-darker, #5D6A86)
  font-family: Nunito
  font-size: 14px
  font-style: normal
  font-weight: 300
  line-height: 150%

.container-pay-attention
  background: #F1F5FA
  padding: 10px
  display: flex
  align-items: flex-start
  border-radius: 10px
  gap: 12px

.bg-icon-pay-attention
  background: #FDB462
  padding: 12px
  border-radius: 100%

.info-icon
  width: 24px
  height: 24px
  max-width: fit-content
  color: #FFF

.m-0
  margin: 0

.mb-50
  margin-bottom: 50px

.mb-0
  margin-bottom: 0

.w-auto
  width: auto

.w-70
  width: 70%
