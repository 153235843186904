.spin-wheel-wrapper
  display: flex
  flex-direction: column
  align-items: center
  position: relative
  max-width: 100%

  &__count
    display: flex
    height: 48px
    padding: 4px 12px
    border-radius: 8px
    background: #FFF
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.6rem
    font-weight: 700
    animation: spinWheelCount .4s ease-out .5s 1 normal backwards
    position: relative
    z-index: 0

.spin-wheel
  aspect-ratio: 1 / 1
  width: 424px
  max-width: 100%
  border-radius: 100%
  background: #FFF
  box-shadow: 0px 6px 15px 4.5px rgba(68, 43, 140, 0.20)
  margin-top: -20px
  position: relative
  z-index: 1
  padding: max(12px, 1.8vw)

  &__spin
    width: 44px
    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.25))
    position: absolute
    left: 50%
    top: 50%
    translate: -50% -50%

  &__roulette
    background-color: #eee
    aspect-ratio: 1 / 1
    width: 376px
    max-width: 100%
    border-radius: 100%
    position: relative
    overflow: hidden
    transition: rotate 4s
    &--6-slices .spin-wheel__slice
      width: 58%
      left: 21%
    &--5-slices .spin-wheel__slice
      width: 72.5%
      left: 14%
    &--4-slices .spin-wheel__slice
      width: 100%
      left: 0%
    &--3-slices .spin-wheel__slice
      width: 173%
      left: -36.5%
    &--2-slices .spin-wheel__slice
      width: 100%
      left: 0%
      clip-path: unset
    &--1-slices
      .spin-wheel__slice-text
        rotate: 0deg
        max-width: initial
        width: 100%
        top: 30%
        left: 0
        position: absolute
        text-align: center
      .spin-wheel__slice
        width: 100%
        height: 100%
        left: 0%
        clip-path: unset

  &__slice
    height: 50%
    width: 50%
    position: absolute
    clip-path: polygon(100% 0 , 50% 100% , 0 0 )
    transform-origin: bottom
    padding: 24px
    display: flex
    align-items: center
    justify-content: center
    font-size: 1.4rem
    font-weight: 300
    text-align: right
    color: #fff
    left: 25%

    &--disabled
      opacity: .5

    &-text
      rotate: -90deg
      line-height: 1
      display: -webkit-box
      max-width: 200%
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      overflow: hidden
      max-width: 120px

@keyframes spinWheelCount
  from
    transform: translate3d(0, 100%, 0)
    visibility: visible
  to
    transform: translate3d(0, 0, 0)
