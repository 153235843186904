.main-sidebar
	position: fixed
	top: 0px
	left: 0
	right: 0px
	z-index: 105
	box-shadow: inset -5px -5px 20px rgba(#BCC4D6, 0.2)
	background: linear-gradient(140deg, #FFFFFF -30%, var(--color-primary-lightest) 100%)fixed
	+bp(md)
		bottom: 0px
		left: 0
		width: 260px
		z-index: 103

	&__mobile-hero
		background: var(--color-bright)
		+g(full, space-between, middle)
		padding: 1em
		padding-bottom: .5em
		padding-right: 1.5em
		border-bottom: 1px solid var(--color-primary-lightest)
		+bp(md)
			display: none

	&__mobile-hero-brand
		img
			height: 20px
			width: auto

	&__container
		position: fixed
		top: 46px
		left: 0
		right: 0px
		padding: 1em
		box-sizing: border-box
		height: 100vh
		overflow-x: hidden
		overflow-y: auto
		z-index: 10
		opacity: 0
		pointer-events: none
		margin-top: -10px
		transition: all ease-in-out .2s
		+bp(md)
			top: 0px
			bottom: 0px
			left: 64px
			padding: 44px 22px
			width: 260px
			display: flex
			flex-direction: column
			opacity: 1
			pointer-events: all
			margin-top: 0px
			.main-screen--admin &
				left: 0

		&.active
			opacity: 1
			pointer-events: all
			margin-top: 0px
			background: linear-gradient(180.71deg, #FFFFFF -30%, #F2FAFE 80%)
			box-shadow: 0 15px 15px rgba(var(--color-primary-dark), .1)
			max-height: calc(100vh - 46px)
			overflow-y: auto

	&__nav
		display: flex
		flex-direction: column
		flex-grow: 1

	&__link
		display: flex
		flex-wrap: nowrap
		gap: 12px
		border-radius: 50px
		padding: 12px
		color: var(--color-primary)
		margin: .2em 0
		transition: all ease-in-out .2s
		&:hover, &.active
			color: #FFF
			cursor: pointer
		&.active
			background: var(--color-primary)
		&:hover, &:focus
			background: var(--color-primary-light)

		svg
			display: inherit

	&__bottom-links
		margin-top: 2em
		+bp(md)
			margin-top: auto
		.main-sidebar__link
			&:hover, &.active
				background: var(--color-primary)
				color: #FFF
				cursor: pointer

	&__highlight-links
		margin-top: 1em
		padding-top: 1em
		border-top: 1px solid var(--color-primary-lightest)
		.main-sidebar__link
			border: 1px solid var(--color-primary-lightest)
			box-shadow: $dropshadow
			&.active
				background: white

	&__link-icon
		flex-shrink: 0
		height: 15px
		svg
			height: 15px

	&__link-name
		min-width: 0
		flex-grow: 1
		font-size: .9em
		@extend .u-text-truncate

	&__brand
		max-width: 170px
		margin-bottom: 3em
		display: none
		+bp(md)
			display: block

.simulated
	&__section
		border-bottom: 1px solid var(--color-primary-lightest)
		padding-bottom: 20px
		margin-bottom: 20px

	&__progress
		padding-bottom: 12px

	&__header
		display: flex
		align-items: center
		gap: 16px

	&__container
		flex: 1

	&__notebook
		margin-bottom: 0
		font-weight: 700
		font-size: 1.4rem
		text-transform: uppercase
		color: var(--color-primary-dark)

	&__teacher
		font-size: 1.2rem
		color: var(--color-primary-dark)
		line-height: 1.2
		margin-bottom: 0

	&__questions
		font-size: 1.2rem
		color: var(--color-neutral)
		display: flex
		align-items: center
		gap: 12px
		padding: 8px 16px
		border-radius: 50px
		cursor: pointer

		&.is-selected
			background-color: var(--color-primary-lightest)
			color: var(--color-primary)
			font-weight: 800

	&__questions-name
		@extend .u-text-truncate
		flex: 1
