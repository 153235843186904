.icon {
  flex-shrink: 0;
  display: inline-flex;
  color: var(--color-primary);

  svg {
    min-width: 20px;
  }
}

.wrapper {
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--color-primary-lightest);
  padding: 2px;
  border-radius: 4px;
}
