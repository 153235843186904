.custom-guide
  display: flex
  flex-direction: column
  gap: 20px

  &__header
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 16px

  &__title, &__text
    margin-bottom: 0

  &__title
    flex-grow: 1
    color: var(--color-neutral-darker, #5D6A86)
    font-size: 1.6rem
    font-weight: 300
    strong
      font-weight: 700

  &__body
    display: flex
    flex-direction: column
    gap: 20px
    padding: 20px
    border-radius: 10px
    border: 1px solid var(--color-neutral-lightest, #ECE9F8)
    background: #fff
    box-shadow: 0px 4px 10px 0px rgba(119, 119, 120, 0.15)

  &__text
    color: var(--color-neutral-darker, #5D6A86)
    font-size: 1.4rem
    font-weight: 300
