.report-tab
  padding: 0 clamp(16px, 4vw, 56px)
  overflow-x: auto
  display: flex
  align-items: bottom
  gap: 12px
  position: relative
  z-index: 1

  &__link
    appearance: none
    border: 0
    color: #fff
    font-size: 1.4rem
    font-weight: 700
    padding: 12px 20px
    text-transform: uppercase
    border-radius: 10px 10px 0px 0px
    background: none
    cursor: pointer
    transition: .16s

    &:hover, &:focus
      background: rgba(250, 250, 250, .5)
      color: var(--brand-primary-dark, #110B3E)

    &--is-active
      color: var(--brand-primary-dark, #110B3E)
      background: var(--color-neutral-bright, #FAFAFA)
      &:hover, &:focus
        background: var(--color-neutral-bright, #FAFAFA)
