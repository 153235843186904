.vegalabs
  --color-primary-dark: #351A07
  --color-primary: #FF6B00
  --color-primary-light: #DBA680
  --color-primary-lighter: #CBB8A6
  --color-primary-lightest: #F5EDE7

  --color-secondary-dark: #0FB1BB
  --color-secondary: #5DC9DD
  --color-secondary-light: #74DBE1
  --color-secondary-lighter: #A7DDE0
  --color-secondary-lightest: #DEF6F8
