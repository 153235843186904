.manage-content
  background-color: #fff
  border-radius: 12px
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
  padding: 20px
  display: flex
  flex-wrap: wrap
  gap: 40px

  &__sidebar
    max-width: 100%
    width: 100%
    @media (min-width: $md)
      border-right: 1px solid var(--color-neutral-lightest)
      padding-right: 40px
      width: 220px
      

  &__content
    width: 120px
    flex-grow: 1
    @media (min-width: $md)
      margin-top: 86px