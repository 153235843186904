.guide-timeline
  display: flex
  flex-direction: column
  gap: 52px
  position: relative
  padding-bottom: 32px
  width: 100%
  max-width: 100%
  @media (min-width: $xl)
    margin-left: 128px

  &::before
    content: ''
    width: 0
    border-left: 2px dashed var(--color-neutral-light)
    position: absolute
    top: 0
    bottom: 0
    left: 50%
    translate: -50%
    z-index: 0

  &__day
    display: flex
    flex-direction: column
    gap: 20px
    z-index: 1

    &--pause
      background: var(--color-bright)
      gap: 0

    &:first-of-type, &:last-of-type
      &::before
        content: ''
        background: var(--color-neutral-light)
        width: 12px
        height: 12px
        border-radius: 50px
        position: absolute
        left: 50%
        translate: -50% -50%

    &:last-of-type
      &::before
        bottom: 0
        translate: -50% 50%

  &__header, &__footer
    display: flex
    align-items: center
    gap: 0 12px

  &__header
    scroll-margin-top: 164px

  &__icon
    width: 32px

  &__title, &__date
    margin-bottom: 0
    font-size: 1.2rem
    font-weight: 300

  &__title
    color: var(--color-neutral-darker, #5D6A86)

    .guide-timeline__day--pause &
      font-size: 2rem
      color: var(--color-primary-dark)
      font-weight: 700

  &__date
    flex-shrink: 0
    color: var(--color-neutral)
    margin-left: auto

  &__cards
    display: flex
    flex-direction: column
    gap: 12px

  &__body
    padding: 20px 12px
    text-align: center
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: center
    gap: 12px

  &__text
    margin-bottom: 0
    font-size: 1.2rem
    font-weight: 300
    color: var(--color-neutral-darker, #5D6A86)

  &__img
    width: 96px
    height: 72px
