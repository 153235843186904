.post
	@extend .fadeIn
	background: white
	border-radius: 10px
	box-shadow: $dropshadow
	padding: 1em
	border: 1px solid var(--color-bright)
	transition: all .15s
	+bp(md)
		padding: 2em
	+g(space-between)

	&:hover
		box-shadow: $dropshadow-md

	&__avatar
		width: 30px
		height: 30px
		background-size: cover
		background-position: center
		border-radius: 50px
		border: 1px solid var(--color-primary-lightest)
		cursor: pointer

		.avatar
			width: 30px
			height: 30px

		+bp(md)
			width: 35px
			height: 35px

			.avatar
				width: 35px
				height: 35px

	&__comments
		box-sizing: border-box
		+i(10/10)
		border-top: 3px solid var(--color-primary-lightest)
		margin: 2em 0 2em
		padding-top: 1em

	&__body
		+i(15/20, md 16/20)

	&__actions
		width: 15px

	&__header-name
		cursor: pointer
		font-weight: 700
		font-size: .9em
		line-height: 1.2em
		+bp(md)
			line-height: inherit
			font-size: 1em

	&__header-time
		color: var(--color-neutral)
		font-size: .8em

	&__header
		padding-bottom: 1em

	&__description, &__description-text
		color: var(--color-neutral-darker)
		line-height: 1.5em
		font-size: .85em
		white-space: pre-line
		word-wrap: break-word
		+bp(md)
			font-size: .92em

	&__description-image
		margin-top: 1em
		min-height: 200px
		+i(1/1)
		background: var(--color-primary-lightest)
		background-size: cover !important
		background-position: center !important
		background-repeat: no-repeat !important
		border-radius: 10px

	&__action
		text-align: center
		margin-bottom: .5em

	&__action-icon
		width: 15px
		margin: 0 auto
		transition: transform ease-in-out .2s
		color: var(--color-primary)
		cursor: pointer
		&:hover
			cursor: pointer
			transform: scale(1.2)

		&.filled
			svg
				fill: var(--color-primary-light)

	&__action-count
		font-size: .75em
		color: var(--color-primary-light)
		font-weight: 800
		margin-top: 0em
		animation: fadeIn .5s

	.magic-link
		padding: 0em
		margin-top: 2em
		+bp(md)
			border: 1px solid var(--color-primary-lightest)
			border-radius: 10px
			overflow: hidden
			padding-top: 1em
			padding-bottom: 1em
			padding-right: 1em
			margin-left: -5vw
			width: calc(100% + 8.2vw)
		&__body
			+bp(md)
				margin-left: 35%
