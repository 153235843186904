.egepi
  --color-primary-dark: #052050
  --color-primary: #204D9C
  --color-primary-light: #61B1DB
  --color-primary-lighter: #9ECFE9
  --color-primary-lightest: #E0F0F9

  --color-secondary-dark: #DD5034
  --color-secondary: #9D2710
  --color-secondary-light: #CAEFDB
  --color-secondary-lighter: #9D2710
  --color-secondary-lightest: ##DD5034

.egepi .mock__content
  @media only screen and (min-width: 49em)
    background-image: url('../../images/egepi/horizontal-logo.svg')
    background-position: 85% center
