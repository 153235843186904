.form,
.row {
  display: flex;
  flex-direction: column;
}

.form {
  gap: 16px;
}

.row {
  gap: 4px;

  &.disabled {
    pointer-events: none;
    opacity: 0.8;

    label {
      color: var(--color-neutral);
    }
  }
}

.label {
  font-weight: 700;
  font-size: 1.4rem;
  color: var(--color-primary);
}

.control {
  appearance: none;
  border-radius: 5px;
  border: 1px solid var(--color-neutral-lighter);
  padding: 8px 16px;
  font-size: 1.6rem;
  background: white;
  color: var(--color-neutral);
  font-weight: 300;
  display: block;
  height: 40px;
  box-sizing: border-box;
  width: 100%;
  transition: 0.16s;

  &:hover {
    border-color: var(--color-neutral);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--color-primary);
  }

  &:disabled {
    color: var(--color-neutral);
    pointer-events: none;
    opacity: 0.24;
  }

  &.valid {
    border-color: var(--color-success, #1ca388);
  }

  &.invalid {
    border-color: var(--color-danger, #fd6b62);
  }
}

.field {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  color: var(--color-neutral-darker, #5d6a86);
  font-size: 1.4rem;
  font-weight: 300;
  user-select: none;

  input {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid var(--color-neutral-light);
    appearance: none;
    print-color-adjust: exact;
    margin: 3px 0 0 0;

    &[type='checkbox'] {
      border-radius: 0.25em;

      &:checked {
        border-color: var(--color-primary);
        background-color: var(--color-primary);
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>");
        background-size: 0.6em;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &[type='radio'] {
      border-radius: 50px;

      &:checked {
        border-color: var(--color-primary);
        background-color: var(--color-primary);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
        background-size: 0.6em;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  &.round {
    & [type='checkbox'] {
      border-radius: 50%;
    }
  }

  a {
    font-weight: 700;
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
}

.link {
  align-self: flex-start;
  color: var(--color-neutral, #8894ac);
  font-size: 1.4rem;
  font-weight: 300;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.feedback {
  font-size: 14px;
  font-weight: 300;

  &.valid {
    color: var(--color-success, #1ca388);
  }

  &.invalid {
    color: var(--color-danger, #fd6b62);
  }
}

.fieldGradeWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}

.fieldGrade {
  cursor: pointer;
  border-radius: 8px;
  border-left: 10px solid #b9b9b9;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(93, 106, 134, 0.05);
  color: #000;
  font-size: 2rem;
  font-weight: 700;

  input {
    display: none;

    &:checked + span {
      background: #ccc;
    }
  }

  span {
    display: block;
    width: 100%;
    height: 100%;
    padding: 20px;
    min-height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 8px 8px 0;
  }

  &.firstGrade {
    border-color: #8ea7e4;

    input:checked + span {
      background: #8ea7e4;
    }
  }

  &.secondGrade {
    border-color: #fdb462;

    input:checked + span {
      background: #fdb462;
    }
  }

  &.thirdGrade {
    border-color: #006b30;

    input:checked + span {
      background: #006b30;
      color: #fff;
    }
  }

  &.eja {
    border-color: #254aa5;

    input:checked + span {
      background: #254aa5;
      color: #fff;
    }
  }
}

.terms {
  display: flex;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid var(--color-neutral-lightest, #ece9f8);
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(93, 106, 134, 0.05);
  color: var(--color-neutral-darker, #5d6a86);
  font-family: Nunito;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 150%;
  resize: none;
  width: 100%;
  height: 240px;
}
