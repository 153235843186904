.font-bold 
  font-weight: 700

.text-primary 
  color: var(--color-primary)

.text-primary-dark 
  color: var(--color-primary-dark)

.text-neutral
  color: var(--brand-neutral-neutral, #8894AC)

.text-lg
  font-size: 1.6rem

.text-md
  font-size: 1.4rem

.text-sm
  font-size: 14px

.border-t
  border-top: 1px solid var(--color-neutral-lightest)

.border-l
  border-left: 1px solid var(--brand-neutral-bright, #F1F5FA)

.border-l-rounded
  border-top-left-radius: 5px !important
  border-bottom-left-radius: 5px !important

.border-r-rounded
  border-top-right-radius: 5px !important
  border-bottom-right-radius: 5px !important

.mt-20
  margin-top: 20px

.bg-gray-100
  background-color: #f9f9f9

.container-header-attempt-modal
  display: flex
  justify-content: space-between
  border-bottom: 1px solid var(--color-bright)
  margin-bottom: 20px

.container-attempt-card
  overflow-x: auto
  border: 1px solid var(--color-neutral-lightest)
  border-radius: 5px 
  box-shadow: 0px 4px 10px 0px rgba(68, 43, 140, 0.05)
  padding: 0 20px
  margin-bottom: 20px

.attempt-card
  background-color: #fff
  min-height: 51px
  display: flex
  align-items: center
  justify-content: space-between

.attempt-title
  color: var(--color-primary-dark)
  font-weight: 700
  font-size: 1.4rem
  text-wrap: nowrap

.container-question-id
  border-radius: 2px
  background: var(--brand-neutral-bright, #F1F5FA)
  padding: 1px 3px



