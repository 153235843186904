.btn {
  cursor: pointer;
  background: #fff;
  color: var(--color-primary);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  font-size: 1.6rem;
  font-family: 'Nunito', sans-serif;
  line-height: 1.5;
  border: 1px solid transparent !important;
  border-radius: 100px;
  padding: 11px 24px;
  transition: all ease-in-out 0.2s;
  appearance: none;
  font-weight: 700;
  max-width: 100%;

  &.outline {
    border-color: var(--color-primary-lightest) !important;

    &:hover,
    &:focus,
    &.active {
      color: #fff;
      background: var(--color-primary);
      border-color: var(--color-primary);
    }
  }

  &.full {
    width: 100%;
  }

  &.review {
    color: #fff;
    border: 4px solid #fff !important;
    background-color: var(--color-primary-light);
    box-shadow: 0px 4px 10px 3px rgba(93, 106, 134, 0.2);

    &:hover {
      border-color: var(--color-primary) !important;
      background-color: var(--color-primary);
    }
  }

  &.primary {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    color: white;

    &:hover,
    &:focus {
      background-color: var(--color-primary-dark);
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.24;
  }

  &Wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-top: 12px;
  }
}
