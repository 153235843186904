.metrics
    position: relative
    padding: 40px 20px 20px
    border: 3px solid var(--color-primary-lighter)
    border-radius: 8px

    &__main-content
        width: 100%
        max-width: 100%
        @media (min-width: 1280px)
            width: 900px
            flex-grow: 1

        > .widget
            padding: 2em !important

    &--list
        display: flex
        flex-direction: column
        gap: 10px

    p
        font-size: 12px
        margin: 0

    &__card
        display: flex
        justify-content: space-between
        align-items: center
        gap: 12px
        flex-wrap: wrap

        p
            max-width: 350px

        &--done-medal
            width: 36px
            position: absolute
            right: 0
            top: 0
            translate: 40% -40%

        &--btn-icon
            width: 15px

    &__tag
        display: inline-flex
        padding: 2px 8px
        justify-content: center
        align-items: center
        border-radius: 0px 0px 6px 6px
        border: 2px solid #FFF
        border-top: 0
        background: var(--color-primary-lighter)
        box-shadow: 0px 4px 10px 0px rgba(68, 43, 140, 0.05)
        color: var(--color-primary)
        font-size: 1.4rem
        font-weight: 700
        text-transform: uppercase
        position: absolute
        top: 0
        left: 50%
        transform: translateX(-50%)
        width: max-content

.metrics-header
    border-bottom: 1px solid #ECE9F8
    margin: 20px 0
    display: flex
    flex-direction: column

    &-level
        display: flex
        justify-content: space-between

    &-title
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center

        span
            font-size: 18px
            font-weight: 700

        &--disabled
            color: var(--color-neutral-light)

    &-points
        font-size: 14px
        margin-bottom: 12px

.metrics__news
    position: relative
    padding: 2em !important
    @media (min-width: 1280px)
        max-width: 432px

    &-header
        display: block
        margin: 12px 0 8px

        &--title
            font-weight: 700
            font-size: 18px

        &--points
            display: flex
            justify-content: space-between
            color: var(--color-neutral)
            font-size: 14px
            gap: 12px

    &-card
        width: 100%
        justify-content: space-between
        display: flex
        flex-direction: row
        align-items: center
        gap: 12px
        border: 1px solid var(--color-primary-lightest)
        border-radius: 8px
        // height: 70px
        padding: 8px
        flex-grow: 1

        &-content
            display: flex
            align-items: center
            gap: 10px

        p
            font-size: 14px
            margin: 0

        &-img
            min-width: 50px
            height: 60px
            background-color: #F1F5FA
            display: flex
            align-items: center
            flex-direction: column
            justify-content: center
            border-radius: 8px
            padding: 3px

            p

            span
                font-size: 10px
                font-weight: 700
                color: #5D6A86

            img
                border-radius: 50%
                object-fit: cover
                height: 35px
                width: 35px
