.module-wrapper
  display: flex
  flex-direction: column
  gap: 12px
  max-height: 225px
  overflow-y: auto
  padding: 6px 8px 12px 6px
  margin-right: -8px

  &--grid
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 250px), 1fr))

.module-card
  background-color: white
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
  border-radius: 12px
  border: 1px solid var(--color-neutral-lightest)
  padding: 16px
  display: flex
  align-items: center
  justify-content: space-between
  gap: 16px
  position: relative
  transition: all ease-in-out .2s
  &:hover
    box-shadow: 0 2px 10px rgba(black, .15)

  &, .badge
    cursor: pointer

  .medal
    position: absolute
    top: 0
    right: 0
    translate: 50% -50%

  .moduleBadge
    display: flex
    align-items: center
    justify-content: flex-start
    background-color: #F1F5FA
    gap: 10px
    padding: 3px
    border-radius: 4px

  .btn--outline
    display: none
    @media (min-width: $sm)
      display: inline-flex

  &__header
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 12px
    line-height: 1
    width: 260px
    max-width: 100%

  &__headerModuleList
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 12px
    line-height: 1
    width: 350px
    max-width: 100%

  &__img
    width: 48px
    height: 48px
    object-fit: cover
    border-radius: 8px

  &__inner-header
    width: 60px
    flex-grow: 1

  &__title
    @extend .u-text-truncate
    width: 0
    flex-grow: 1
    color: var(--color-primary-dark)
    font-size: 1.6rem
    font-weight: 700
    line-height: 1
    margin-bottom: 0

  &__subtitle, &__text
    color: var(--color-neutral)
    font-weight: 300
    font-size: 1.4rem
    line-height: 1
    margin-bottom: 4px

  &__text
    flex-shrink: 0

  &__footer
    flex-shrink: 0
    width: 100%
    @media (min-width: 1330px)
      width: 152px

  &__link
    line-height: 1
    display: flex
    align-items: center
    gap: 4px
    margin-bottom: 0

  &__link-text
    @extend .u-text-truncate
    color: var(--color-neutral-dark)
    font-weight: 700
    font-size: 1.4rem
    text-decoration: underline
