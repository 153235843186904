.error-404
  background: linear-gradient(180deg, #F5FBFC 0%, #ECECF9 100%)
  width: 100vw
  height: 100vh
  overflow-y: auto
  padding: 52px
  display: flex
  align-items: flex-start
  @media (min-width: $md)
    align-items: center

  &__inner
    width: min(1080px, 100%)
    margin: 0 auto

  &__logo
    width: 100px
    margin-bottom: 32px
    display: block

  &__img
    display: block
    width: 328px
    margin-bottom: 28px

  &__title
    font-weight: 700
    font-size: 2.8rem
    color: var(--color-neutral-dark)

  &__description
    font-weight: 300
    font-size: 2rem
    color: var(--color-primary-dark)
    margin-bottom: 36px
