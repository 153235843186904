.avatar
  text-transform: uppercase
  text-align: center
  width: 44px
  height: 44px
  aspect-ratio: 1/1
  border-radius: 100px
  border: 1px solid var(--color-primary-lightest)
  background-color: var(--color-primary-lightest)
  color: var(--color-primary)
  font-size: 1.2rem
  font-weight: 700
  line-height: 1
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden
  object-fit: cover

  &--xxs
    width: 24px
    height: 24px

  &--xs
    width: 32px
    height: 32px

  &--sm
    width: 40px
    height: 40px

  &--md
    width: 60px
    height: 60px

  &--lg
    width: 128px
    height: 128px

  &--xl
    width: 152px
    height: 152px

.avatar-stack
  display: flex
  align-items: center
  list-style: none
  min-width: 0
  cursor: pointer

  &--md
    .avatar-stack__item
      max-width: 44px

  &__item
    min-width: 0
    max-width: 24px

    &:last-of-type
      min-width: fit-content

  .avatar
    max-width: none
    cursor: pointer
    transition: transform .15s

    &:hover
      position: relative
      z-index: 1
      transform: translateY(-4px)
