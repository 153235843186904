
.u-box
    background: white
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
    border-radius: 1rem
    transition: all .15s
    &:hover
        box-shadow: 0px 4px 20px rgba(68, 43, 140, 0.25)

.u-box-hoverless
    background: white
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
    border-radius: 1rem
    transition: all .15s

.u-text-truncate
    display: inline-block
    max-width: 100%
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.u-text-left
    text-align: left !important

.u-text-center
    text-align: center !important

.u-text-right
    text-align: right !important

.u-text-neutral-dark
    color: var(--color-neutral-dark) !important

.u-text-danger
    color: var(--color-danger) !important

.u-uppercase
    text-transform: uppercase
.u-capitalize
    text-transform: capitalize

.u-overflow-x
    overflow-y: hidden
    overflow-x: auto
.u-overflow-y
    overflow-y: auto
    overflow-x: hidden
.u-overflow
    overflow: auto

.u-w-100
    width: 100% !important

.u-w-50
    width: 50%

.u-w-lg-50
    @media (min-width: $lg)
        width: 50%

.u-h-100
    height: 100%

.u-m-0
    margin: 0 !important

.u-ml-auto
    margin-left: auto !important

.u-mt-auto
    margin-top: auto !important
.u-mt-0
    margin-top: 0 !important
.u-mt-1
    margin-top: .4rem !important
.u-mt-2
    margin-top: .8rem !important
.u-mt-3
    margin-top: 1.6rem !important
.u-mt-4
    margin-top: 2.4rem !important
.u-mt-5
    margin-top: 4.8rem !important

.u-mx-auto
    margin: 0 auto

.u-my-auto
    margin: auto 0

.u-mb-0
    margin-bottom: 0 !important
.u-mb-1
    margin-bottom: .4rem !important
.u-mb-2
    margin-bottom: .8rem !important
.u-mb-3
    margin-bottom: 1.6rem !important
.u-mb-4
    margin-bottom: 2.4rem !important
.u-mb-5
    margin-bottom: 4.8rem !important

.u-mr-0
    margin-right: 0 !important
.u-mr-1
    margin-right: .4rem
.u-mr-2
    margin-right: .8rem
.u-mr-3
    margin-right: 1.6rem
.u-mr-4
    margin-right: 2.4rem
.u-mr-5
    margin-right: 4.8rem

.u-ml-0
    margin-left: 0 !important
.u-ml-1
    margin-left: .4rem
.u-ml-2
    margin-left: .8rem

.u-ml-2-5
    margin-left: 1rem

.u-ml-3
    margin-left: 1.6rem
.u-ml-4
    margin-left: 2.4rem
.u-ml-5
    margin-left: 4.8rem

.u-p-0
    padding: 0 !important
.u-p-1
    padding: 16px
.u-pl-0
    padding-left: 0 !important
.u-pl-1
    padding-left: .4rem
.u-pl-2
    padding-left: .8rem
.u-pl-3
    padding-left: 1.6rem
.u-pl-4
    padding-left: 2.4rem
.u-pl-5
    padding-left: 4.8rem
.u-pr-0
    padding-right: 0 !important
.u-pr-1
    padding-right: .4rem
.u-pr-2
    padding-right: .8rem
.u-pr-3
    padding-right: 1.6rem
.u-pr-4
    padding-right: 2.4rem
.u-pr-5
    padding-right: 4.8rem
.u-py-0
    padding-top: 0 !important
    padding-bottom: 0 !important
.u-pb-0
    padding-bottom: 0 !important
.u-mh-none
    max-height: none !important
    overflow-y: initial !important

.u-center
    display: grid
    place-content: center

.is-close
    display: none

.is-open
    display: block

.u-cursor-help
    cursor: help
.u-cursor-default
    cursor: default !important
.u-cursor-pointer
    cursor: pointer !important

.u-visually-hidden
    position: absolute !important
    width: 1px !important
    height: 1px !important
    padding: 0 !important
    margin: -1px !important
    overflow: hidden !important
    clip: rect(0,0,0,0) !important
    white-space: nowrap !important
    border: 0 !important

.u-d-block
    display: block !important
.u-d-inline-block
    display: inline-block !important

.u-ratio
    position: relative

    &--16x9
        padding-bottom: 56.25%

    iframe
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

.u-hr
    border: 0
    border-top: 1px solid var(--color-neutral-lightest)
    margin: 32px 0

.u-fg-1
    flex-grow: 1
