.course-wrapper
  display: flex
  flex-direction: column
  gap: 12px
  overflow-y: auto
  padding-right: 8px
  margin-right: -8px

  &--list
    max-height: 550px
    @media (min-width: 1330px)
      max-height: 344px

.course-card
  background-color: white
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
  border-radius: 12px
  padding: 24px
  display: flex
  flex-wrap: wrap
  align-items: flex-end
  gap: 32px
  cursor: pointer
  transition: all ease-in-out .2s
  @media (min-width: 1330px)
    gap: 60px

  &:hover
    box-shadow: 0 6px 10px rgba(black, .15)

  &__img-wrapper
    position: relative
    .badge
      position: absolute
      left: 8px
      top: 8px
      z-index: 1
      max-width: 80px

  &__img
    width: 110px
    height: 110px
    object-fit: cover
    border-radius: 12px

    &--md
      width: 136px
      height: 136px

      @media (min-width: $lg)
        width: 272px

  &__header
    display: flex
    align-items: center
    gap: 12px

  &__inner-header
    width: 0px
    flex-grow: 1
    display: flex
    flex-direction: column
    gap: 4px

  &__title
    @extend .u-text-truncate
    color: var(--color-primary-dark)
    font-size: 1.6rem
    font-weight: 700
    line-height: 1
    margin-bottom: 0

  &__subtitle, &__text
    color: var(--color-neutral-light)
    font-weight: 300
    font-size: 1.4rem
    line-height: 1
    margin-bottom: 4px
    strong
      color: var(--color-neutral)

  &__notifications
    flex-shrink: 0
    display: flex
    align-items: center
    gap: 12px

  &__autor
    font-size: .8em
    color: var(--color-neutral)
    margin-top: .5em

  &__description
    font-size: .8em
    color: var(--color-neutral-dark)
    margin: 1vw 0 1.5vw

  &__body
    flex-grow: 1
    display: flex
    align-items: center
    gap: 24px

    &--home
      align-items: stretch

    .medal
      flex-shrink: 0
      align-self: center

  &__inner-body
    width: 120px
    flex-grow: 1
    flex-shrink: 1

  &__content
    width: 120px
    flex-grow: 1
    flex-shrink: 1
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 6px 0

  &__tag
    flex-shrink: 0
    align-self: flex-start
    background-color: var(--color-success)
    border-radius: 4px
    font-size: 1.2rem
    font-weight: 700
    line-height: 1.5
    color: #fff
    display: inline-flex
    align-items: center
    justify-content: center
    gap: 10px
    padding: 6px 12px

    svg
      width: 16px

  &__footer
    flex-shrink: 0
    width: 100%
    @media (min-width: 1330px)
      width: 152px

  &__link
    line-height: 1
    display: flex
    align-items: center
    gap: 4px
    margin-bottom: 0

  &__link-text
    @extend .u-text-truncate
    color: var(--color-neutral-dark)
    font-weight: 700
    font-size: 1.4rem
    text-decoration: underline
