.btn
  border-radius: 100px
  text-align: center
  padding: 6px 12px
  transition: all ease-in-out .2s
  height: 40px
  font-size: 13px!important
  display: inline-flex
  justify-content: center
  gap: 8px
  align-items: center
  box-shadow: $dropshadow
  font-weight: 700
  -webkit-appearance: none
  -moz-appearance: none
  line-height: 0px!important
  &:hover
    cursor: pointer

  &--primary
    background-color: var(--color-primary)
    border: 1px solid var(--color-primary)
    color: white
    &:hover
      background-color: var(--color-primary-dark)

  &--secondary
    background: var(--color-third-light)
    border: 1px solid var(--color-third-light)
    color: white
    &:hover
      background: var(--color-third)

  &--secondary-dark
    background: var(--color-secondary-dark)
    border: 1px solid var(--color-secondary-dark)
    color: white
    &:hover
      background: var(--color-secondary)

  &--third
    background: var(--color-primary-lightest)
    border: 1px solid var(--color-primary)
    color: var(--color-primary-dark)
    &:hover
      color: white
      border: 1px solid var(--color-primary-dark)
      background: var(--color-primary-dark)

  &--success
    background: var(--color-secondary)
    border: 1px solid var(--color-secondary-light)
    color: white
    &:hover
      color: white
      background: var(--color-secondary-light)

  &--done
    background: #E4FEEE
    color: var(--color-success)
    font-weight: 700
    border: none
    padding: 0 30px
    &:hover
      cursor: default

  &--rightAnswer
    background: #1CA388
    border: 1px solid var(--color-secondary-light)
    color: white
    &:hover
      color: white
      background: #1CA388

  &--danger
    background: var(--color-danger)
    border: 1px solid var(--color-danger)
    color: white
    &:hover
      color: white
      background: var(--color-danger)

  &--dark
    background: var(--color-neutral)
    border: 1px solid var(--color-neutral)
    color: white
    &:hover
      color: white
      background: var(--color-neutral-darker)

  &--third
    background: var(--color-primary-lightest)
    border: 1px solid var(--color-primary-lightest)
    color: var(--color-primary-dark)
    &:hover
      color: white
      border: 1px solid var(--color-primary-dark)
      background: var(--color-primary-dark)

  &--review
    color: #fff
    border: 2px solid #fff
    background-color: var(--color-primary-light)
    box-shadow: $dropshadow-md

    &:hover
      border-color: var(--color-primary)
      background-color: var(--color-primary)

  &--orange
    color: #fff
    border: 2px solid #fff
    background-color: #F99153
    box-shadow: $dropshadow-md

    &:hover
      border-color: #F99153
      background-color: #FFECD8
      color: #F99153
      border-color: #FFECD8

  &--turbo
    color: #fff
    border: 2px solid #fff
    background-color: #FF4C6F
    box-shadow: $dropshadow-md

    &:hover
      border-color: #FF4C6F
      background-color: #FFE7E5
      color: #FF4C6F
      border-color: #FFE7E5

  &--full
    +i(1/1)

  &--small
    padding: .6em 1em!important
    font-size: .9em
    height: 36px!important

  &--tiny
    padding: .2em .6em!important
    font-size: .8em
    height: 24px

  &--wide
    @media (min-width: $md)
      padding-left: 48px !important
      padding-right: 48px !important

  &--outline
    background: white
    border: 1px solid var(--color-primary-lightest)
    color: var(--color-primary)
    &:hover, &.active
      color: white
      background: var(--color-primary)
      border-color: var(--color-primary)

    &-secondary
      background: none
      border: 1px solid var(--color-third-light)
      color: var(--color-third-light)

    &-success
      background: none
      border: 1px solid var(--color-success)
      color: var(--color-success)

    &-danger
      background: none
      border: 1px solid var(--color-danger)
      color: var(--color-danger)

    &-blue
      background: none
      border: 1px solid #2F80ED
      color: #2261A9

    &-white
      background: none
      border: 1px solid #fff
      color: #fff

    &-dashed
      background: none
      border: 1px dashed var(--color-neutral)
      color: var(--color-neutral)
      align-items: center
      &:hover
        color: var(--color-neutral-light)
        border-color: var(--color-neutral-light)

  &--send
    box-sizing: border-box
    +g(center, middle)
    width: 40px
    height: 40px
    padding: 0
    img, svg
      width: 15px
      height: 15px

  &--link
    color: var(--color-primary)
    text-decoration: underline
    background: none
    border-color: transparent
    box-shadow: none

  &--none
    appearance: none
    background: transparent
    border: none
    padding: 0
    text-align: left

  &--tab-side
    @media (min-width: $md)
      position: absolute
      top: 105px
      right: 45px

  &--box
    @extend .u-box
    justify-content: left
    border-radius: 4px
    padding: 16px
    color: var(--color-primary)
    font-size: 1.4rem
    gap: 8px

    svg
      width: 20px
      height: 20px

  &.disabled, &:disabled, fieldset:disabled &
    pointer-events: none
    opacity: .24

  &__icon
    width: 16px
    flex-shrink: 0

    &--md
      width: 20px

.btn-group
  display: inline-flex
  max-width: 100%
  overflow: auto

  .btn
    height: 32px
    line-height: 2rem!important

  &--with-dropdown
    width: 100%
    overflow: visible

  input
    display: none !important

    &:checked + .btn--outline
      color: white
      background: var(--color-primary)
      border-color: var(--color-primary)

  label
    display: inline-block !important

  > .btn
    border-radius: 0
    flex-grow: 1

    &:not(:first-of-type)
      margin-left: -1px

  > .btn:first-child, > :first-child + .btn
    border-top-left-radius: 100px
    border-bottom-left-radius: 100px

  > .btn:last-child
    border-top-right-radius: 100px
    border-bottom-right-radius: 100px

  .btn.c-dropdown
    flex-grow: 0
    border-radius: 0 100px 100px 0
    margin-left: -1px
    position: relative

    .c-dropdown__toggle::before
      content: ''
      position: absolute
      inset: 0

    &.btn--primary
      border-left-color: #fff

    .c-dropdown__toggle svg
      stroke: currentColor
