.open-register
		min-height: 100vh

		&__hero
			+g(full, center, middle)
			+i(1/1)
			background: linear-gradient(180deg, #F5FBFC 0%, #ECECF9 100%)
			min-height: 90vh
			gap: 10vw
			padding: 1em 1em 2em
			+bp(md)
				+g(full, center, middle, rev)

		&__hero-logo
			max-width: 200px
			margin: 0 auto
			padding-top: 2em
			+bp(md)
				padding-top: 0em
				max-width: inherit


		&__hero-body, &__hero-image
			+i(1/1, sm 8/28, md 4/18)
			text-align: center
			+bp(md)
				text-align: left

		&__hero-image
			+i(8/10, sm 14/28, md 6/18)
			padding-top: 2em
			img
				max-height: 500px
				width: auto
				padding-top: 0em

		&__hero-description
			margin: 3em 0
			+i(1/1)

		&__hero-footer
			border-top: 1px solid var(--color-neutral)
			padding-top: 2em
			text-align: center

			&__warning
				margin-top: .5em
				font-weight: 800
				color: var(--color-danger)



		&__about
			+g(full, center)
			padding: 8em 2em
			background-image: url('../../images/about-bg.svg')
			background-size: cover
			background-position: center


		&__about-title
			+i(1/1)
			text-align: center
			font-size: 3em

		&__list
			+i(20/20, md 16/20)
			margin: 5em 0
			+g(full, center)
			gap: 2vw

		&__card
			+i(10/10, md 4/18)
			background: white
			border: 1px solid #EEEBF9
			box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
			border-radius: 10px
			padding: 3em
			color: #5D6A86
			min-height: 200px

		&__card-title
			font-weight: 800
			color: var(--color-primary-dark)
			margin-bottom: 1em

		&__cta
			+i(1/1)
			text-align: center

		&__footer
			background: var(--color-secondary)
			+g(full, space-between, middle)

		&__footer-image
			display: none
			+bp(md)
				display: block
			+i(6/12)
			height: 350px
			background-image: url('../../images/about-footer-bg.png')
			background-position: center right
			background-size: 100% auto
			background-repeat: no-repeat

		&__footer-body
			+i(1/1, md 6/12)
			padding: 2em
			font-size: 1.3em
			color: white
			line-height: 1.3em
			+bp(md)
				padding: 0 10vw

			&__image
				max-width: 250px
				margin-bottom: 2em
				border-bottom: 1px solid white
				padding-bottom: 2em
