.finder
  display: flex
  flex-direction: column
  flex-shrink: 0
  gap: 24px
  padding: 20px
  border-radius: 20px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: #FFF
  box-shadow: $dropshadow
  max-width: 100%
  @media (min-width: $xl)
    flex-direction: row
    height: 180px
    overflow: hidden
    flex-grow: 1

  &__tree
    display: flex
    flex-direction: column
    gap: 20px
    @media (min-width: $xl)
      flex-shrink: 0
      width: 280px
      overflow: hidden
      &:hover, &:focus
        overflow-y: auto
    @media (min-width: $xxl)
      width: 320px

  &__folder
    display: flex
    align-items: center
    gap: 16px
    padding: 16px 20px
    border-radius: 12px
    border: 1px solid var(--color-neutral-lightest, #ECE9F8)
    background: #FFF
    cursor: pointer
    transition: all .15s

    &:hover
      box-shadow: $dropshadow

    &--is-open
      background-color: var(--color-bright)

    &-icon, .card__icon-wrapper, &-label
      flex-shrink: 0
      margin-bottom: 0

    &-icon
      color: var(--color-primary)
      width: 20px

    &-label
      @extend .u-text-truncate
      width: 0 
      flex-grow: 1
      color: var(--color-primary-dark, #110B3E)
      font-size: 1.4rem

  &__subfolder, &__tree-item
    display: flex
    flex-direction: column
    gap: 10px

  &__subfolder
    margin-left: 32px

  &__content
    border-top: 1px solid var(--color-neutral-lightest, #ECE9F8)
    padding: 24px 0
    @media (min-width: $xl)
      border-top: 0
      border-left: 1px solid var(--color-neutral-lightest, #ECE9F8)
      padding: 0 24px
      flex-grow: 1
      display: flex
      flex-direction: column
      overflow-y: auto

      .empty-state
        margin: auto

  &__favorite-icon
    width: 16px
    cursor: pointer

    &--active
      fill: var(--color-danger)
      stroke: var(--color-danger)