.icon
  width: 16px
  aspect-ratio: 1/1
  flex-shrink: 0

  &--verified
    cursor: help

    & + #verified
      background-color: #13AFF0
