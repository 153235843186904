.report-card
  display: flex
  flex-direction: column
  padding: 28px
  gap: 16px
  border-radius: 10px
  border-left: 10px solid var(--color-primary-light, #7B63BF)
  background: #FFF
  box-shadow: $dropshadow
  cursor: pointer
  background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_36934_13232)'%3E%3Cpath d='M34.5 9L20.25 23.25L12.75 15.75L1.5 27' stroke='%23F1F5FA' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M25.5 9H34.5V18' stroke='%23F1F5FA' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_36934_13232'%3E%3Crect width='36' height='36' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")
  background-repeat: no-repeat
  background-position: right 8px bottom 4px
  background-size: 36px
  transition: .16s

  &-wrapper
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 300px), 1fr))
    gap: 20px

  &:hover
    box-shadow: $dropshadow-md

  &--monitor
    background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.667 4.75H6.33366C4.58476 4.75 3.16699 6.16776 3.16699 7.91667V23.75C3.16699 25.4989 4.58476 26.9167 6.33366 26.9167H31.667C33.4159 26.9167 34.8337 25.4989 34.8337 23.75V7.91667C34.8337 6.16776 33.4159 4.75 31.667 4.75Z' stroke='%23F1F5FA' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.667 33.25H25.3337' stroke='%23F1F5FA' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19 26.918V33.2513' stroke='%23F1F5FA' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")

  &--user
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.9996 4.32422C11.3429 4.32422 4.3252 11.3419 4.3252 19.9986C4.3252 23.5838 5.52886 26.8879 7.55413 29.5287C7.84522 28.1576 8.52748 26.8879 9.53376 25.8816C10.9032 24.5122 12.7606 23.7428 14.6973 23.7428H25.3019C27.2386 23.7428 29.096 24.5122 30.4655 25.8816C31.4717 26.8879 32.154 28.1576 32.4451 29.5287C34.4704 26.8879 35.674 23.5838 35.674 19.9986C35.674 11.3419 28.6564 4.32422 19.9996 4.32422ZM19.9996 35.6731C16.8218 35.6731 13.8649 34.7274 11.395 33.1022V31.0451C11.395 30.1693 11.7429 29.3294 12.3622 28.7101C12.9815 28.0907 13.8215 27.7428 14.6973 27.7428H25.3019C26.1778 27.7428 27.0177 28.0907 27.637 28.7101C28.2563 29.3294 28.6043 30.1693 28.6043 31.0451V33.1022C26.1343 34.7274 23.1774 35.6731 19.9996 35.6731ZM0.325195 19.9986C0.325195 9.13276 9.13373 0.324219 19.9996 0.324219C30.8655 0.324219 39.674 9.13276 39.674 19.9986C39.674 30.8645 30.8655 39.6731 19.9996 39.6731C9.13373 39.6731 0.325195 30.8645 0.325195 19.9986ZM16.6973 15.1382C16.6973 13.3143 18.1758 11.8358 19.9996 11.8358C21.8234 11.8358 23.3019 13.3143 23.3019 15.1382C23.3019 16.962 21.8234 18.4405 19.9996 18.4405C18.1758 18.4405 16.6973 16.962 16.6973 15.1382ZM19.9996 7.83585C15.9667 7.83585 12.6973 11.1052 12.6973 15.1382C12.6973 19.1711 15.9667 22.4405 19.9996 22.4405C24.0326 22.4405 27.3019 19.1711 27.3019 15.1382C27.3019 11.1052 24.0326 7.83585 19.9996 7.83585Z' fill='%23F1F5FA'/%3E%3C/svg%3E%0A")

  &--filter
    background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M34.8337 4.75H3.16699L15.8337 19.7283V30.0833L22.167 33.25V19.7283L34.8337 4.75Z' stroke='%23F1F5FA' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")

  &--globe
    background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.0003 34.8346C27.7448 34.8346 34.8337 27.7458 34.8337 19.0013C34.8337 10.2568 27.7448 3.16797 19.0003 3.16797C10.2558 3.16797 3.16699 10.2568 3.16699 19.0013C3.16699 27.7458 10.2558 34.8346 19.0003 34.8346Z' stroke='%23F1F5FA' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.16699 19H34.8337' stroke='%23F1F5FA' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19.0003 3.16797C22.9607 7.50369 25.2114 13.1304 25.3337 19.0013C25.2114 24.8722 22.9607 30.4989 19.0003 34.8346C15.04 30.4989 12.7893 24.8722 12.667 19.0013C12.7893 13.1304 15.04 7.50369 19.0003 3.16797Z' stroke='%23F1F5FA' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")

  &--video
    background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_36932_11797)'%3E%3Cpath d='M36.4163 11.082L25.333 18.9987L36.4163 26.9154V11.082Z' stroke='%23F1F5FA' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M22.1663 7.91797H4.74967C3.00077 7.91797 1.58301 9.33573 1.58301 11.0846V26.918C1.58301 28.6669 3.00077 30.0846 4.74967 30.0846H22.1663C23.9152 30.0846 25.333 28.6669 25.333 26.918V11.0846C25.333 9.33573 23.9152 7.91797 22.1663 7.91797Z' stroke='%23F1F5FA' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_36932_11797'%3E%3Crect width='38' height='38' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")

  &--pencil
    background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 31.668H33.25' stroke='%23F1F5FA' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M26.125 5.54235C26.7549 4.91246 27.6092 4.55859 28.5 4.55859C28.9411 4.55859 29.3778 4.64547 29.7853 4.81426C30.1928 4.98306 30.5631 5.23046 30.875 5.54235C31.1869 5.85424 31.4343 6.22451 31.6031 6.63201C31.7719 7.03951 31.8588 7.47627 31.8588 7.91735C31.8588 8.35843 31.7719 8.79519 31.6031 9.20269C31.4343 9.6102 31.1869 9.98046 30.875 10.2924L11.0833 30.084L4.75 31.6674L6.33333 25.334L26.125 5.54235Z' stroke='%23F1F5FA' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")

  &--mouse-pointer
    background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.75 4.75L15.9442 31.6192L19.9183 19.9183L31.6192 15.9442L4.75 4.75Z' stroke='%23F1F5FA' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.583 20.582L30.083 30.082' stroke='%23F1F5FA' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")

  &__title, &__subtitle
    margin-bottom: 0

  &__title
    color: var(--color-primary, #442B8C)
    font-size: 1.8rem
    font-weight: 700

  &__subtitle
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.4rem
    font-weight: 300
