.alert
  font-size: 1.4rem
  line-height: 1.2
  color: var(--color-neutral-dark)
  background-color: #F1F5FA
  padding: 10px 20px
  border-radius: 10px
  display: flex
  align-items: flex-start
  gap: 10px

  &--bgless
    padding: 0
    gap: 4px
    background-color: transparent
    color: var(--color-neutral)

  &--success
    color: var(--color-success)
    background-color: var(--color-success-lightest)

  &--danger
    color: var(--color-danger)
    background-color: var(--color-danger-lightest)

  &__icon
    flex-shrink: 0
    width: 16px
    color: var(--color-primary)
