.matter-performance
  @extend .fadeIn
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
  padding: 32px
  border-radius: 10px
  background-color: #fff

  &__header
    border-bottom: 1px solid #ECE9F8
    padding-bottom: 16px
    margin-bottom: 16px

    & > :last-child
      margin-bottom: 0

  &__title
    color: #110B3E
    font-size: 2rem
    font-weight: 700
    margin-bottom: 0

  &__subtitle
    font-size: 1.2rem

  &__container
    margin-top: 36px
    display: flex
    flex-direction: column
    @media (min-width: $xl)
      flex-direction: row

  &__col
    display: flex
    flex-direction: column
    gap: 8px
    @media (min-width: $xl)
      width: calc( 100% / 3 + 16px )

    &:not(:first-of-type)
      padding-top: 32px
      border-top: 1px solid var(--color-bright)
      margin-top: 32px
      @media (min-width: $xl)
        padding-left: 32px
        padding-top: 0
        border-top: 0
        margin-top: 0

    &:first-of-type
      @media (min-width: $xl)
        width: calc( 100% / 3 - 32px )

    &:last-of-type
      .chart-performance
        cursor: default

        &:hover
          background-color: transparent
