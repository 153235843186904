.multi-select
  background-color: var(--color-bright)
  min-height: 40px
  border-radius: 4px
  display: flex
  align-items: center
  font-size: 13px
  padding: 4px 8px

  &__value-container
    flex-basis: 0
    flex-grow: 1
    display: flex
    flex-wrap: wrap
    gap: 2px 0

  &__indicator
    appearance: none
    padding: 8px
    background-color: transparent
    color: var(--color-primary)

    svg
      width: 20px
      height: 20px

  &__placeholder
    color: hsl(0, 0%, 50%)
