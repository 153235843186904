.page-detail
  width: 100%
  background: #FFF
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
  border-radius: 10px
  padding: 20px

  &__header
    margin-bottom: 20px
    position: relative
    min-height: 164px

  &__inner
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    gap: 32px

  &__container
    width: 112px
    flex-grow: 1

  &__bg-wrapper
    position: absolute
    width: 100%
    z-index: 0
    inset: 0

    &::before
      content: ""
      position: absolute
      inset: 0
      background-color: rgba(0, 0, 0, .3)
      z-index: 1
      border-radius: 12px

  &__bg
    width: 100%
    height: 100%
    border-radius: 12px
    object-fit: cover

  &__banner
    min-height: 164px
    padding: 20px
    position: relative

    .card__meta
      color: #fff
      &::after
        background-color: #fff

  &__body
    margin-inline: 32px
    position: relative

  &__icon-wrapper
    position: absolute
    left: 0
    right: 0
    top: -88px
    z-index: 2

  &__icon
    background: var(--color-primary-lightest)
    border: 1px solid #FFFFFF
    border-radius: 4px
    width: 32px
    height: 32px
    color: var(--color-primary)
    display: flex
    align-items: center
    justify-content: center
    position: relative
    margin: 20px 20px 0 0
    svg
      width: 16px
      stroke-width: 1

  &__title
    font-weight: 700
    font-size: 2.8rem
    line-height: 1.2
    color: #fff
    margin-bottom: 0

  &__subtitle
    font-weight: 300
    font-size: 1.4rem
    line-height: 1.5
    color: var(--color-neutral)
    margin-bottom: 4px

  &__description
    color: #fff
    font-size: 1.5rem
    margin-bottom: 8px

  &__description-title
    font-weight: 700
    font-size: 1.4rem
    line-height: 1.5
    color: var(--color-primary-dark)

  &__footer
    border-top: 1px solid var(--color-neutral-lightest)
    margin-top: 24px
    padding-top: 24px
