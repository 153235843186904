.progress
  height: 16px
  border-radius: 4px
  background-color: var(--color-bright)
  overflow: hidden
  position: relative

  &__bar
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    background-color: var(--color-secondary)
    z-index: 9

  &__text
    height: 100%
    line-height: 16px
    text-align: left
    font-size: 1.2rem
    font-weight: 700
    color: var(--color-neutral-dark)
    padding-left: 10px
    position: absolute
    z-index: 10

.semi-circle-progress
  position: relative
  text-align: center
  width: 136px
  height: 68px
  overflow: hidden

  &__bar
    position: absolute
    top: 0
    left: 0
    width: 136px
    height: 136px
    border-radius: 50%
    box-sizing: border-box
    border: 24px solid var(--color-primary-lightest)
    border-bottom-color: var(--color-primary)
    border-right-color: var(--color-primary)
    transform: rotate(45deg) // start: 45, end: 225
    transition: transform 1s ease-in

  &__value
    position: absolute
    bottom: 0
    left: 0
    right: 0
    font-size: 2rem
    font-weight: 700
    line-height: 1
    color: var(--color-primary)
