.new-login
  +i(1/1)
  +g(full)
  background: linear-gradient(90deg, #FFFFFF -30%, var(--color-primary-lightest) 80%)fixed

  &__container, &__image
    +i(1/1, md 1/2)
    min-height: 100vh
    +g(full, middle, center)
    position: relative

  &__image
    display: none
    +bp(md)
      display: block

  &__body
    +g(full)
    gap: 1em
    max-width: 360px
    padding: 1.5em

  &__body-logo
    +i(5/10, md 6/10)

  &__body-header
    +g(full)
    gap: 2em
    +i(1/1)

  &__body-form
    +i(1/1)

  &__body-title
    +i(1/1)
    font-weight: 700
    font-size: 0.95em
    border-top: 1px solid var(--color-neutral-lightest)
    padding-top: 1em
    color: var(--color-neutral-dark)

  &__login-image
    background-color: white
    box-shadow: 0 0 90px rgba(black, .1)
    position: absolute
    top: 2em
    bottom: 2em
    left: 2em
    right: 2em
    border-radius: 20px
    background-size: cover
    background-position: center

.rember-link
  color: var(--color-primary)!important
  text-decoration: underline

.small-description
  font-weight: 300
  font-size: 0.8em
  margin: 0
  color: var(--color-neutral)
  margin-top: 5px

.container-fields
  display: flex
  gap: 16px
  align-items: flex-start

.text
  font-weight: 700
  color: var(--color-neutral-dark)
  font-size: 0.95em
  align-self: flex-end
  height: 40px

.error-message
  color: red
  height: 20px

.container-checkbox
  display: flex
  justify-content: space-between
  gap: 30px
  padding-top: 20px
  padding-bottom: 30px

.container-grid-student
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: 16px
