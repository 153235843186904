.panorama-card
  flex-grow: 1
  min-height: 476px
  width: 220px
  max-width: 100%
  display: flex
  flex-direction: column
  padding: 20px
  gap: 12px
  border-radius: 12px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: #fff
  box-shadow: 0px 4px 10px 0px rgba(119, 119, 120, 0.15)

  &__title
    margin-bottom: 0
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.8rem
    font-weight: 700

  &__body
    flex-grow: 1
    display: flex
    flex-direction: column

    &--empty-state
      border-radius: 12px
      border: 1px solid var(--color-neutral-lightest, #ECE9F8)
      background: var(--color-neutral-bright, #FAFAFA)

    .empty-state
      margin: auto
