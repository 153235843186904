.list-alternatives
  list-style: upper-alpha
  display: flex
  flex-direction: column
  gap: 20px
  padding-left: 20px
  color: var(--color-neutral-dark)

  &__container
    display: flex
    align-items: flex-start
    gap: 10px

  .form__check-input
    flex-shrink: 0
    margin-top: 12px

  .ck-editor, > .form__control
    width: 100px !important
    flex-grow: 1

  &__icon
    flex-shrink: 0
    width: 16px
    color: var(--color-danger)
    cursor: pointer
