.send-tips {    
    appearance: none;
    border: 0;
    background-color: #fff;
    border: 1px solid #ECE9F8;
    cursor: pointer;
    color: #000;
    line-height: 1;
    padding: 16px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: fixed;
    top: 40%;
    right: 0;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 1;
    box-shadow: 0px 4px 10px 3px #5D6A8633;

    &-icon {
        padding: 4px;
        border-radius: 50%;
        color: #fff;
        background-color: #FDB462;
        width: 30px;
        height: 30px;
    }
}

.badge-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap; 
    gap: 24px
}