.terms
	min-height: 100vh
	+g(center, middle)
	padding: 5em 0

	&_background
		background-image: url('../../images/login-bg.svg')
		background-size: cover

	h1
		text-align: center
		margin-bottom: 1.5em
	h2
		font-weight: 700
		margin-bottom: .3em
		margin-top: 2em
	p
		margin-bottom: 1.2em
		a
			color: var(--color-secondary)
	em
		font-style: italic

	ul
		padding-left: 1.5em

	&__screen
		+i(1/1)
		+g(full, center)

	&__brand
		+i(1/1)
		+g(full, center)
		margin: 2em 0
		img
			width: 280px
			max-height: 180px

	&__container
		+i(14/20)
		background: white
		border-radius: 20px
		box-shadow: $dropshadow
		padding: 4em
		position: relative
		overflow: hidden
