.profile-resume
  +g(full, middle)
  gap: .4em

  &__avatar
    transition: all ease-in-out .2s
    flex-shrink: 0
    width: 36px!important
    height: 36px!important
    object-fit: cover
    border-radius: 50px
    border: 1px solid var(--color-neutral-light)
    &:hover
      border: 1px solid var(--color-primary)

  &__body
    +g(full)
    flex-direction: column
    line-height: 10px!important

  &__name
    @extend .u-text-truncate
    width: 100%
    color: var(--color-primary-dark)
    text-transform: capitalize
    font-size: 13px!important
    font-weight: 700!important
    max-width: 100px

  &__tag
    background-color: var(--color-neutral-lightest)
    color: var(--color-neutral-dark)
    border-radius: 30px
    padding: .1em .8em
    font-size: 10px!important
    border: 1px solid var(--color-neutral-lighter)
    font-weight: 300!important

  &__email
    font-weight: 300
    font-size: 1.2rem
    padding-top: 5px
    color: var(--color-neutral)
