.open-ranking
  +i(1/1)

  &__page-header
    +i(1/1)
    margin-bottom: 2em
    border-bottom: 1px solid var(--color-neutral-lightest)
    padding-bottom: 1em
    +g(full, space-between, middle)
    font-weight: 700
    .btn
      padding: .4em 1.5em
      margin: 0 3px
    &__title
      font-size: 1.2em

  &__result-header
    +i(1/1)
    padding-bottom: 1em

  &__item
    background: white
    +g(full, space-between, middle)
    +i(1/1)
    color: var(--color-primary-dark)
    background: #FFFFFF
    box-shadow: 0px 2px 5px rgba(68, 43, 140, 0.05)
    border-radius: 10px
    padding: .5em 1em
    margin-bottom: 1em
    border: 1px solid white

    &.active
      border: 1px solid var(--color-primary)
      background: var(--color-bright)

  &__body
    +g(full, middle)
    +i(6/10)

  &__place, &__percentual
    +i(2/10)
    text-align: right
    font-size: .8em
    font-weight: 700

  &__description
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    max-width: 100px
    font-size: .85em
    b
      font-weight: 700

  &__avatar
    width: 30px
    height: 30px
    border: 1px solid var(--color-neutral)
    border-radius: 50%
    background-color: var(--color-neutral-lighter)
    margin-right: 1em
    background-position: center !important
    background-repeat: no-repeat !important
    background-size: cover !important

  &__header
    +g(full, space-between, middle)
    +i(1/1)
    background: var(--color-bright)
    border-radius: 10px
    padding: 1em
    margin-bottom: 1em

  &__header-content
    +i(1/1)
    +g(full, space-between, middle)
    padding-bottom: 1em
    border-bottom: 1px solid var(--color-primary-lightest)

  &__results-item
    font-size: .8em
    margin: 1em 0
    gap: 1em
    border-top: 1px solid var(--color-bright)
    padding-top: 1em
    +g(full, space-between, middle)
    color: var(--color-neutral-darker)
    +i(1/1)
    &--header
      border-top: none
      .open-ranking__results-item__progress
        background: none
      .open-ranking__results-item__percentual
        font-weight: 700

    &__name
      +i(2.5/10)
    &__progress
      +i(4/10)
      height: 3px
      background: var(--color-bright)
      position: relative

    &__progress-bar
      position: absolute
      width: 1%
      height: 3px
      background: var(--color-secondary)

  &__cta
    margin-top: 1em
    border-top: 2px solid var(--color-primary-lightest)
    padding-top: 1em
    display: grid
    grid-gap: 1em
    grid-template-columns: repeat( 2, minmax(0, 1fr))

  &__metrics
    +g(full, space-between, middle)
    margin-bottom: 1em

  &__metrics-item
    +i(4.9/10)
    +g(full, space-between, middle)
    border: 1px solid var(--color-neutral-lighter)
    border-radius: 5px
    overflow: hidden
    margin-bottom: .5em
    &:nth-child(2n)
      .open-ranking__metrics-item__name
        +i(3/10)
      .open-ranking__metrics-item__value
        +i(7/10)

    &__name, &__value
      +i(5/10)
      padding: 10px
      font-size: 13px

    &__name
      color: #000
    &__value
      color: #FFF
      background: var(--color-neutral)
      font-weight: 700
      text-transform: capitalize

  &__circles
    +g(full, space-between, middle)
    margin-top: 2em
  &__circle
    +g(full, space-between, middle)
    +i(2/10)
    > div
      margin: 0 auto
    &__label
      text-align: center
      width: 100%
      padding-top: 10px
      font-size: .8em
      color: var(--color-neutral)

    &--final
      +i(3/10)
      border-left: 1px solid var(--color-primary-lightest)
