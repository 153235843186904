.progress-lvl-tag
  cursor: help
  display: inline-flex
  align-items: center
  gap: 8px
  padding: 4px 4px 4px 10px
  border-radius: 100px
  background: var(--color-neutral-light)
  max-width: 100%

  &--danger
    background: var(--color-danger)

  &--third
    background: var(--color-third)

  &--warning
    background: var(--color-warning)

  &--success-light
    background: var(--color-success-light)

  &--success
    background: var(--color-success)

  &__text
    @extend .u-text-truncate
    color: #fff
    font-size: 1.2rem
    font-weight: 700
    margin-bottom: 0
    flex-shrink: 1

  &__img-wrapper
    flex-shrink: 0
    width: 28px
    height: 28px
    border-radius: 100px
    background-color: #fff
    display: inline-flex
    align-items: center
    justify-content: center

  &__img
    height: 20px
    width: auto
