@keyframes In
  0%
    translate: 100%

  100%
    translate: 0

.nps-modal
  position: fixed
  inset: 0
  z-index: 9999

  &__content
    width: 400px
    max-width: 80vw
    display: flex
    padding: 20px
    flex-direction: column
    gap: 20px
    border-radius: 10px 0px 0px 10px
    background: #FFF
    box-shadow: $dropshadow-md
    position: absolute
    right: 0
    top: 44px
    transition: translate .4s

    &--open
      animation: In .4s ease-out 0s
      animation-iteration-count: 1
    &--close
      translate: 100%

  &__title
    margin-bottom: 0
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.4rem
    font-weight: 700

  &__footer
    display: flex
    align-items: center
    justify-content: space-between

  &__btn
    background: none
    padding: 2px
    svg
      display: block

  &__steps
    color: var(--color-neutral, #8894AC)
    font-size: 1.2rem
    font-weight: 300
