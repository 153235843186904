.flip-card
  background-color: transparent
  width: 212px
  height: 212px
  perspective: 1000px
  cursor: pointer

  &-wrapper
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap
    gap: 20px

  &__inner
    position: relative
    width: 100%
    height: 100%
    text-align: center
    transition: transform 0.6s
    transform-style: preserve-3d

  &--flipped &__inner
    transform: rotateY(180deg)

  &__front,
  &__back
    position: absolute
    width: 100%
    height: 100%
    backface-visibility: hidden
    border-radius: 12px
    display: flex
    align-items: center
    justify-content: center
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
    padding: 16px
    p
      margin-bottom: 0

  &__front
    background-color: #fff
    color: #333
    border: 1px solid var(--color-primary-light, #7B63BF)
    border-left-width: 8px
    &>p
      color: #000
      text-align: center
      font-size: 1.8rem
      font-weight: 700

  &__back
    background-color: var(--color-primary-light, #7B63BF)
    color: white
    transform: rotateY(180deg)
    border: 2px solid var(--color-primary-light, #7B63BF)
    text-align: left
    overflow-y: auto
    padding: 8px
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    padding: 8px

  &__icon
    width: 16px
    color: var(--color-primary)
    position: absolute
    bottom: 12px
    right: 16px
    pointer-events: none

    .flip-card__back &
      color: #fff
