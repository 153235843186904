/// @group base
/// @name type
*
	box-sizing: border-box

html
	font-size: 62.5% // 10px
	scroll-behavior: smooth

body, input, select
	font-family: $title
	color: var(--color-primary-dark)
	font-size: 15px
	font-weight: 300
	line-height: 1.5

strong, b, dt
	font-weight: 800

p
	margin-top: 0
	margin-bottom: 1.6rem

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6
	font-family: $title
	margin-top: 0
	margin-bottom: .8rem
	color: var(--color-primary)
	font-weight: 800
	line-height: 1.2

h1, .h1
	font-size: 1.6em

button
	cursor: pointer
	font-size: 1em
	font-family: $text

img, svg
	height: auto
	/* Make sure images are scaled correctly. */
	max-width: 100%
	/* Adhere to container width. */
	vertical-align: middle
