.admin-lesson-card
  display: flex
  align-items: center
  gap: 16px
  padding: 16px

  &:not(:first-of-type)
    border-top: 1px solid var(--color-neutral-lightest)

  &__body
    width: 60px
    flex-grow: 1

  &__title
    @extend .u-text-truncate
    margin-bottom: 0
    font-weight: 700
    font-size: 1.4rem
    color: var(--color-primary-dark)

  &__inner-body
    display: flex
    align-items: center
    gap: 32px

  .card__count
    min-height: 24px

  &__footer
    flex-shrink: 0