.nav-section
  display: flex
  flex-direction: column
  gap: 12px

  &__item
    padding: 16px 12px
    border-radius: 10px
    border: 1px solid var(--color-neutral-lightest, #ECE9F8)
    background: #fff
    background-clip: padding-box
    box-shadow: 0px 4px 10px 0px rgba(93, 106, 134, 0.05)
    display: flex
    align-items: center
    gap: 8px
    cursor: default

    &--is-active
      background: var(--color-bright)

    &--success
      background: var(--color-success)

  &__icon
    flex-shrink: 0
    display: inline-flex
    align-items: center
    justify-content: center
    width: 24px
    height: 24px
    border-radius: 50px
    background: var(--color-bright, #F1F5FA)
    color: var(--color-primary)
    svg, img
      width: 16px

    .nav-section__item--is-active &
      background: #fff

    .nav-section__item--success &
      color: var(--color-success)

  &__title
    @extend .u-text-truncate
    flex-grow: 1
    margin-bottom: 0
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.2rem
    font-weight: 700

    .nav-section__item--success &
      color: #fff

  &__check
    flex-shrink: 0
    appearance: none
    margin: 0
    width: 16px
    height: 16px
    border: 1px solid var(--color-neutral-light)
    border-radius: 50px
    background-color: #fff
    background-clip: padding-box
    transition: all ease-in-out .2s
    background-position: center
    outline: none

    &:checked
      border-color: var(--color-success)
      background-color: var(--color-success)
      background-repeat: no-repeat
      background-position: center
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>")
      background-size: 12px auto
      outline: none

      .nav-section__item--success &
        background-color: #fff
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='lightseagreen' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>")
        border-color: #fff
