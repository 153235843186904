.login {
  width: 100%;
  height: 100vh;
  display: flex;
  background: var(--color-neutral-bright);
  overflow-y: auto;
}

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  @media (min-width: 784px) {
    width: 50%;
  }
}

.main,
.main form,
.header,
.body,
.btnGroup,
.footer,
.textGroup {
  display: flex;
  flex-direction: column;
}

.main,
.main form {
  gap: 32px;
  width: 400px;
  max-width: 100%;
  padding: 24px;
}

.main form {
  padding: 0;
}

.header {
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  border-bottom: 1px solid var(--color-neutral-lightest);
  padding-bottom: 32px;
}

.body {
  gap: 32px;
}

.logo {
  width: 188px;
}

.title,
.description {
  margin-bottom: 0;
}

.title {
  font-weight: 300;
  font-size: 1.8rem;
  color: var(--color-primary-dark);

  strong {
    font-weight: 700;
  }
}

.figure {
  display: none;
  width: 50%;
  padding: 32px;
  @media (min-width: 784px) {
    display: block;
  }
}

.image {
  background-color: white;
  box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btnGroup {
  gap: 16px;
}

.footer {
  border-top: 1px solid var(--color-neutral-lightest);
  padding-top: 32px;
  gap: 4px;
}

.back {
  color: var(--color-primary-dark, #110b3e);
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 12px;
}

.description {
  color: var(--color-neutral-darker, #5d6a86);
  font-size: 1.6rem;
  font-weight: 300;

  &.box {
    padding: 10px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(93, 106, 134, 0.05);
    color: var(--color-neutral-darker, #5d6a86);
    font-size: 1.4rem;
    font-weight: 300;
  }
}

.textGroup {
  gap: 12px;
}
