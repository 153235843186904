.kpi-widget
  flex-grow: 1
  width: 160px
  max-width: 100%
  background: #fff
  border-radius: 10px
  box-shadow: $dropshadow
  padding: 16px
  display: flex
  flex-direction: column
  min-height: 134px
  justify-content: space-between
  word-break: break-word

  &--bright
    background-color: var(--color-bright)
    .kpi-widget__count-in-body
      background-color: #fff

  &--fast-access
    cursor: pointer
    display: flex
    justify-content: space-between
    flex-direction: row
    min-height: 100px
    width: 100%

  &-wrapper
    display: flex
    flex-wrap: wrap
    gap: 12px
    margin-bottom: 40px

  &-dashboard
    margin-bottom: 36px

    &__title
      color: var(--color-neutral-dark, #5D6A86)
      font-size: 1.4rem
      font-weight: 300
      margin-bottom: 0

  &__hat
    font-size: 1.2rem
    font-weight: 300
    margin-bottom: 0
    color: var(--color-neutral-dark)

  &__header
    margin-bottom: 12px

  &__title
    color: var(--color-primary-dark)
    font-size: 1.6rem
    font-weight: 700
    margin-bottom: 0

  &__body
    flex-grow: 1
    border: 1px solid var(--color-neutral-lightest)
    border-radius: 10px
    background-color: #fff
    padding: 16px
    display: flex
    flex-direction: column
    gap: 12px

    > :last-child
      margin-bottom: 0

    &--dashboard
      border: none
      padding: 0
      margin-bottom: 12px
      gap: 4px

  &__count
    color: var(--color-primary)
    font-weight: 700
    font-size: 2.4rem
    margin-bottom: 0

  &__action-title
    flex-grow: 1
    color: var(--color-primary-dark)
    font-weight: 700
    font-size: 1.6rem
    margin-bottom: 12px
    margin-bottom: 0

  &__link
    color: var(--color-primary)
    font-weight: 300
    font-size: 1.4rem
    text-decoration: underline

  &__count-in-body
    background-color: var(--color-bright)
    border-radius: 4px
    padding: 8px
    color: var(--color-neutral-dark)
    font-size: 1.4rem
    font-weight: 700
    span
      font-weight: 300
      color: var(--color-neutral)

  &__text
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.5rem
