.navbar
  +g(full, center, middle)
  position: fixed
  background: var(--color-primary-dark)
  +i(1/1)
  padding: 1.5em
  z-index: 10

  &__link
    color: white
    margin: 0 2.5vw
    font-size: .9em
    font-weight: 300
    &:hover
      text-decoration: underline

    &--highlight
      background: var(--color-secondary)
      padding: .5em 1em
      border-radius: 5px
