.demo
  --color-primary-dark: #110B3E
  --color-primary: #442B8C
  --color-primary-light: #7763AE
  --color-primary-lighter: #C0B8D8
  --color-primary-lightest: #ECE9F8

  --color-secondary-dark: #46B0C3
  --color-secondary: #5DC9DD
  --color-secondary-light: #74DBE1
  --color-secondary-lighter: #A7DDE0
  --color-secondary-lightest: #DEF6F8
