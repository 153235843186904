.user-dashboard-task-list
  +i(1/1)
  margin: 2em 0em

  &__title
    padding: 5px 10px
    font-size: 13px
    font-weight: 300
    color: var(--color-primary-dark)
    border: 1px solid #EEEBF9
    border-radius: 5px
    display: inline-block

  &__container
    background: white
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
    padding: 1em
    margin-top: .5em
    border-radius: 10px
    gap: 1vw
    display: grid
    grid-template-columns: repeat( 1, minmax(0, 1fr))
    +bp(md)
      grid-template-columns: repeat( 2, minmax(0, 1fr))

  &__item
    border: 1px solid var(--color-primary-lightest)
    padding: 1em
    border: 1px solid #EEEBF9
    border-radius: 3px
    font-size: 1.1rem
    background: linear-gradient(253.87deg, #FFFFFF 5.22%, rgba(255, 255, 255, 0) 99.41%), var(--color-bright)
    border-right: 4px solid var(--color-primary)
    +g(full, space-between, middle)

    &:last-child
      margin-bottom: 0em
  &__empty
    padding: 2vw 0
    +i(1/1)
  &__empty-title
    margin-bottom: 1em

  &__item-info
    +i(1/1)
    display: block

  &__item-cta
    display: block

  &__item-title
    font-size: 13px
    font-weight: 700

  &__btn
    padding: 5px 10px
    display: inline
    font-size: 13px
    background: white
    border-radius: 50px
    border: 1px solid #EEEBF9
    transition: all ease-in-out .2s
    &:hover
      background: var(--color-primary)
      color: white
      cursor: pointer
