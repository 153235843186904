.duel-notification {
  background-color: var(--color-primary-light);
  border-radius: 8px;
  color: #fff;
  padding: 30px !important;

  svg {
    display: block;
    justify-self: flex-end;
    cursor: pointer;
  }

  &__content {
    display: flex;
    gap: 24px;

    .avatar {
      border: 2px solid #fff;
    }

    &-name {
      font-size: 1.4rem;
      font-weight: 700;
    }

    &-buttons {
      display: flex;
      align-items: center;
      gap: 42px;
      margin: 24px 0;
      font-weight: 700;

      button {
        font-weight: 700;
      }

      span {
        cursor: pointer;
      }
    }
  }
  &__bar {
    background-color: #ece9f8;
    height: 3px;
    transition: width 0.1s linear;
    border-radius: 8px;
  }
}
