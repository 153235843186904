.report-form
  display: flex
  flex-direction: column

  &__control, &__datepicker
    height: 40px
    width: 200px
    max-width: 100%
    appearance: none
    border-radius: 100px
    border: 1px solid var(--color-neutral-lightest)
    padding: 10px 12px 10px 12px
    font-size: 1.3rem
    font-weight: 300
    background-color: var(--color-bright)
    color: var(--color-neutral)
    transition: .16s

    // &[type="date"]::-webkit-inner-spin-button, &[type="date"]::-webkit-calendar-picker-indicator
    //   display: none

    &--md
      width: 400px

    &::placeholder
      color: var(--color-neutral-dark)

    &[type=search]
      padding-right: 40px
      background-color: var(--color-bright)
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.25 14.25C11.5637 14.25 14.25 11.5637 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25Z' stroke='%238894AC' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.7498 15.7498L12.4873 12.4873' stroke='%238894AC' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      background-repeat: no-repeat
      background-position: right 12px center
      background-size: 16px

    &:hover
      background-color: #fff

    &:disabled
      color: var(--color-neutral)
      pointer-events: none
      opacity: .5

  &__select
    height: 40px
    width: 200px
    max-width: 100%
    appearance: none
    border-radius: 100px
    border: 1px solid var(--color-neutral-lightest)
    padding: 10px 28px 10px 12px
    font-size: 1.3rem
    font-weight: 300
    background-color: var(--color-bright)
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 1.25L5 5.75L9.5 1.25' stroke='%238894AC' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    background-repeat: no-repeat
    background-position: right 12px center
    background-size: 10px
    color: var(--color-neutral)
    position: relative
    transition: .16s

    &:hover
      background-color: #fff

    &:disabled
      color: var(--color-neutral)
      pointer-events: none
      opacity: .5

    .react-multi-select__value-container
      padding-left: 0
      padding-right: 0
    .react-multi-select__placeholder
      @extend .u-text-truncate
    .react-multi-select__indicators
      display: none
    .react-multi-select__control
      border: 0 !important
      background-color: transparent !important
      margin-top: -10px
      &::after
        position: absolute
        inset: 0 -28px 0 -12px
        z-index: 1
        pointer-events: auto
        content: ""

  &__datepicker
    width: auto
    max-width: 100%
    display: flex
    align-items: center
    gap: 32px
    position: relative

    &::after
      transform: translate(-1px, -8px)
      content: ""
      top: 50%
      left: 50%
      width: 1px
      height: 16px
      position: absolute
      border-left: solid 1px var(--color-neutral-lighter)
      z-index: 0

    .report-form__control
      border: 0
      border-radius: 0
      padding: 0
      background-color: transparent
      height: auto
      width: 108px
      &:first-of-type
