.success-modal
  display: flex
  align-items: flex-start
  justify-content: center
  position: fixed
  inset: 0
  background-color: rgba(0, 0, 0, .4)
  overflow-x: hidden
  overflow-y: auto
  outline: 0
  z-index: 1060
  opacity: 0
  transition: opacity .4s ease-in-out
  pointer-events: none
  padding: 16px

  &.active
    pointer-events: all
    opacity: 1

    .success-modal__content
      opacity: 1
      transform: translateY(0)

  &.enter-done, &.show
    opacity: 1
    pointer-events: visible

  &.exit
    opacity: 0

  &__content
    position: relative
    display: flex
    align-items: center
    flex-wrap: wrap
    gap: 32px
    margin-top: auto
    margin-bottom: auto
    width: 956px
    max-width: 86vw
    padding: max(32px, 3.4vw)
    box-sizing: border-box
    background-color: white
    border-radius: 50px
    box-shadow: $dropshadow
    transform: translateY(-100px)
    transition: all .2s ease-in-out .1s
    opacity: 0

    &--disclaimer
      width: 800px
      padding: 50px
      margin-top: auto
      margin-bottom: auto
      .success-modal__body
        display: flex
        flex-direction: column
        gap: 30px
      .success-modal__text:last-of-type
        padding: 30px
        border: solid 2px #442B8C
        border-radius: 8px

    .success-modal.enter-done &, .success-modal.show &
      transform: translateY(0)
      opacity: 1

    .success-modal.exit &
      transform: translateY(-100px)
      opacity: 0

  &__tag
    position: absolute
    left: 0
    top: 60px
    padding: 10px
    border-radius: 0px 12px 12px 0px
    color: #fff
    background-color: var(--color-success)
    font-size: 1.6rem
    font-weight: 700

  &__img, &__inner
    flex-basis: 0
    flex-grow: 1

  &__img
    min-width: 152px

  &__title
    color: var(--color-primary-dark)
    font-size: 4.0rem
    font-weight: 700
    line-height: 1.2
    margin-bottom: 0

  &__text
    margin-bottom: .5em
    font-size: 1.6rem
    font-weight: 300
    color: var(--color-neutral-dark)

  &__name
    color: var(--color-primary)
    font-size: 2.0rem
    font-weight: 700
    margin-bottom: 1em

  &__body
    margin-bottom: 32px

  &__kpis
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr))
    gap: 12px

  &__kpi
    padding: 10px 20px
    border-radius: 12px
    border: 1px solid var(--color-primary-lightest)
    display: flex
    align-items: center
    gap: 10px
    position: relative
    .medal
      position: absolute
      right: 0
      top: 0
      transform: translate(50%, -50%)

    &-icon
      flex-shrink: 0
      width: 24px
      color: var(--color-primary)

    &-inner
      flex-grow: 1

    &-value
      margin-bottom: 0
      color: var(--color-primary)
      font-size: 1.6rem
      font-weight: 700

    &-label
      color: #8894AC
      font-size: 1.4rem
      font-weight: 300
      line-height: 1
      margin-bottom: 0
