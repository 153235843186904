.challenge-success
  display: flex
  flex-direction: column
  gap: 24px
  flex-grow: 1

  &__header
    text-align: center
    display: inline-flex
    padding: 20px max(20px, 3vw)
    flex-direction: column
    justify-content: center
    align-items: center
    border-radius: 0px 0px 20px 20px
    border: 1px solid var(--color-neutral-lightest, #ECE9F8)
    background: #FFF
    box-shadow: $dropshadow-md

  .avatar
    margin-bottom: 12px

  &__title, &__subtitle
    margin-bottom: 0

  &__title
    color: var(--color-primary)
    font-size: 1.6rem
    font-weight: 700

  &__subtitle
    color: var(--color-neutral, #8894AC)
    font-size: 1.4rem
    font-weight: 300
    display: flex
    align-items: center
    gap: 4px

  &__icon
    flex-shrink: 0
    width: 16px

  &__body
    flex-grow: 1
    text-align: center
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 44px

  &__message
    display: flex
    flex-direction: column
    align-items: center
    gap: 4px
    > *
      margin-bottom: 0

    &-title
      color: var(--color-primary)
      text-align: center
      font-size: 2.4rem
      font-weight: 700
      line-height: 1.2

    &-subtitle
      color: var(--color-neutral, #777)
      text-align: center
      font-size: 1.4rem
      font-weight: 300

  &__kpis-list
    display: flex
    flex-wrap: wrap
    justify-content: center
    gap: 8px

  &__kpi
    display: flex
    padding: 4px
    flex-direction: column
    gap: 8px
    width: 112px
    max-width: 100%
    border-radius: 4px
    border: 1px solid var(--color-primary)
    background: #FFF
    > *
      margin-bottom: 0

    &-title
      text-align: center
      padding: 2px 8px
      display: flex
      align-items: center
      justify-content: center
      gap: 2px
      border-radius: 4px
      background: var(--color-primary)
      color: var(--color-bright)
      font-size: 1.2rem
      font-weight: 700

    &-value
      color: var(--color-neutral-dark, #4B4B4B)
      font-size: 1.4rem
      font-weight: 700
      display: flex
      justify-content: center
      align-items: center
      gap: 8px

    &-icon
      width: 20px
      height: 20px
      border-radius: 100%
      color: #fff
      display: flex
      align-items: center
      justify-content: center
      background-color: var(--color-neutral)
      svg
        width: 12px

  &__points
    position: relative

  &__total
    @extend .u-text-truncate
    color: #FFF
    text-align: center
    font-size: 3.2rem
    font-weight: 700
    line-height: 1.2
    position: absolute
    top: 24px
    right: 0
    left: 0
    margin-bottom: 0
    padding: 0 16px

  &__stars
    position: absolute
    right: -36px
    top: -24px
    path:nth-child(1)
      animation: starsIn .9s ease-out .6s infinite
    path:nth-child(2)
      animation: starsIn .8s ease-out .2s infinite
    path:nth-child(3)
      animation: starsIn .7s ease-out .4s infinite

    &--icon
      position: relative
      right: 0
      top: 0

@keyframes starsIn
  from
    opacity: 0
  to
    opacity: 1
