.class-progressbar
  +g(full, space-between, middle)
  position: relative
  font-size: .85em
  font-weight: 700
  color: var(--color-primary)

  &__line
    width: 60%
    background: var(--color-primary)
    height: 5px
    border-radius: 2px
    z-index: 1

  &::after
    content: ""
    position: absolute
    background: var(--color-primary-lightest)
    z-index: 0
    left: 0px
    right: 20%
    height: 5px
    border-radius: 2px
