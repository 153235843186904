.tab
  background: var(--color-primary-lightest)
  border-radius: 50px
  border: 1px solid var(--color-primary-lightest)
  padding: 6px
  box-sizing: border-box
  width: 100%
  max-width: 100%
  display: flex
  gap: .5vw
  margin-bottom: 2em
  cursor: pointer
  overflow-x: auto
  overflow-y: hidden
  -ms-overflow-style: none
  scrollbar-width: none
  overflow-y: scroll
  &::-webkit-scrollbar
    display: none
  @media (min-width: $md)
    width: max-content

  &__link
    appearance: none
    border: none
    outline: none
    font-size: .88em
    font-weight: 300
    font-weight: 700
    white-space: nowrap
    background: transparent
    padding: .3em 1.5em
    border-radius: 50px
    color: var(--color-neutral-darker)
    transition: all ease-in-out .2s
    line-height: 1.5
    background-color: rgba(white, .3)
    transition: all ease-in-out .2s

    &.active, &:hover
      background: white
      color: var(--color-primary)
      box-shadow: $dropshadow

  &__cards
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))
    gap: 20px

    &--h
      grid-template-columns: minmax(100%, 1fr)

    & > *
      width: 100%

  &__header
    display: flex
    overflow-x: auto
    margin-bottom: 8px

  &__container
    +g(full)
    +i(1/1)
    gap: 1em 0

    .widget-wrapper
      +i(1/1)

  &__pane
    .infinite-scroll-component
      overflow: initial !important
