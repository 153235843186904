.profile
  width: 100%
  position: relative
  .btn--outline
    position: absolute
    right: 12px
    top: 12px
    z-index: 10

  &__bg-wrapper
    position: relative

    &::before
      content: ""
      position: absolute
      inset: 0
      background-color: rgba(0, 0, 0, .5)
      z-index: 1
      border-radius: 12px

  &__bg
    width: 100%
    height: 160px
    border-radius: 12px
    object-fit: cover

  &__inner
    margin-top: -135px
    position: relative
    z-index: 2
    display: flex
    flex-direction: column
    align-items: center
    gap: 32px
    width: 100%
    padding: 2em
    @media (min-width: $md)
      flex-direction: row
      align-items: flex-start

    .medal-wrapper
      background: transparent
      padding: 0px

    .medal-wrapper__info strong
      color: white

  &__avatar
    width: 150px
    height: 150px
    object-fit: cover
    border: 4px solid #FDFCFA
    box-shadow: $dropshadow
    border-radius: 100px

  &__data
    text-align: center
    @media (min-width: $sm)
      text-align: left

  &__title
    color: var(--color-primary-dark)
    font-size: 2.5rem
    font-weight: 700
    margin: .5rem 0 0 0
    @media (min-width: $md)
      color: white

  &__info-wrapper
    display: flex
    flex-direction: column
    align-items: center
    @media (min-width: $sm)
      flex-direction: row
      align-items: flex-start
      gap: 1em

  &__info
    color: var(--color-primary-dark)
    font-size: 13px
    font-weight: 300
    display: flex
    align-items: center
    flex-wrap: wrap
    @media (min-width: $md)
      color: white

  &__icon
    width: 16px
    margin-right: .5em

  &__header
    flex-grow: 1
    display: flex
    align-items: center
    justify-content: center
    flex-wrap: wrap-reverse
    gap: 16px
    @media (min-width: $md)
      justify-content: space-between

    .medal-wrapper
      flex-shrink: 0

      &__info strong
        color: var(--color-primary-dark)
        @media (min-width: $md)
          color: white

  &__data
    flex-grow: 1
