.page-container {
  margin-left: 260px;
  padding: 48px;

  &--header {
    margin-bottom: 32px;
    text-align: end;
  }

  &--wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 36px;
  }
}

.box-container {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #fff;
  border-radius: 1rem;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 400px;
  height: 700px;
  overflow: auto;
  max-width: 50%;

  &--header {
    padding: 18px;
    display: flex;
    justify-content: space-between;

    &-title {
      font-weight: 700;
    }
  }

  &--form {
    height: 100%;
    padding: 24px;
    border: 3px dashed var(--color-neutral-light);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--content {
    text-align: center;

    label {
      display: flex;
      flex-direction: column;
      gap: 8px;
      cursor: pointer;
      font-weight: 700;

      p {
        font-weight: normal;
      }
    }
  }

  &--text-area {
    background-color: #f1f5fa;
    border: 1px solid var(--color-neutral-lightest, #ece9f8);
    border-radius: 10px;
    // padding: 12px;
    flex-grow: 1;
    display: flex;
    overflow-y: auto;
    :focus {
      outline: none;
      border: none;
    }

    textarea {
      background-color: transparent;
      font-family: Nunito;
      border: none;
    }

    textarea,
    p {
      padding: 12px;
      line-height: 21px;
      font-size: 14px;
      color: #5d6a86;
      overflow-y: scroll;
    }
  }
}

.text-balloon {
  background-color: #fff;
  border-radius: 10px;
  padding: 12px;
  margin-top: 32px;
  position: relative;
  margin-top: 84px;

  &-title {
    font-weight: 700;
    margin-bottom: 12px;
    display: block;
  }

  ::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
    top: -20px;
    left: 5%;
  }
}

.modal {
  display: flex;
  align-items: center;

  img {
    height: 80px;
    width: 80px;
    margin-right: 40px;
  }

  &-title {
    font-weight: bold;
    color: var(--color-primary);
  }
}
