.box
    background-color: #fff
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
    border-radius: 10px

.widget-wrapper
    display: flex
    flex-wrap: wrap
    gap: 20px
    margin-bottom: 20px

    & > *
        flex-basis: 0
        flex-grow: 1

    &--chart
        display: grid
        justify-content: center
        justify-items: center
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))

        & > *
            width: 100%

    &--col
        display: flex
        flex-wrap: wrap
        & > *
            flex-basis: 0
            flex-grow: 1
            min-width: 300px

    &--sm-space
        gap: 12px
        margin-bottom: 30px

    &__col
        display: flex
        flex-direction: column
        gap: 20px

        & > *
            // height: 100%

.essay-widget
    @extend .box
    padding: 24px !important
    width: 100%

    &--full
        +i(1/1)

    &--center
        text-align: center
        display: flex
        flex-direction: column
        align-items: center

        .round-dg__user-inner
            width: auto
            flex-grow: 0

    &__header
        margin-bottom: 16px

    &__title
        color: var(--color-primary-dark)
        font-size: 1.6rem
        font-weight: 700
        line-height: 1.5
        margin-bottom: 0

    &__title-with-btn
        display: flex
        align-items: center
        gap: 12px
        .widget__title
            flex-grow: 1
        .modal__close-btn
            flex-shrink: 0

    &__subtitle, &__text
        color: var(--color-neutral)
        font-weight: 300
        font-size: 1.4rem

    &__subtitle
        margin-bottom: 0

    &__count
        font-size: 1.6rem
        font-weight: 700
        color: var(--color-primary)
        span
            font-size: 1.4rem
            font-weight: 300
            color: var(--color-neutral)

    &__description
        color: var(--color-primary-dark)
        font-size: 1.2rem
        font-weight: 300
        line-height: 1.5
        margin-bottom: 0

    &__filter
        margin-top: 12px
        display: flex
        align-items: center
        flex-wrap: wrap
        gap: 12px

    &__body
        &--individual
            display: flex
            align-items: center
            gap: 40px

            & > *:first-child
                flex-grow: 1

            & > *:last-child
                flex-shrink: 0
                width: 30%

            .form__label
                color: #6A6686
                font-size: 1.2rem
                font-weight: 300
                margin-bottom: 16px

        & > :last-child
            margin-bottom: 0

    &__help-icon
        flex-shrink: 0
        cursor: help
        color: var(--color-neutral)
        width: 20px
        transition: all .15s

        &:hover
            color: var(--color-primary)

    &__date
        display: inline-block
        color: var(--color-neutral-dark, #5D6A86)
        font-size: 1.2rem
        font-weight: 300
        padding: 4px 8px
        border-radius: 4px
        background: var(--color-bright, #F1F5FA)

.widget
    @extend .box
    padding: 3em !important
    width: 100%

    &--full
        +i(1/1)

    &--center
        text-align: center
        display: flex
        flex-direction: column
        align-items: center

        .round-dg__user-inner
            width: auto
            flex-grow: 0

    &__header
        margin-bottom: 16px
        p
            margin-bottom: 0

    &__title
        color: var(--color-primary-dark)
        font-size: 1.6rem
        font-weight: 700
        line-height: 1.5
        margin-bottom: 0

    &__title-with-btn
        display: flex
        align-items: center
        gap: 12px
        .widget__title
            flex-grow: 1
        .modal__close-btn
            flex-shrink: 0

    &__subtitle, &__text
        color: var(--color-neutral)
        font-weight: 300
        font-size: 1.4rem

    &__subtitle
        margin-bottom: 0

    &__count
        font-size: 1.6rem
        font-weight: 700
        color: var(--color-primary)
        span
            font-size: 1.4rem
            font-weight: 300
            color: var(--color-neutral)

    &__description
        color: var(--color-primary-dark)
        font-size: 1.2rem
        font-weight: 300
        line-height: 1.5
        margin-bottom: 0

    &__filter
        margin-top: 12px
        display: flex
        align-items: center
        flex-wrap: wrap
        gap: 12px

    &__body
        &--individual
            display: flex
            align-items: center
            gap: 40px

            & > *:first-child
                flex-grow: 1

            & > *:last-child
                flex-shrink: 0
                width: 30%

            .form__label
                color: #6A6686
                font-size: 1.2rem
                font-weight: 300
                margin-bottom: 16px

        & > :last-child
            margin-bottom: 0

    &__help-icon
        flex-shrink: 0
        cursor: help
        color: var(--color-neutral)
        width: 20px
        transition: all .15s

        &:hover
            color: var(--color-primary)

    &__date
        display: inline-block
        color: var(--color-neutral-dark, #5D6A86)
        font-size: 1.2rem
        font-weight: 300
        padding: 4px 8px
        border-radius: 4px
        background: var(--color-bright, #F1F5FA)

.info-widget
    @extend .box
    padding: 12px
    text-align: center
    min-width: 144px
    border: 1px solid transparent

    &-wrapper
        display: flex
        flex-wrap: wrap
        gap: 20px
        & > *
            flex-basis: 0
            flex-grow: 1

    &--light
        background: linear-gradient(9.59deg, #FFFFFF 20.02%, #F5F5F5 85.13%)
        .info-widget__value
            color: var(--color-primary)

    &--monochrome
        background-color: var(--color-primary-lightest)
        .info-widget__title
            color: var(--color-primary)
        .info-widget__value
            color: var(--color-primary)

    &--primary
        border-color: var(--color-primary)
        .info-widget__title
            color: var(--color-primary)

    &--secondary
        border-color: var(--color-secondary)
        .info-widget__title
            color: var(--color-secondary)

    &--success
        border-color: #23CA88
        .info-widget__title
            color: #23CA88

    &--danger
        border-color: var(--color-danger)
        .info-widget__title
            color: var(--color-danger)

    &--warning
        border-color: var(--color-third)
        .info-widget__title
            color: var(--color-third)

    &--info
        border-color: #32C9D3
        .info-widget__title
            color: #32C9D3

    &--t2
        padding: 0
        text-align: left
        min-width: auto
        border: 0
        box-shadow: none
        position: relative
        .info-widget__body
            justify-content: flex-start

    &--t3
        border-radius: 4px
        border: 1px solid var(--color-bright)
        padding: 8px 20px
        box-shadow: none
        text-align: left
        .info-widget__body
            justify-content: left
        .info-widget__value
            color: var(--color-primary-dark)
            svg
                vertical-align: sub
                width: 20px
                stroke: var(--color-primary)

    &--large-text
        display: flex
        flex-direction: column
        gap: 16px

        .info-widget__header
            flex-basis: 0
            flex-grow: 1
            margin-bottom: 0
        .info-widget__title
            color: #000
            font-size: 1.6rem
            font-weight: 700
            text-align: left
        .info-widget__body
            flex-basis: 0
            flex-grow: 1
        .info-widget__value
            color: var(--color-neutral-darker)
            font-size: 2.8rem

    &--clickable
        cursor: pointer

    &__header
        margin-bottom: 4px

    &__title
        color: #8894AC
        font-size: 1.2rem
        font-weight: 300
        line-height: 1.5
        margin-bottom: 0

    &__body
        display: flex
        justify-content: center
        gap: 28px

    &__value
        color: var(--color-primary-dark)
        font-weight: 700
        font-size: 1.6rem
        margin-bottom: 0
        white-space: nowrap

        svg
            vertical-align: initial

.chart-widget
    @extend .info-widget

    &__header
        @extend .info-widget__header

    &__title
        @extend .info-widget__title
        text-align: left

        &--md
            color: var(--color-primary-dark)
            font-weight: 300
            font-size: 16px

        &--lg
            color: var(--color-primary-dark)
            font-weight: 700
            font-size: 20px

    &__container
        text-align: left
        display: flex
        flex-wrap: wrap
        gap: 20px

    &__group
        flex-basis: 0
        flex-grow: 1
        display: flex
        flex-direction: column
        justify-content: space-between
        gap: 12px

    .widget-wrapper
        margin-bottom: 0

    &-wrapper
        display: flex
        flex-wrap: wrap
        gap: 16px

        & > *
            flex-basis: 0
            flex-grow: 1
            min-width: 200px

        & > :first-child
            max-width: 360px

.hit-widget
    &__header
        text-align: right
        margin-bottom: 16px

    &__title
        color: var(--color-neutral)
        font-weight: 300
        font-size: 1.2rem
        margin-bottom: 0

    &__body
        color: #5D6A86
        font-weight: 300
        font-size: 1.2rem
        line-height: 1.2
        margin-bottom: 0
        display: grid
        grid-template-columns: minmax(auto, 36%) 1fr auto
        align-items: center
        gap: 20px 12px

    &__label, &__bars, &__numbers
        cursor: pointer

    &__bars
        background-color: var(--color-primary-light)
        height: 16px

    &__percentage-bar
        background-color: var(--color-primary)
        border-right: 1px solid white
        height: 100%

.top-widget
    &__header
        display: flex
        gap: 12px
        margin-bottom: 32px

    &__title
        flex-basis: 0
        flex-grow: 1
        color: var(--color-neutral)
        font-weight: 300
        font-size: 1.2rem
        margin-bottom: 0

    &__body
        color: var(--color-neutral-darker)
        font-size: 1.4rem

    &__row
        display: flex
        align-items: center
        gap: 12px

        &:not(:last-child)
            margin-bottom: 12px

    &__cell
        flex-basis: 0
        flex-grow: 1

        &--center
            display: flex
            justify-content: center

.individual-widget
    display: flex
    align-items: center
    gap: 40px

    & > *:first-child
        flex-grow: 1

    & > *:last-child
        flex-shrink: 0
        width: 30%

    .form__label
        color: #6A6686
        font-size: 1.2rem
        font-weight: 300
        margin-bottom: 16px

.accessed-materials-widget
    font-size: 1.2rem
    font-weight: 700

    &-wrapper
        max-height: 400px
        overflow-x: hidden
        overflow-y: auto

    &__row
        display: flex
        align-items: center
        gap: 12px
        flex-wrap: wrap
        margin-bottom: 0
        margin-right: 12px
        border-bottom: 1px solid var(--color-bright)
        padding: 8px 0

    &__icon
        width: 32px
        height: 32px
        background-color: var(--color-primary-lightest)
        line-height: 1
        border-radius: 4px
        color: var(--color-primary)
        flex-shrink: 0
        display: flex
        align-items: center
        justify-content: center

    &__avatar
        width: 32px
        height: 32px
        border-radius: 50px

    &__title
        width: 0
        flex-grow: 1
        color: var(--color-primary-dark)
        display: inline-flex
        align-items: center
        gap: 8px
        flex-wrap: wrap

        &--auto
            width: auto

    &__counter
        font-size: 1.12rem
        font-weight: 300
        color: var(--color-neutral)
        display: inline-flex
        align-items: center
        gap: 2px
        line-height: 1em
        svg
            flex-shrink: 0

    &__counter-group

    &__btn
        appearance: none
        padding: 0
        border: none
        outline: none
        background-color: transparent
        cursor: pointer
        transition: .3s

        svg
            width: 16px
            min-width: 16px
            color: var(--color-neutral-light)

        &:hover
            filter: brightness(.8)

    &__access
        &--up
            color: #6FCF97

        &--down
            color: #FD6B62
            svg
                transform: rotate(180deg)

        svg
            vertical-align: middle

    &__datetime
        font-size: 1.2rem
        font-weight: 300
        color: var(--color-neutral)

.accordion-widget
    font-size: 1.2rem
    overflow-x: hidden
    overflow-y: auto
    max-height: 276px
    padding-right: 12px

    &__item:not(:first-of-type)
        border-top: 1px solid var(--color-bright)

    &__header
        margin-bottom: 0
        padding: 12px 0
        display: flex
        align-items: center
        gap: 16px
        cursor: pointer

        .accordion-widget__chevron
            transition: transform .3s

        &.active .accordion-widget__chevron
            transform: rotate(180deg)

    &__title
        @extend .u-text-truncate
        flex-basis: 0
        flex-grow: 1
        color: var(--color-primary-dark)

    &__counter
        @extend .accessed-materials-widget__counter

    .badge
        flex-shrink: 0
        font-size: 1rem
        line-height: 1.6rem

    &__btn
        @extend .accessed-materials-widget__btn

    &__chevron
        flex-shrink: 0
        appearance: none
        padding: 0
        border: none
        outline: none
        background-color: transparent
        cursor: pointer
        svg
            width: 16px
            color: var(--color-neutral-light)

    &__content
        padding: 8px 0

    .project-timeline__item
        color: #8894AC
        cursor: default !important
        &::before
            display: none !important
        &:first-of-type
            padding-left: 6px
            &::after
                display: none !important

    &__collapse
        height: 0
        transition: height 0.3s
        overflow: hidden

.update-widget
    margin-bottom: 0
    color: var(--color-neutral)
    font-size: 1.2rem
    font-weight: 300
    text-align: right

.dg-widget
    width: 100%
    table-layout: fixed
    font-size: 1.2rem
    text-align: left

    &__body
        display: block
        overflow: auto
        max-height: 74vh
        &--funnel
            max-height: 425px
    & &__header, & &__body &__row
        display: table
        width: 100%
        table-layout: fixed

    &__row
        border-bottom: 1px solid var(--color-bright)

    &__cell-header
        vertical-align: middle
        padding: 8px 8px 8px 0
        font-weight: 700
        color: #000

        &[scope=col]
            font-weight: 300

    &__cell
        vertical-align: middle
        padding: 8px

        &--right
            text-align: right

        &--danger
            color: var(--color-danger)

    &__btn
        @extend .accessed-materials-widget__btn

    &__counter
        @extend .accessed-materials-widget__counter

    &__avatar
        @extend .accessed-materials-widget__avatar
        margin-right: 8px

    .star-rating
        width: 80px

    .star-rating__btn
        padding: 0

    &__text
        &--increment
            color: var(--color-success)

        &--decrement
            color: var(--color-danger)
