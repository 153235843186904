.questions-menu
  padding: 20px
  display: flex
  flex-direction: column
  gap: 32px
  background-color: #FDFCFA
  border: 1px solid #FFFFFF
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
  border-radius: 10px

  &__title
    font-weight: 700
    font-size: 1.4rem
    color: var(--color-neutral-dark)
    margin-bottom: 4px

  &__list
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(36px, 1fr))
    gap: 8px

  &__link
    border: 0
    padding: 8px
    font-weight: 700
    font-size: 1.2rem
    text-decoration: underline
    color: var(--color-neutral-dark)
    display: flex
    align-items: center
    justify-content: center
    height: 36px
    background-color: #fff
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
    border-radius: 4px
    transition: .15s

    &:hover
      box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.1)

    &.active
      background-color: var(--color-secondary)
      color: #fff

    &.disabled
      opacity: .4