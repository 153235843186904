/// @group settings
/// @name fonts

// -------------------------------------
//   Font Sizes
// -------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;700;800&family=Nunito:wght@300;700;800&display=swap')

// -------------------------------------
//   Font Families
// -------------------------------------
$title: 'Nunito', sans-serif
$text: 'Nunito Sans', sans-serif
