
.phanton
  opacity: 0

.fadeIn
  animation: fadeMoveIn .4s ease-out 0s
  animation-iteration-count: 1

.fadeInDown
  animation: fadeMoveInDown .2s ease-out 0s
  animation-iteration-count: 1

.fadeInLeft
  animation: fadeInLeft .1s ease-out 0s 1 normal backwards

.fadeInRight
  animation: fadeInRight .3s ease-out 0s 1 normal backwards

.spin
  animation: spinning 2s
  animation-iteration-count: infinite
  transform-origin: center

@keyframes fadeMoveIn
  0%
    transform: translateY(-3%)
    opacity: 0

  100%
    opacity: 1!important
    transform: translateY(0%)

@keyframes spinning
  0%
    transform: rotate(0)

  100%
    transform: rotate(360deg)

@keyframes fadeMoveInDown
  0%
    transform: translateY(-5%)
    opacity: 0

  100%
    opacity: 1
    transform: translateY(0%)

@keyframes switchMoveOn
  0%
    background-position: left center
    background-size: 12px 12px

  100%
    background-position: right center
    background-size: 12px 12px

@keyframes switchMoveOff
  0%
    background-position: right center
    background-size: 12px 12px

  100%
    background-position: left center
    background-size: 12px 12px

@keyframes fadeInLeft
  from
    opacity: 0
    transform: translate3d(-100%, 0, 0)

  to
    opacity: 1
    transform: translate3d(0, 0, 0)

@keyframes fadeInRight
  from
    opacity: 0
    transform: translateX(100%)

  to
    opacity: 1
    transform: translateX(0)
