.matter-btn
  appearance: none
  cursor: pointer
  display: flex
  align-items: center
  gap: 4px
  padding: 20px 16px
  border-radius: 12px
  border: 2px solid var(--color-neutral-lightest, #ECE9F8)
  background: linear-gradient(10deg, #FFF 20.02%, #F5F5F5 85.13%)
  box-shadow: $dropshadow
  text-align: left
  transition: .16s

  &:hover, &:focus
    background: var(--color-bright, #F1F5FA)
    box-shadow: $dropshadow-md

  &-wrapper
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 380px), 1fr))
    gap: 16px

  &__header
    flex-shrink: 0
    display: flex
    gap: 4px

  &__icon
    flex-shrink: 0
    width: 20px
    color: var(--color-primary)

  &__title
    flex-grow: 1
    color: var(--color-primary)
    font-size: 1.4rem
    font-weight: 700

  &__text
    width: 100px
    flex-grow: 1
    color: var(--color-neutral, #5D6A86)
    font-size: 1.2rem
    font-weight: 300
    text-align: right

  .c-dropdown
    flex-shrink: 0
    align-self: center

.question-btn
  display: flex
  padding: 20px 16px
  align-items: center
  gap: 16px
  border-radius: 10px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: #FFF
  box-shadow: 0px 4px 10px 0px rgba(93, 106, 134, 0.05)
  cursor: pointer

  &:hover, &:focus
    border-radius: 10px
    border: 1px solid var(--color-neutral-lightest, #ECE9F8)
    background: var(--color-neutral-bright, #F1F5FA)
    box-shadow: 0px 4px 10px 3px rgba(93, 106, 134, 0.20)

  &-wrapper
    height: 540px
    display: flex
    flex-direction: column
    gap: 20px

    .btn
      align-self: flex-start

    &--empty
      .btn
        margin: auto

    &__list
      height: 20px
      flex-grow: 1
      overflow-y: auto
      display: flex
      flex-direction: column
      gap: 10px
      padding: 0 12px 12px
      margin: 0 -12px

  &__form
    padding: 2px 20px
    border-radius: 10px
    background: var(--color-neutral-bright, #F1F5FA)

  &__title, &__text
    margin-bottom: 0

  &__title, .c-dropdown
    flex-shrink: 0

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.4rem
    font-weight: 700
    align-self: flex-start

  &__text
    flex-grow: 1
    color: var(--color-neutral-darker, #5D6A86)
    font-size: 1.2rem
    font-weight: 300
