.inkluziva
  --color-primary-dark: #414141
  --color-primary: #3EA4F5
  --color-primary-light: #68A6D8
  --color-primary-lighter: #92BED8
  --color-primary-lightest: #D7E2EB

  --color-secondary: #B2C144
  --color-secondary-light: #CAD863
  --color-secondary-lighter: #E5EEA1

  --color-third: #EFA91C
  --color-third-light: #F3BB4B

  --color-bright: #E9F1F8
