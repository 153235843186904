.course-about
  padding: 20px
  background: #fff
  box-shadow: 0px 4px 10px 3px rgba(68, 43, 140, 0.05)
  border-radius: 10px
  display: flex
  flex-direction: column
  gap: 32px

  .show-course-banner
    min-height: 224px

  &__header
    border-bottom: 1px solid var(--color-neutral-lightest)
    padding-bottom: 20px

    &--borderless
      border-bottom: none
      padding-bottom: 0

  .deadline
    background-color: #F1F5FA
    padding: 8px
    display: flex
    align-items: center
    justify-content: center
    gap: 4px
    border-radius: 4px
    line-height: 1.2
    svg
      flex-shrink: 0

  &__container-header
    display: flex
    align-items: flex-start
    flex-wrap: wrap
    gap: 20%
    margin-bottom: 20px

  &__inner-header
    width: 112px
    flex-grow: 1
    display: flex
    flex-direction: column
    gap: 20px

    > :last-child
      margin-bottom: 0

  &__infos
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 40px

    .course-card__tag
      align-self: auto

  &__rating, &__rating-text
    margin-bottom: 0
    font-weight: 300
    font-size: 1.4rem
    color: var(--color-neutral)
    strong
      font-size: 1.6rem
      font-weight: 700
      color: #5D6A86

  .star-rating
    width: 120px
    &__btn
      padding: 0

  &__description
    color: #5D6A86
    font-weight: 300
    font-size: 1.4rem
    margin-bottom: 1.6rem
    word-wrap: break-word

  &__participants
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    gap: 24px

  &__body
    display: flex
    flex-direction: column
    gap: 32px

  &__filter
    display: flex
    flex-direction: column
    gap: 16px

    > :last-child
      margin-bottom: 0

  &__filter-title, &__title
    font-weight: 700
    font-size: 1.6rem
    line-height: 1.5
    color: var(--color-primary-dark)
    margin-bottom: 0

  &__subtitle
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.6rem
    font-weight: 700
    margin-bottom: 0

  &__actions
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 20px

    .course-about__title
      flex-grow: 1

  &__module
    padding: 16px
    border-radius: 12px
    border: 1px solid var(--color-neutral-lightest, #ECE9F8)
    background: var(--color-neutral-bright, #FAFAFA)
