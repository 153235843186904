.first-time
  @extend .u-box
  +g(full)
  position: relative
  overflow: hidden
  min-height: 300px
  margin-bottom: 2em
  border: 1px solid var(--color-neutral-lightest)
  .empty-state
    box-shadow: none

  &__body
    +g(full)
    padding: 3vw
    width: 70%
    gap: 2em 0

  &__title
    font-size: 1.2em
    font-weight: 700

  &__image
    right: 0px
    position: absolute
    width: 30%
    height: 100%
    background-size: cover
