/// @group settings
/// @name grid

$hagrid-breakpoints: (
  sm: '(min-width: 35.5em)',
  //568px
  sm-only: '(max-width: 48.9999em)',
  //782.4px
  md: '(min-width: 49em)',
  //784px
  md-only: '(max-width: 63.9999em)',
  //1022.4px
  lg: '(min-width: 64em)',
  //1024px
  xl: '(min-width: 80em)',
  //1280px
  xxl: '(min-width: 88em)' //1366px,
);

// breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

.grid {
  display: grid !important;
  grid-template-columns: repeat(12, 1fr);
  gap: 88px;

  .col-1 {
    grid-column: span 1;
  }

  .col-2 {
    grid-column: span 2;
  }

  .col-3 {
    grid-column: span 3;
  }

  .col-4 {
    grid-column: span 4;
  }

  .col-5 {
    grid-column: span 5;
  }

  .col-6 {
    grid-column: span 6;
  }

  .col-7 {
    grid-column: span 7;
  }

  .col-8 {
    grid-column: span 8;
  }

  .col-9 {
    grid-column: span 9;
  }

  .col-10 {
    grid-column: span 10;
  }

  .col-11 {
    grid-column: span 11;
  }

  .col-12 {
    grid-column: span 12;
  }
}
