
::-webkit-scrollbar
  width: 8px
  background: var(--color-bright)

::-webkit-scrollbar-track
  background: var(--color-bright)

::-webkit-scrollbar-thumb
  transition: all ease-in-out .2s
  background: var(--color-neutral-light)
  border-radius: 12px !important

  &:hover
    background: var(--color-primary-light)
