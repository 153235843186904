.react-multi-select
    font-size: 13px

    &__control
        border-color: var(--color-bright) !important
        background-color: var(--color-bright) !important

        &--is-focused
            border-color: var(--color-primary) !important

    &__indicators
        svg
            fill: var(--color-primary)

    &__value-container

    .css-1g6gooi
        margin-top: -5px!important
        padding: 0px!important
        height: 33px!important
        line-height: 1em!important

    &__multi-value
        padding-left: .3em
        border-radius: 50px !important
        font-size: 13px !important
        background-color: var(--color-primary-lighter) !important
        color: white !important
        color: white

        &__label
            color: white !important

        &__remove
            flex-shrink: 0
            padding-right: .5em !important
            cursor: pointer
            &:hover
                border-radius: 0 50px 50px 0 !important
                background-color: transparent !important
                color: rgba(255, 255, 255, .5) !important

    &__option
        color: var(--color-primary-dark, #5D6A86) !important
