.filter-multi-select
  position: relative

  &__toggle
    @extend .filter-bar__select
    text-align: left

  &__content
    position: absolute
    z-index: 1
    top: calc( 100% + 4px )
    right: 0
    left: 0
    padding: 10px 0
    background-color: #fff
    border: 1px solid var(--color-neutral)
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.1)
    border-radius: 4px

    &.active
      opacity: 1
      visibility: visible
      transform: translateY(0)
      transition: .15s ease

    &.inactive
      opacity: 0
      visibility: hidden
      transform: translateY(-12px)
      transition: .15s ease

    .form__check
      margin-bottom: 0
      padding: 10px 10px 10px calc( 1.5em + 10px )
      transition: .15s

      &:hover, &:focus, &.checked
        background-color: var(--color-bright)

  &__badges
    height: 21px
    display: flex
    align-items: center
    gap: 4px
    overflow: hidden
    position: relative

    .badge
      flex-shrink: 0
      max-width: 80%

    // &::before
    //   content: ''
    //   position: absolute
    //   top: 2px
    //   bottom: 3px
    //   right: 0
    //   background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)
    //   width: 20px
    //   transition: .15s
