.notifications
  flex-shrink: 0
  display: inline-flex
  align-items: center
  gap: 4px
  position: relative

  &__toggle
    color: var(--color-primary)
    appearance: none
    border: none
    padding: 0
    background: none
    line-height: 0
    position: relative

    svg
      transition: transform ease-in-out .2s
      &:hover
        transform: scale(1.2)

    svg
      width: 16px

  &__badge
    display: block
    position: absolute
    top: 0
    right: 0
    transform: translate(50%, -50%)
    background-color: #FF4C6F
    color: #fff
    font-size: 1.2rem
    min-width: 16px
    height: 16px
    line-height: 16px
    border-radius: 50px
    padding: 0 4px

  &__content
    position: absolute
    right: -20px
    top: 120%
    z-index: 1
    width: 680px
    max-width: 80vw
    height: calc( 100vh - 200px )
    border: 1px solid var(--color-primary-lightest)
    background: white
    box-shadow: 0px 40px 40px 0px rgba(#110B3E, .2)
    border-radius: 10px
    animation: fadeIn
    animation-duration: .3s
    display: flex
    flex-direction: column
    overflow: hidden

    @media (min-width: 784px)
      height: calc( 100vh - 200px )

  &__header
    display: flex
    align-items: center
    justify-content: space-between
    gap: 20px
    margin: 0 2vw
    padding: 36px 0 8px 0
    border-bottom: 1px solid var(--color-primary-lightest)

  &__title
    margin-bottom: 0
    font-size: 1.6rem
    font-weight: 700
    line-height: 1.5
    color: var(--color-primary)

  &__read-btn
    background-color: transparent
    border: none
    outline: none
    cursor: pointer
    padding: 0
    color: var(--color-neutral-darker)
    font-size: 1.2rem
    font-weight: 300

  &__body
    height: 0
    flex-grow: 1
    overflow-x: hidden
    overflow-y: auto
    &::-webkit-scrollbar
      display: none
    &--dashboard
      height: auto
      border-radius: 10px
      box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
      border: 1px solid var(--brand-neutral-Neutral-lightest, #ECE9F8)
      overflow-x: hidden
      overflow-y: hidden

  &__label
    color: var(--color-primary)
    font-size: 1.35rem
    font-weight: 300
    display: none
    @media (min-width: $sm)
      display: inline-block

.notification
  padding: 2.5vw
  display: flex
  align-items: flex-start
  gap: 20px
  cursor: pointer
  background-color: #fff
  transition: all .3s
  +bp(md)
    padding: 1.2vw 2vw

  &--center
    flex-wrap: wrap
    align-items: center
    gap: 0 16px
    @media (min-width: $lg)
      flex-wrap: nowrap

    .notification__footer
      align-self: flex-start
      width: 100%
      text-align: right
      @media (min-width: $lg)
        width: auto
        text-align: left

  &.read
    background-color: var(--color-bright)
    border-bottom: 1px solid #fff !important

    .unread + &
      box-shadow: inset 0px 4px 10px -2px rgba(68, 43, 140, 0.1)

  &:not(:last-of-type)
    border-bottom: 1px solid var(--color-primary-lightest)

  &__header
    flex-shrink: 0

  &__avatar-stack
    width: 60px
    height: 60px
    position: relative
    .avatar
      position: absolute
    .avatar:first-child
      top: 0
      left: 0
    .avatar:last-child
      bottom: 0
      right: 0

  &__body
    width: 0
    flex-grow: 1

  &__footer
    flex-shrink: 0

  &__icon
    color: var(--color-primary)
    background-color: var(--color-primary-lightest)
    width: 40px
    height: 40px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50px
    position: relative

    &--sm
      width: 32px
      height: 32px

    &--warning
      color: var(--color-warning)
      background-color: var(--color-warning-lightest)

  &__title
    color: var(--color-primary-dark)
    font-weight: 700
    font-size: 1.2rem
    margin-bottom: 0

  &__more
    color: var(--color-neutral-lighter)

  &__text, &__datetime
    color: var(--color-neutral)
    font-weight: 300
    font-size: 1.2rem
    margin-bottom: 0
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis

    a
      color: var(--color-primary)
      font-weight: 700
      text-decoration: underline
