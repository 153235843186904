.module-card-admin-wrapper
  display: flex
  flex-direction: column
  gap: 32px

.module-card-admin
  border-radius: 8px
  background-color: var(--color-bright)
  padding: 20px
  display: flex
  gap: 40px

  .card
    width: 264px
    max-width: 100%
    margin: 0 auto

  &__disciplines
    width: 200px
    flex-grow: 1
    overflow-y: auto
    display: flex
    flex-direction: column

    .accordion
      height: 200px
      flex-grow: 1
      overflow-y: auto
