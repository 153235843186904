.pagination
    display: flex
    list-style: none

    &-wrapper
        display: flex
        align-items: center
        justify-content: center
        margin-bottom: 24px

    &__item
        &:first-child
            .pagination__link
                border-radius: 4px 0 0 4px
        &:last-child
            .pagination__link
                border-radius: 0 4px 4px 0
        &:not(:first-child)
            margin-left: -1px
        &.active
            .pagination__link
                color: #fff
                background-color: var(--color-primary)

    &__link
        color: var(--color-primary)
        padding: 6px 12px
        position: relative
        display: block
        text-decoration: none
        background-color: #fff
        border: 1px solid #dee2e6
        transition: all .15s
        &:hover
            background-color: darken(#fff, 5%)
