.duel-notification {
  background-color: var(--color-primary-light);
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  z-index: 100;
  position: fixed;
  right: 0;
  top: 100px;
  color: #fff;
  padding: 30px !important;
  pointer-events: none;
  transform: translateX(100%);
  transition: opacity 0.5s ease, transform 0.5s ease;

  &--visible {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(0);
  }

  svg {
    display: block;
    justify-self: flex-end;
    cursor: pointer;
  }

  &__content {
    display: flex;
    gap: 12px;

    .avatar {
      border: 2px solid #fff;
    }

    &-name {
      font-size: 1.4rem;
      font-weight: 700;
    }

    &-buttons {
      display: flex;
      align-items: center;
      gap: 42px;
      margin: 24px 0;
      font-weight: 700;

      button {
        font-weight: 700;
      }

      span {
        cursor: pointer;
      }
    }
  }
  &__bar {
    background-color: #ece9f8;
    height: 3px;
    transition: width 0.1s linear;
    border-radius: 8px;
  }
}
