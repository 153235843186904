.banner-challenge
  color: #fff
  border-radius: 20px
  background: rgb(1,121,195)
  background: radial-gradient(circle, rgba(1,121,195,1) 0%, rgba(0,43,106,1) 100%)
  display: flex
  justify-content: space-between
  flex-direction: column
  gap: 12px
  min-height: 240px
  padding: 24px 32px
  position: relative
  margin-bottom: 40px

  &--admin
    min-height: auto
    border-radius: 10px
    border: 1px solid var(--color-neutral-lightest, #ECE9F8)
    background: linear-gradient(10deg, #FFF 20.02%, #F5F5F5 85.13%)
    box-shadow: $dropshadow-lg

  &__bg
    position: absolute
    inset: 0
    pointer-events: none
    z-index: 0
    background-repeat: no-repeat
    background-position: center right -80px

    .banner-challenge--admin &
      background-position: center right 10%

  &__header, &__body, &__footer
    position: relative
    z-index: 1

  &__title, &__date, &__count
    margin-bottom: 0

  &__header
    display: flex
    flex-direction: column

    .banner-challenge--admin &
      gap: 8px

  &__title
    color: #FFF
    font-size: 3.6rem
    font-weight: 800
    line-height: 1.2

    .banner-challenge--admin &
      color: var(--color-primary-dark)

  &__date
    color: #FFF
    font-size: 1.2rem
    font-weight: 300

    .banner-challenge--admin &
      color: var(--color-neutral-dark, #5D6A86)
      font-size: 1.6rem
      font-weight: 300

  &__count
    color: #fff
    display: inline-flex
    padding: 8px 12px
    align-items: center
    gap: 12px
    border-radius: 8px
    background: rgba(255, 255, 255, 0.20)
    color: #FFF
    font-size: 1.4rem
    font-weight: 700

    svg
      width: 20px
