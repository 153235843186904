.challenge-modal
  height: 0
  flex-grow: 1
  display: flex
  flex-direction: column

  &__section
    flex-grow: 1
    overflow: hidden
    position: relative
    overflow-y: auto

    &--name
      background: var(--color-bright, #F1F5FA)
      display: flex
      flex-direction: column
      align-items: center
      gap: 24px
      padding: 0 16px 16px 16px

      .avatar
        border: 8px solid #fff

    &--question
      display: flex
      padding: 28px max(16px, 2.6vw)
      flex-direction: column
      gap: 20px
      background: #FFF
      box-shadow: $dropshadow-lg
      animation: ModalSectionIn .16s ease-out

    &--success
      background: var(--color-bright, #F1F5FA)
      display: flex
      flex-direction: column
      align-items: center
      gap: 24px
      padding: 0 16px 16px 16px
      animation: ModalSectionIn .16s ease-out

  &__hat, &__name-section-title, &__name-section-subtitle, &__warning, &__title, &__subtitle
    margin-bottom: 0

  &__hat
    @extend .u-text-truncate
    display: flex
    min-width: 194px
    max-width: 100%
    padding: 8px 16px
    justify-content: center
    align-items: center
    gap: 8px
    border-radius: 0px 0px 12px 12px
    border: 2px solid var(--color-neutral-lightest, #ECE9F8)
    background: #FFF
    box-shadow: $dropshadow
    color: var(--color-primary, #442B8C)
    font-size: 1.4rem
    font-weight: 700
    text-transform: uppercase

  &__name-section
    &-header
      display: flex
      flex-direction: column
      align-items: center
      gap: 8px
      text-align: center

    &-title
      color: var(--color-primary)
      font-size: 2.8rem
      font-weight: 700

    &-subtitle
      color: var(--color-neutral, #8894AC)
      font-size: 1.4rem
      font-weight: 300
      display: flex
      align-items: center
      gap: 4px

    &-icon
      flex-shrink: 0
      width: 16px

  &__warning
    display: inline-flex
    align-items: center
    gap: 16px
    padding: 20px
    border-radius: 0px 10px 10px 0px
    background: #FAFAFA
    box-shadow: 0px 4px 4px 0px rgba(114, 114, 114, 0.10)
    max-width: 204px
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.6rem
    font-weight: 700
    line-height: 1.2
    position: absolute
    left: 0
    top: 60px
    animation: fadeInLeft .4s ease-out .5s 1 normal backwards
    img
      width: 24px
    @media (min-width: $md)
      top: auto
      bottom: 60px

  &__header
    display: flex
    justify-content: space-between
    align-items: center

  &__title
    color: var(--color-primary)
    font-size: 2rem
    font-weight: 700
    line-height: 1.2

  &__subtitle
    color: var(--color-neutral, #8894AC)
    font-size: 1.4rem
    font-weight: 300

  &__countdown-timer
    flex-shrink: 0

@keyframes ModalSectionIn
  from
    opacity: 0
  to
    opacity: 1
