.star-rating
  display: flex

  &__btn
    padding: 0 8px
    background-color: transparent
    border: none
    outline: none
    cursor: pointer

  &__star
    width: 44px
    color: #FFD661

  .on svg
    fill: #FFD661
