.show-course
  +g(full, space-between)

.show-course-banner
  +i(1/1)
  background-color: var(--color-primary-lightest)
  background-position: center
  background-size: cover
  border-radius: 10px
  padding: max(12px, 2vw)
  overflow: hidden
  color: white
  position: relative

  &::before
    content: ''
    position: absolute
    inset: 0
    z-index: 1
    background-color: rgba(0, 0, 0, .4)

  > *:not(.show-course-banner__img)
    position: relative
    z-index: 2

  &__hat
    font-size: 1.2rem
    font-weight: 300
    margin-bottom: 0

  &__title
    position: relative
    z-index: 2
    font-size: 2em
    line-height: 1.2
    font-weight: 700
    margin-bottom: 8px
    word-wrap: break-word

  &__description
    position: relative
    z-index: 2
    max-width: 300px
    font-weight: 300

  &__by
    text-align: right
    font-size: 1.2rem
    font-weight: 300
    margin-bottom: 0

  &__cta
    position: relative
    z-index: 2
    margin-top: 4em
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 20px

  &__img
    position: absolute
    inset: 0
    height: 100%
    z-index: 0
    object-fit: cover

  &__inner
    display: flex
    flex-wrap: wrap-reverse
    align-items: flex-end
    gap: 16px 128px

    .show-course-banner__container
      width: 220px
      max-width: 100%
      flex-grow: 1
      .show-course-banner__description
        max-width: 100%

      .btn-group--with-dropdown
        flex-shrink: 0
        width: auto !important

  .btn--outline-secondary
    background-color: #fff

    &:hover
      background-color: var(--color-third)
      color: #fff

.show-course-tabs
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: space-between
  gap: 16px
  margin-bottom: 32px

  .card__progress, .tab
    margin-bottom: 0

  .card__progress
    width: 328px
    max-width: 100%
