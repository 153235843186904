.unisescoop
    --color-primary-dark:#05462D
    --color-primary: #00663E
    --color-primary-light: #42A17C
    --color-primary-lighter: #9EBD9E
    --color-primary-lightest: #e3f0e3

    --color-secondary: #24BAF3
    --color-secondary-light: #95D1E8
    --color-secondary-lighter: #D9E9EF

    --color-third: #F38747
    --color-third-light: #FFAD7A

    --color-neutral-darker: #5d6a86
    --color-neutral: #8894AC
    --color-neutral-light: #BCC4D6
    --color-neutral-lighter: #CCD2E0
    --color-neutral-lightest: #ECE9F8

    --color-bright: #F1F5FA

    --color-danger: #FD6B62