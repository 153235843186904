.challenge-card
  --color-primary-dark: #313fa9
  --color-primary-lighter: #DDF4FF
  padding: 36px 24px 24px 24px
  border-radius: 12px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: #FFF
  box-shadow: 0px 4px 10px 0px rgba(68, 43, 140, 0.05)
  position: relative
  display: flex
  flex-direction: column
  transition: all .15s

  &--turbo
    --color-primary-dark: #df274c
    --color-primary: #FF4C6F
    --color-primary-light: #FDE5EA
    --color-primary-lighter: #FFF6F8

  &--conquest
    --color-primary-dark: #ef9526
    --color-primary: #F9AD53
    --color-primary-light: #FDB462
    --color-primary-lighter: #FFF5E9

  &--done
    pointer-events: none

    .challenge-card__done-medal
      display: inline-block

  &:hover
    // .challenge-card__banner
    //   background-color: var(--color-primary)
    //   color: #fff
    // .challenge-card__pattern
    //   rect
    //     fill: var(--color-primary-dark)
    box-shadow: $dropshadow-md

  &__done-medal
    display: none
    width: 36px
    position: absolute
    right: 0
    top: 0
    translate: 40% -40%

  &__header
    margin-bottom: 24px

  .avatar
    margin: 0 auto

  &__tag
    @extend .u-text-truncate
    display: inline-block
    padding: 2px 8px
    max-width: 80%
    border-radius: 0px 0px 6px 6px
    border: 2px solid #FFF
    border-top: 0
    background: var(--color-primary-lighter)
    box-shadow: 0px 4px 10px 0px rgba(68, 43, 140, 0.05)
    color: var(--color-primary)
    font-size: 1rem
    font-weight: 700
    text-transform: uppercase
    position: absolute
    top: 0
    left: 50%
    transform: translateX(-50%)

  &__close
    stroke: #8894AC
    width: 16px
    position: absolute
    top: 12px
    right: 12px
    cursor: pointer

  &__category
    display: flex
    align-items: center
    gap: 8px
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.2rem
    font-weight: 300
    margin-bottom: 8px

  &__icon
    width: 24px
    height: 24px
    flex-shrink: 0
    background-color: var(--color-primary-lighter)
    border-radius: 100px
    display: flex
    align-items: center
    justify-content: center
    svg
      width: 14px
      stroke: var(--color-primary)

    &--square
      border-radius: 4px

  &__body
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: space-between
    margin-bottom: 16px
    word-wrap: break-word

  &__text p, &__title, &__name
    @extend .u-text-truncate
    width: 100%

  &__text
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1rem
    font-weight: 300
    margin-bottom: 24px
    p
      margin-bottom: 0

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.4rem
    font-weight: 700
    margin-bottom: 0

  &__name
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.2rem
    font-weight: 700

  &__banner
    background-color: var(--color-primary-lighter)
    display: flex
    padding: 8px 16px
    justify-content: space-between
    align-items: center
    gap: 12px
    color: var(--color-primary)
    font-size: 1.4rem
    font-weight: 700
    text-transform: uppercase
    margin: 0 -24px
    height: 47px
    transition: .3s

    &--conquest
      justify-content: center

      .challenge-card__banner-text
        width: auto
        flex-grow: 0

  &__pattern
    width: 24px
    flex-shrink: 0
    transition: .3s
    rect
      fill: var(--color-primary-light)

  &__banner-text
    @extend .u-text-truncate
    margin-bottom: 0
    width: 0
    flex-grow: 1
    text-align: center

  &__medal
    width: 54px
    filter: drop-shadow(0px 4px 4px rgba(119, 119, 119, 0.15))

  &__footer
    height: 40px
    display: flex
    align-items: center
    justify-content: center

    .card__progress-container
      height: 12px

    .card__progress-bar
      border-radius: 100px
      background-color: var(--color-success)

    .card__progress-text
      color: var(--color-neutral-dark)

  .btn--outline
    border-color: var(--color-primary)
