.report-legend {
  flex-grow: 1;
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  gap: 28px;
  border-radius: 10px;
  border: 1px solid var(--color-neutral-lightest, #ece9f8);
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(93, 106, 134, 0.05);

  &-wrapper {
    flex-shrink: 0;
    width: 280px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    border-bottom: 1px solid var(--color-neutral-lightest, #ece9f8);
    padding-bottom: 8px;
  }

  &__title {
    color: var(--color-primary-dark, #110b3e);
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 0;
  }

  &__body {
    flex-grow: 1;
    color: var(--color-neutral-darker, #5d6a86);
    font-size: 1.2rem;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    gap: 16px;

    p {
      margin-bottom: 0;
    }
  }

  &__figure {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 16px;

    img,
    svg {
      flex-shrink: 0;
      width: 76px;
    }
  }

  &__caption {
    width: 100px;
    flex-grow: 1;
  }
}
