.banner {
  border-radius: 10px;
  background: var(--color-primary-light, #7b63bf);
  color: #fff;
  padding: 8px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  @media (min-width: 760px) {
    flex-direction: row;
    align-items: flex-end;
    height: 164px;
    text-align: left;
    margin-bottom: 48px;
  }
}

.avatar {
  flex-shrink: 0;
  border: 5px solid #fff !important;
  @media (min-width: 760px) {
    margin-bottom: -56px;
  }
}

.title,
.text {
  margin-bottom: 0;
}

.title {
  color: #fff;
  text-shadow: 0px 4px 10px rgba(93, 106, 134, 0.05);
  font-size: 2.4rem;
  font-weight: 700;
}

.text {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.2;
}
