.skill-evaluation
  display: flex
  flex-direction: column
  gap: 32px
  padding: 32px
  border-radius: 8px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: #FFF
  box-shadow: $dropshadow
  overflow: auto

  .tooltip-container
    display: inline-block
    position: relative

  .tooltip-container:hover .tooltip-text
    visibility: visible
    opacity: 1

  .tooltip-text
    visibility: hidden
    opacity: 0
    width: 120px
    background-color: var(--color-primary)
    color: #fff
    text-align: center
    padding: 5px 0
    border-radius: 6px
    z-index: 1
    position: absolute
    top: 0
    left: 50%
    transform: translate(-50%, -100%)
    transition: opacity 0.3s

  .tooltip-text::after
    content: ''
    position: absolute
    top: 100%
    left: 50%
    margin-left: -5px
    border-width: 5px
    border-style: solid
    border-color: var(--color-primary) transparent transparent transparent

  &__header
    display: flex
    flex-direction: column
    gap: 8px

    &--student
      margin-bottom: 22px
      text-align: center

      .skill-evaluation__title
        font-size: 20px

  &__body
    display: flex
    flex-direction: column
    @media (min-width: $lg)
      flex-direction: row

  &__col
    width: 100%
    position: relative

    &:first-of-type
      padding-bottom: 32px
      margin-bottom: 32px
      border-bottom: 1px solid var(--color-neutral-lightest, #ECE9F8)
      @media (min-width: $lg)
        padding-bottom: 0
        margin-bottom: 0
        border-bottom: 0
        padding-right: 32px
        margin-right: 32px
        border-right: 1px solid var(--color-neutral-lightest, #ECE9F8)

  &__hat
    margin-bottom: 0
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.4rem
    font-weight: 300

  &__title, &__essay-title
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.6rem
    font-weight: 700
    margin-bottom: 0

  &__essay
    display: flex
    flex-direction: column
    gap: 12px

    &-header
      display: flex
      align-items: center
      justify-content: space-between
      gap: 16px

    &-text
      position: relative
      margin-bottom: 0
      overflow: auto
      // background-color: red
      height: 700px
      sup
        vertical-align: super
        font-size: .5em
        font-weight: 700

      mark
        // display: inline-block
        // max-width: 100%
        // position: relative

    &-mark, &-raw_text
      text-align: justify
      position: absolute
      top: 0
      left: 0
    &-mark
      user-select: none
      z-index: 1
    &-raw_text
      z-index: 10
      &::selection
        color: #222200
        background-color: #ffff00

  &__competence
    display: flex
    flex-direction: column
    gap: 32px
    position: relative

    &-header
      display: flex
      align-items: center
      justify-content: space-between
      gap: 16px

      .square-tag
        flex-shrink: 0

    &-title
      margin-bottom: 0
      color: var(--color-neutral-dark, #5D6A86)
      font-size: 1.6rem
      font-weight: 700

    &-subtitle
      margin-bottom: 0
      color: var(--brand-neutral-neutral, #8894AC)
      font-size: 1.4rem
      font-weight: 300

    &-body
      margin-bottom: 32px

    &-tag
      display: inline-flex
      flex-direction: column
      align-items: center
      gap: 4px
      border-radius: 8px 0px 0px 8px
      border: 1px solid var(--color-neutral-lightest, #ECE9F8)
      background: #FFF
      box-shadow: $dropshadow
      width: 132px
      position: absolute
      right: -45px
      top: 0
      translate: 0 -100%

      &-inner
        position: relative
        padding: 12px 20px

        &::after
          position: absolute
          content: ''
          width: 0
          height: 0
          right: 3px
          bottom: -11px
          border: 10px solid transparent
          border-right-color: var(--color-neutral-lightest)
          rotate: 45deg

      &-title
        color: var(--color-neutral-dark, #5D6A86)
        font-size: 1.2rem
        font-weight: 300
        text-align: center
        margin-bottom: 0

      &-points
        color: var(--color-success, #6FCF97)
        font-size: 2.8rem
        font-weight: 700
        display: flex
        align-items: flex-end
        gap: 4px
        margin-bottom: 0
        line-height: 2.8rem

      &-total
        color: var(--color-neutral-dark, #5D6A86)
        font-size: 1.4rem
        font-weight: 700

  &__comment
    &-title
      margin-bottom: 12px
      color: var(--color-primary, #442B8C)
      font-size: 1.4rem
      font-weight: 700

    &-list
      display: flex
      flex-direction: column
      gap: 8px

    &-item
      display: flex
      align-items: center
      gap: 8px

  &__improve

    &-title
      margin-bottom: 12px
      color: var(--color-primary, #442B8C)
      font-size: 1.4rem
      font-weight: 700

  &__icon
    cursor: pointer
    width: 20px

    &--danger
      color: var(--color-danger)

  &__points
    @extend .form__control
    width: fit-content
    font-weight: 700
    color: var(--color-neutral-dark, #5D6A86)
    display: flex
    align-items: center
    justify-content: center

    input
      all: unset
      max-width: 100%
      text-align: right

    span
      color: var(--color-neutral-light, #BCC4D6)
      font-size: 1.4rem
      font-weight: 300

  &__tip
    display: flex
    padding: 20px
    flex-direction: column
    gap: 8px
    border-radius: 8px
    border: 1px solid var(--brand-neutral-neutral-lightest, #ECE9F8)
    background: linear-gradient(10deg, #FFF 20.02%, #F5F5F5 85.13%)
    box-shadow: $dropshadow
    color: var(--color-neutral-dark, #5D6A86)
    position: relative

    &-icon
      position: absolute
      top: 0
      left: 0
      width: 32px
      height: 32px
      border-radius: 100px
      translate: -50% -50%
      color: var(--color-primary)
      background-color: #fff
      border: 1px solid var(--color-neutral-lightest, #ECE9F8)
      box-shadow: $dropshadow
      display: flex
      align-items: center
      justify-content: center
      svg
        width: 16px

    &-title
      text-align: center
      font-size: 1.4rem
      font-weight: 700
      margin-bottom: 0

    &-text
      font-size: 1.2rem
      font-weight: 300

  &__card
    background-color: #F1F5FA
    padding: 20px
    border-radius: 10px
    border: 1px solid var(--color-neutral-lightest)
