.log-conquest-activities
  display: flex
  flex-direction: column
  padding: 20px
  border-radius: 12px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: #FFF
  box-shadow: $dropshadow

  &__header
    margin-bottom: 12px

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.6rem
    font-weight: 700
    margin-bottom: 0

  &__body
    display: flex
    flex-direction: column
    gap: 12px
    margin-bottom: 40px

  &__activity
    --color-primary-dark: #313fa9
    --color-primary: #4551AD
    --color-primary-light: #A1DFFD
    --color-primary-lighter: #DDF4FF
    margin-bottom: 0
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.2rem
    font-weight: 300
    display: flex
    align-items: flex-start
    gap: 8px

    &--turbo
      --color-primary-dark: #df274c
      --color-primary: #FF4C6F
      --color-primary-light: #FDE5EA
      --color-primary-lighter: #FFF6F8

    &--conquest
      --color-primary-dark: #ef9526
      --color-primary: #F9AD53
      --color-primary-light: #FDB462
      --color-primary-lighter: #FFF5E9

    &-content
      width: 20px
      flex-grow: 1
      word-break: break-word
      line-height: 1

    &-title, &-name
      @extend .u-text-truncate
      width: 100%
      max-width: 100%

    &-title
      color: var(--color-neutral-dark, #5D6A86)
      font-size: 1.4rem
      font-weight: 700
      line-height: 1.2

    &-name
      color: var(--color-neutral-dark, #5D6A86)
      font-size: 1.2rem
      font-weight: 300

    &-value
      flex-shrink: 0
      font-weight: 700

  &__icon
    @extend .challenge-card__icon
    color: var(--color-primary)

  &__text-truncate
    display: block
    width: 0
    flex-grow: 1
    @extend .u-text-truncate
