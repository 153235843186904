.w-full
	width: 100%

.overflow-auto
	overflow: auto

.word-break
	word-wrap: break-word

.post-comment
	@extend .fadeIn
	display: flex
	align-items: flex-start
	gap: 16px
	margin: 0
	padding: 1em 0
	border-bottom: 1px solid var(--color-bright)
	position: relative
	+bp(md)
		padding: 1em 0

	&:last-child
		border-bottom: none

	&--pinned
		border-radius: 10px
		padding: 10px
		background: var(--color-neutral-bright, #F1F5FA)
		border-bottom: 0

		.expanded-calendar__tag
			position: absolute
			top: 10px
			right: 10px

		.post-comment__actions
			align-self: flex-end

	&__avatar
		border: 1px solid var(--color-primary-lightest)
		width: 25px
		height: 25px
		background-size: cover
		background-position: center
		border-radius: 50px
		+bp(md)
			width: 30px
			height: 30px

	&__body
		flex-grow: 1

	&__header-name
		font-weight: 700
	&__header-time
		color: var(--color-neutral)
		font-size: .8em

	&__header
		padding-bottom: .5em

	&__description
		color: var(--color-neutral)
		line-height: 1.5em
		font-size: .85em
		white-space: pre-line
		+bp(md)
			font-size: .92em

	&__description-image
		margin-top: 1em
		min-height: 200px
		+i(1/1)
		background: var(--color-primary-lightest)
		border-radius: 10px

	&__action
		text-align: center
		margin-bottom: 1em

	&__action-icon
		width: 15px
		margin: 0 auto
		transition: transform ease-in-out .2s
		color: var(--color-primary)
		cursor: pointer
		&:hover
			cursor: pointer
			transform: scale(1.1)
	&.filled
		svg
			fill: black

	&__action-count
		font-size: .8em
		color: var(--color-primary)

	.btn--tiny
		position: absolute
		top: 10px
		right: 0px

	.btn--answers
		text-decoration: underline
		background: none
		border: none
		color: var(--color-primary)
		margin-bottom: 10px
		font-size: 12px
		padding: 0
		&:focus-visible
			outline: none !important

	.container-submit-answers
		display: grid
		grid-template-columns: 1fr 40px
		align-items: center
		margin-top: 10px
		gap: 8px

	.btn--submit-answers
		background: var(--color-primary)
		border: none
		border-radius: 100%
		color: white
		width: 30px
		height: 30px
		display: flex
		align-items: center
