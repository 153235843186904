$medium-blank-space: 2em
$color-black-light: #4A4A49

.mock
    position: fixed
    top: 0px
    bottom: 0px
    left: 0px
    right: 0px
    z-index: 1061
    +g(full, center, middle)
    animation: fadeIn ease-in-out .2s
    overflow-y: auto
    padding: 40px 0

    &--overlay
        background: rgba(black, .80)

    &__content
        z-index: 210
        background: white
        position: relative
        padding: $medium-blank-space
        +g(full, middle)
        padding: 85px $medium-blank-space*1.5
        border-radius: 20px
        width: 90%
        height: 90%
        max-width: 1050px
        max-height: 430px
        background-repeat: no-repeat
        background-position: right center
        background-size: 45%
        box-sizing: border-box
        +bp(md)
            background-image: url('../../images/bg_banner_mock_question_book.png')

    &__reminder
        float: left
        cursor: pointer
        display: block
        left: 30px
        position: absolute
        top: 30px
        font-weight: 300
        font-size: 12px

    &__close-btn
        position: absolute
        top: 2em
        right: 2em
        cursor: pointer

        svg
            width: 40px
            height: 40px
            background: white
            border-radius: 100px

    &__title
        font-family: $title
        font-size: 38px
        font-weight: 700
        color: $color-black-light
        line-height: 1.2
        max-width: 380px

    .card__count
        padding: 20px 16px

        @media (max-width: 768px)
            padding: 8px
