.tag-list
  +i(1/1)
  +g(full)
  margin: 1em 0

.tag
  background: var(--color-primary-light)
  color: white
  padding: .3em 1.2em
  border-radius: 50px
  font-size: .8em
  font-weight: 700
  margin-top: .3vw
  margin-bottom: .3vw
  transition: background ease-in-out .2s
  cursor: pointer
  & + &
    margin-left: .3vw

  &:hover
    background: var(--color-primary-dark)

.square-tag
  display: inline-block
  cursor: default
  background-color: var(--color-bright)
  border-radius: 4px
  line-height: 2.2rem
  padding: 0 8px
  font-weight: 700
  font-size: 1.2rem
  text-align: center
  color: var(--color-neutral)
  @extend .u-text-truncate

  span
    font-size: .8em
    font-weight: 300

  &--md
    font-size: 1.6rem
    line-height: 24px

  &--full
    width: 100%

  &--success
    background-color: var(--color-success)
    color: white

  &--warning
    background-color: var(--color-warning)
    color: white

  &--danger
    background-color: var(--color-danger)
    color: white
