.search
  &__header
    margin-bottom: 24px
    padding-bottom: 24px
    border-bottom: 1px solid var(--color-neutral-lightest, #ECE9F8)

  &__body
    height: 440px
    display: flex
    flex-direction: column
    padding-right: 12px
    overflow-y: auto

    .empty-state
      margin: auto

  &__loader
    margin: auto
    text-align: center

    &-icon
      @extend .spin
      margin-bottom: 12px
      width: 32px

    &-title
      color: var(--color-neutral)
      font-size: 1.6rem
      line-height: 1.5
      margin-bottom: 0

  &__result
    padding: 16px 4px
    display: flex
    align-items: flex-start
    gap: 20px
    cursor: pointer
    background-color: #fff
    border-bottom: 1px solid var(--color-primary-lightest)

    &-icon, &-date
      flex-shrink: 0

    &-icon
      color: var(--color-primary)
      background-color: var(--color-primary-lightest)
      width: 40px
      height: 40px
      display: flex
      align-items: center
      justify-content: center
      border-radius: 50px
      position: relative

    &-content
      width: 0
      flex-grow: 1

    &-header
      margin-bottom: 12px

    &-date, &-title, &-text
      margin-bottom: 0

    &-title
      color: var(--color-primary-dark, #110B3E)
      font-size: 1.2rem
      font-weight: 700

    &-text
      @extend .u-text-truncate
      color: var(--color-neutral-dark, #110B3E)
      font-size: 1.2rem
      font-weight: 300

    &-date
      color: var(--color-neutral-dark, #110B3E)
      font-size: 1.2rem
      font-weight: 700
