.donate-card
    +g(full, middle)
    background: white
    box-shadow: $dropshadow
    border-radius: 10px
    padding: 1em
    margin-top: 1em
    margin-bottom: 1em
    cursor: pointer
    +i(1/1)
    transition: all ease-in-out .2s
    +bp(md)
    padding: 1.5em
    @extend .fadeIn
    &:hover
        margin-top: 1em
        box-shadow: 0px 4px 10px rgba(var(--color-primary), 0.4)

    input[type="radio"]
        /* remove standard background appearance */
        -webkit-appearance: none
        -moz-appearance: none
        appearance: none
        /* create custom radiobutton appearance */
        display: inline-block
        width: 16px
        height: 16px
        padding: 2px
        /* background-color only for content */
        background-clip: content-box
        border: 2px solid var(--color-neutral-light)
        background-color: white
        border-radius: 50%

    /* appearance for checked radiobutton */
    input[type="radio"]:checked
        background-color: var(--color-primary)

    input[type="radio"]:focus
        outline: none

    &__title
        +i(7/12, md 8/12)
        margin: .2em 0 0 .5em
        font-size: .85em
        font-weight: 700
        color: var(--color-primary-dark)
        display: inline-block
        +bp(md)
        font-size: 1em

    &__description
        +i(1/1)
        margin: 0 0 0 2em
        font-weight: normal
        color: var(--color-neutral)
        font-size: .9em

    &__price
        +i(3/12, md 2/12)
        text-align: right
        margin-top: .2em
        font-size: .9em
        font-weight: 700
        color: var(--color-primary-dark)
