.challenge-question
  flex-grow: 1
  display: flex
  flex-direction: column
  padding: max(12px, 3.5vw)
  gap: 20px
  border-radius: 20px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: linear-gradient(10deg, #FFF 20.02%, #F5F5F5 85.13%)
  box-shadow: $dropshadow-lg
  position: relative

  &__count
    align-self: flex-end
    color: var(--color-primary)
    font-size: 1.2rem
    font-weight: 300
    display: flex
    align-items: center
    gap: 4px
    svg
      flex-shrink: 0
      width: 16px
    @media (min-width: $lg)
      position: absolute
      top: 16px
      right: 16px

  &__header
    display: flex
    min-height: 136px
    padding: 8px
    justify-content: center
    align-items: center
    text-align: center
    gap: 10px
    border-radius: 4px
    background: #FFF

  &__title
    margin-bottom: 0
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.6rem
    font-weight: 700
    line-height: 1.2

  &__body
    flex-grow: 1

  &__alternatives-list
    display: flex
    flex-direction: column
    gap: 8px

  &__alternative
    display: flex
    align-items: center
    padding: 8px
    gap: 8px
    border-radius: 4px
    border: 1px solid var(--color-neutral-lightest, #ECE9F8)
    background: #FFF
    cursor: pointer
    appearance: none
    position: relative
    text-align: left
    color: var(--color-neutral-dark, #5D6A86)

    &--success
      background-color: #42924E
      color: #fff

      .challenge-question__radio
        border: 2px solid #fff

        &::before
          display: block
          content: ''
          width: 8px
          height: 8px
          background-color: #fff
          border-radius: 100%

    &--danger
      background-color: var(--color-danger)
      color: #fff

      .challenge-question__radio
        border: 2px solid #fff

        &::before
          display: block
          content: ''
          width: 8px
          height: 8px
          background-color: #fff
          border-radius: 100%

  &__radio
    flex-shrink: 0
    width: 16px
    height: 16px
    border: 1px solid var(--color-neutral)
    border-radius: 100%
    display: flex
    align-items: center
    justify-content: center

  &__icon
    width: 32px
    position: absolute
    right: -8px
    top: -8px

  &__footer
    text-align: center

  &__comment
    margin-top: 20px
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    gap: 8px 16px
    text-align: left

    &-content
      display: flex
      flex-direction: column
      padding: 8px
      gap: 8px
      border-radius: 8px
      background: var(--color-bright, #F1F5FA)
      color: var(--color-eutral-dark, #5D6A86)
      font-size: 1.6rem
      font-weight: 300
      width: 320px
      max-width: 100%
      flex-grow: 1

      > *
        margin-bottom: 0
