.pit
    --color-primary-dark: #0F2049
    --color-primary: #3C5691
    --color-primary-light: #7390D2
    --color-primary-lighter: #9FADCE
    --color-primary-lightest: #F3F7FF

    --color-secondary: #5CB8DE
    --color-secondary-light: #9FD9F1
    --color-secondary-lighter: #DCECF2

    --color-third: #FF9153
    --color-third-light: #FF9B63

    --color-neutral-darker: #5d6a86
    --color-neutral: #8894AC
    --color-neutral-light: #BCC4D6
    --color-neutral-lighter: #CCD2E0
    --color-neutral-lightest: #ECE9F8

    --color-bright: #F1F5FA

    --color-danger: #FD6B62
