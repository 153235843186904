.magic-link
  position: relative
  min-height: 120px
  +g(full, middle)
  +i(1/1)
  transition: all ease-in-out .2s
  &:hover
    text-decoration: none !important
    box-shadow: 0 0px 20px rgba(black, .1)

  &__title
    color: var(--color-primary)
    font-size: 1em
    font-weight: 700
    word-break: normal
    margin-bottom: .8em
    line-height: 1.2em
    display: -webkit-box
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    overflow: hidden

  &__url
    color: var(--color-secondary)
    font-size: .8em

  &__description
    color: var(--color-primary-lighter)
    font-weight: 300
    font-size: .85em
    margin: 1em 0 0
    margin-top: .5em
    word-break: normal
    line-height: 1.4em
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden

  &__image
    +i(12/12, md 3.5/12)
    min-height: 180px
    background-size: cover
    background-position: center
    background-color: var(--color-primary-lightest)
    +bp(md)
      position: absolute
      top: 0px
      bottom: 0px
      left: 0px
      right: 0px
      min-height: 130px
      max-height: 130px
    img
      width: 100%

  &__body
    +i(12/12, md 8/12)
    padding: 1em 0
    +bp(md)
      margin-left: 33.33333%
      padding: 0 1em

    &--no-image
      +i(12/12)
      margin-left: 0px
      +bp(md)
        margin-left: 0px
        padding: 0px

  &__close svg
    float: right
    width: 24px
    height: 16px
    cursor: pointer
