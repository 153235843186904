.kpi-cards
  display: flex
  flex-wrap: wrap
  gap: 24px

.kpi-card
  +g(full)
  flex-direction: column
  width: min(100%, 220px)
  flex-grow: 1
  @extend .u-box
  padding: 1.5em
  gap: 8px
  border: 1px solid var(--color-neutral-lightest)
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
  position: relative

  &__icon
    background-color: var(--color-primary-lightest)
    width: 30px
    height: 30px
    +g(full, center, middle)
    border-radius: 100px
    svg
      color: var(--color-primary)

  &__help-icon
    flex-shrink: 0
    cursor: help
    color: var(--color-neutral)
    width: 20px
    position: absolute
    top: 8px
    right: 8px
    transition: all .15s

    &:hover
      color: var(--color-primary)

  &__title
    +i(1/1)
    flex-grow: 1
    font-weight: 700
    color: var(--color-primary-dark)

  &__value
    font-weight: 700
    font-size: 1.2em
    color: var(--color-primary)
    +i(1/1)

  &__metric
    +i(1/1)
    padding: .5em 1em
    border-radius: 5px
    background-color: var(--color-primary-lightest)
    font-size: .8em

    &.negative
      background-color: #facbc8
      color: #8d120a

    &.positive
      background-color: #DCFFEA
      color: #1CA388

  &__explanation
    color: var(--color-neutral-dark)
    font-size: .8em
