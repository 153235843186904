.students-list {
  height: 500px;
  overflow: auto;
  padding: 8px;
}

.challenged-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  border-radius: 10px;

  &--selected {
    background-color: var(--color-neutral-bright);
    padding: 12px;
  }

  &__student {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__invite-btn {
    color: var(--color-primary);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.duels-page {
  display: flex;
  flex-wrap: wrap;
  gap: 96px;

  &__empty {
    display: flex;
    flex-grow: 1;
    height: fit-content;
  }

  @media (max-width: 768px) {
    gap: 24px;
  }

  &__main {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }

  &__aside {
    min-width: 300px;
  }
}

.status-filter {
  display: flex;
  align-items: center;

  label {
    margin-right: 24px;
  }

  input {
    margin: 0;
    margin-right: 4px;
  }
}

.duel-modal {
  > button {
    margin-right: 12px;
  }

  @media (max-width: 480px) {
    max-width: 100vw !important;
  }
}
