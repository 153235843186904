.show-course-main-content
  padding: 2vw 0
  flex-shrink: 0
  width: 320px
  max-width: 100%
  flex-grow: 1

  &--full
    width: 100%

.show-course-section
  margin: 0 0 2em 0

  &__title
    font-weight: 800
    color: var(--color-primary-dark)
    margin-bottom: .5em

  &__description
    font-size: .9em
    color: var(--color-neutral-dark)
    margin-bottom: 16px

  &__list
    @extend .show-course-section__description
    list-style: disc
    margin-left: 20px
