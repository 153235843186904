.duel-card {
  display: flex;
  gap: 12px;

  > svg {
    height: 30px;
    width: 30px;
    background-color: #f1f5fa;
    color: var(--color-primary);
    padding: 4px;
    border-radius: 4px;
  }

  &__content {
    flex-grow: 1;
    margin-bottom: 12px;
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 700;
  }

  &__success-badge {
    background-color: #1ca388 !important;
    color: #fff !important;
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-neutral-bright);
    border-radius: 10px;
    padding: 12px;
    margin: 12px 0;

    &-profile {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--color-neutral);
      font-size: 1.2rem;

      &--img {
        position: relative;

        &__star-icon {
          position: absolute;
          bottom: 0;
          right: 0;
          background-color: #74dbe1;
          border-radius: 50%;
          padding: 2px;
          border: #74dbe1;
          fill: #fff;
          color: #fff;
          height: 17px;
          width: 17px;
        }
      }
    }

    &--score {
      display: flex;
      align-items: center;
      font-size: 1.8rem;
      font-weight: 700;
      gap: 8px;
    }
  }

  &__deadline {
    color: var(--color-neutral);

    &--alert {
      color: var(--color-danger);
    }
  }

  button {
    font-weight: 700;
  }
}

.vs {
  color: #f44336;
  width: 20px;
  height: 20px;
}
