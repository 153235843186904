.instruction-widget
  display: flex
  padding: 20px
  align-items: center
  gap: 40px
  border-radius: 12px
  background: #FFF

  &-wrapper
    display: flex
    flex-direction: column
    gap: 20px

  &__header
    display: flex
    align-items: flex-start
    gap: 8px

  &__number
    flex-shrink: 0
    display: inline-block
    font-size: 1.6rem
    line-height: 2.4rem
    width: 24px
    border-radius: 100px
    color: var(--color-primary, #442B8C)
    font-weight: 700
    background-color: var(--color-neutral-lightest, #ECE9F8)
    text-align: center

  &__info
    flex-grow: 1

  &__title
    color: var(--color-primary, #442B8C)
    font-size: 1.4rem
    font-weight: 700
    margin-bottom: 8px

  &__description
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.4rem
    font-weight: 300
    margin-bottom: 0

  &__footer
    margin-top: 8px

  &__body, &__footer
    text-align: center

  &__img
    height: 70px
    width: auto
    max-width: fit-content
