.taxonomies
	@extend .fadeIn
	display: flex
	flex-wrap: wrap
	flex-direction: column
	background: #FFF
	border-radius: 10px
	box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
	padding: 24px
	@media (min-width: $xl)
		flex-direction: row

	&__column
		flex-grow: 1
		min-width: 260px
		padding: 10px 0
		@media (min-width: $xl)
			padding: 10px 40px
			max-width: calc( 100% / 3 )
		&:not(:last-child)
			border-bottom: solid 1px #EEEBF9
			@media (min-width: $xl)
				border-right: solid 1px #EEEBF9
				border-bottom: none
		&:first-child
			padding-left: 0
		&:last-child
			padding-right: 0

	.btn--outline
		margin-bottom: 30px

	&__item
		+g(full, space-between, middle)
		cursor: pointer
		background-clip: padding-box
		border: 1px solid var(--color-primary-lightest)
		color: var(--color-neutral-darker)
		border-radius: 50px
		padding: .5em 1em
		margin-bottom: 1em
		font-size: .8em
		gap: .5em

		&.active, &:hover
			background-color: var(--color-primary)
			color: #FFF
			.taxonomies__btn
				color: white
			svg
				stroke: #fff

	&__text
		+i(6/10)
		display: inline-block
		flex-grow: 1
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis
		font-size: 13px

	&__btn
		appearance: none
		background: transparent
		border: none
		padding: 0
		flex-shrink: 0
		margin-top: -.1em
		color: var(--color-neutral)

	&__icon
		width: 10px
		flex-shrink: 0
		margin-right: 10px
		cursor: move
		cursor: grab
