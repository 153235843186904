.answer-guide-kpis
    +g(full, space-between, middle)
    +i(1/1)

.answer-guide-kpi
    outline: 1px solid var(--color-neutral-lightest)
    +i(1/2)
    min-height: 40px
    display: flex
    justify-content: center
    align-items: center

    b
        margin-left: .5em
        color: var(--color-primary)

.card-metrics-kpi
    padding: 12px 10px
    max-width: 135px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    box-shadow: 0px 4px 10px 0px rgba(68, 43, 140, 0.05)
