@media print
  @page
    size: A4
    margin: 8mm

.printed-exam
  padding: 20px
  background-color: #fff
  @media print
    padding: 0

  &__header
    display: flex
    align-items: center
    justify-content: space-between
    flex-wrap: wrap
    gap: 16px
    margin-bottom: 12px

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-family: Nunito
    font-size: 1.4rem
    font-style: normal
    font-weight: 700
    line-height: 150%
    margin-bottom: 0

  &__subtitle
    color: var(--color-primary-dark, #110B3E)
    font-family: Nunito
    font-size: 1.2rem
    font-style: normal
    font-weight: 300
    line-height: 150%
    margin-bottom: 0

  &__logo
    width: 140px

  &__form
    margin-bottom: 20px
    display: flex
    flex-direction: column
    gap: 4px

    &-row
      display: flex
      gap: 8px

    &-control
      flex-grow: 1
      margin-bottom: 0
      border-radius: 4px
      padding: 4px
      background-color: #fff
      border: 1px solid var(--color-neutral-lightest)
      color: var(--color-primary-dark, #110B3E)
      font-family: Nunito
      font-size: 1rem
      font-style: normal
      font-weight: 700
      line-height: 150%

  &__info
    display: flex
    padding: 12px
    flex-direction: column
    align-items: center
    gap: 16px
    border-radius: 8px
    background: #F1F5FA
    print-color-adjust: exact
    margin-bottom: 20px

  &__meta
    color: var(--color-primary-dark, #110B3E)
    font-family: Nunito
    font-size: 1.2rem
    font-style: normal
    font-weight: 300
    line-height: 150%
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 24px

    &-item
      display: flex
      align-items: center
      gap: 4px
      margin-bottom: 0

  &__icon
    color: var(--color-neutral-light)
    width: 16px

  &__description
    color: var(--color-neutral-dark, #5D6A86)
    text-align: justify
    font-family: Nunito
    font-size: 1.2rem
    font-style: normal
    font-weight: 300
    line-height: 150%
    margin-bottom: 0
    align-self: stretch

  &__questions
    display: flex
    flex-direction: column
    gap: 64px
    margin-bottom: 64px

  &__sheet
    border: 1px solid #000
    padding: 16px 8px

    &-number
      color: var(--color-neutral-dark, #5D6A86)
      font-size: 1.2rem

    &-line
      height: 32px
      border-bottom: 1px solid #000

      &:first-of-type
        height: 32px
        width: 100%
        margin: 0 auto

.printed-question
  display: flex
  flex-direction: column
  gap: 16px

  &__header
    display: flex
    align-items: flex-start
    justify-content: space-between
    padding: 4px 8px
    border-radius: 4px
    background: #F1F5FA
    print-color-adjust: exact

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-family: Nunito
    font-size: 1.1rem
    font-style: normal
    font-weight: 700
    line-height: 150%
    margin-bottom: 0

  &__subtitle
    color: var(--color-primary-dark, #110B3E)
    font-family: Nunito
    font-size: 1rem
    font-style: normal
    font-weight: 300
    line-height: 150%
    margin-bottom: 0

  &__tag
    margin-bottom: 0
    border-radius: 2px
    padding: 2px 4px
    background-color: #fff
    color: var(--color-neutral-dark)
    color: var(--brand-neutral-neutral-darker, #5D6A86)
    font-family: Nunito
    font-size: 1rem
    font-style: normal
    font-weight: 300
    line-height: 150%

  &__enunciation
    color: var(--color-primary-dark, #110B3E)
    font-family: Nunito
    font-size: 1.1rem
    font-style: normal
    font-weight: 700
    line-height: 150%
    margin-bottom: 0
    display: flex
    flex-direction: column
    gap: 16px
    > *
      margin-bottom: 0
    img
      width: 276px

  &__motivating-text
    display: flex
    flex-direction: column
    padding: 16px
    gap: 10px
    border-radius: 4px
    border: 1px solid var(--color-neutral-lighter, #CCD2E0)
    color: var(--brand-primary-dark, #110B3E)
    text-align: justify
    font-family: Nunito
    font-size: 1rem
    font-style: normal
    font-weight: 300
    line-height: 150%
    word-break: break-word
    p
      margin-bottom: 0

  &__press
    color: var(--color-primary-dark, #110B3E)
    font-style: italic
    font-weight: 700

  &__alternatives
    display: flex
    flex-direction: column
    gap: 12px

  &__alternative
    color: var(--color-primary-dark, #110B3E)
    text-align: justify
    font-family: Nunito
    font-size: 1.1rem
    font-style: normal
    font-weight: 300
    line-height: 150%
    display: flex
    align-items: flex-start
    gap: 12px

    &-id
      font-weight: 700
      display: flex
      align-items: center
      gap: 6px
      flex-shrink: 0

    img
      width: 236px

.answer-key-wrapper
  border: 1px solid #000
  padding: 16px 8px
  display: grid
  grid-template-columns: repeat(4, 1fr)
  gap: 9px 8px

  &__columns
    columns: 2 auto
    column-gap: 20px

.answer-key
  color: #000
  border: 1px solid #000
  font-size: 1.6rem
  padding: 2px 8px
  display: flex
  align-items: center
  gap: 8px
  // width: 170px

  &--gray
    border-color: #acacac
    height: 30px

  &__number
    width: 32px
    flex-shrink: 0
    display: flex

  &__alternative
    width: 16px
    height: 16px
    flex-shrink: 0
    border: 1px solid #000
    border-radius: 50px
    line-height: 16px
    font-size: 1rem
    text-align: center
    color: #acacac
    print-color-adjust: exact

  &__id
    width: 60px
    flex-shrink: 0
    margin-left: auto
