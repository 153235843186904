.admin-course-card-wrapper
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(328px, 1fr))
  grid-gap: 20px
  gap: 20px
  @media (min-width: $lg)
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr))

.admin-course-card
  background-color: #fff
  padding: 20px
  box-shadow: 0px 4px 10px rgba(119, 119, 120, 0.05)
  border-radius: 12px
  position: relative
  display: flex
  flex-wrap: wrap
  flex-direction: column
  gap: 20px

  &--flex-gap-5
    display: flex
    gap: 5px
    align-items: flex-start

  @media (min-width: $sm)
    flex-direction: row

  &:hover, &:focus
    .admin-course-card__img
      transform: scale(1.2)

  &__img-wrapper
    border-radius: 12px
    overflow: hidden
    position: relative
    width: 144px
    min-height: 144px
    flex-shrink: 0
    margin: 0 auto
    .badge
      position: absolute
      left: 12px
      top: 12px
      z-index: 1

  &__img
    width: 100%
    height: 100%
    object-fit: cover
    transition: all 1s ease

  &__inner
    width: 100%
    max-width: 100%
    flex-grow: 1
    display: flex
    flex-direction: column
    gap: 24px
    @media (min-width: $sm)
      width: 144px

  &__header > :last-child, &__body > :last-child, &__footer > :last-child
    margin-bottom: 0

  &__title
    @extend .u-text-truncate
    cursor: default
    word-break: break-all
    font-weight: 700
    font-size: 1.6rem
    line-height: 1.2
    color: var(--color-primary-dark)

  &__body
    flex-grow: 1

  &__icon
    min-width: 20px
    stroke: var(--color-primary)

    &--fill
      fill: var(--color-primary)

  &__footer
    display: flex
    align-items: center
    gap: 20px

  &__rating
    flex-grow: 0
    white-space: nowrap
    margin-bottom: 0
    font-weight: 700
    font-size: 1.4rem
    color: #5D6A86
    display: flex
    align-items: center
    gap: 4px
    span
      color: var(--color-neutral)
      font-weight: 300
      font-size: 1.2rem
    svg
      min-width: 16px
      stroke: #FFD661
      fill: #FFD661

  .btn-group--with-dropdown
    width: 112px
    flex-grow: 1
