.profile-page

  &__input-button
    input[type=submit]
      padding: 5px 25px !important

  &__header
    font-weight: 700

  &__notifications
    padding-bottom: 30px
    display: flex
    justify-content: space-between

  &__disclaimer
    width: 100%
    background-color: #F6F6FA
    height: fit-content
    padding: 10px
    border: 1px solid #ECE9F8
    +bp(md)
      width: 320px

  &__box
    width: 100%
    border-radius: 10px
    max-width: 657px
    padding: 30px
    background-color: #FFFFFF
    box-shadow: 0px 4px 10px rgb(68 43 140 / 5%)

  &__sidebar
    flex-shrink: 0
    width: 160px
    text-align: center
    +bp(md)
      text-align: left

  &__avatar
    width: 130px
    height: 130px
    background-size: cover
    border-radius: 50%
    background: white
    background-repeat: no-repeat
    background-size: cover
    background-position: center
    border: 2px solid var(--color-neutral)
    box-shadow: 0 0 20px rgba(var(--color-primary-dark), .2)
    margin: 0 auto
    margin-bottom: -1em
    +bp(md)
      margin: inherit
      margin-bottom: -1.5em

  &__info
    color: var(--color-neutral)
    font-size: .9em
    transform: translateY(50%)

  &__avatar-btn
    +g(full, center, middle)
    width: 50px
    height: 50px
    background: var(--color-primary)
    border-radius: 50%
    border: 2px solid white
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    margin-left: 50px
    transform: translate(50%, -50%)
    cursor: pointer
    transition: all ease-in-out .2s
    +bp(md)
      transform: translate(50%, -50%)
    &:hover
      background: var(--color-primary-dark)
    input
      display: none

    img
      width: 20px
      height: 15px
      padding: 0
      transition: all ease-in-out .2s
      margin: 0
  &__wrapper
    width: 800px

  &__content
    background-color: #FFFFFF
    width: 100%
    display: flex
    flex-direction: column
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
    padding: 20px 30px
    border-radius: 10px
    +bp(md)
      flex-direction: row
