.completed-conquest
  width: 312px
  max-width: 100%
  aspect-ratio: 1 / 1
  padding: 20px
  flex-shrink: 0
  border-radius: 311px
  border: 6px solid #FFF
  background: #ededed
  box-shadow: 0px 4px 10px 0px rgba(119, 119, 120, 0.15)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: 16px
  text-align: center

  &--danger
    background: #FFE7E5
  &--warning
    background: #FFECD8
  &--primary
    background: #ECE9F8

  &__img
    width: 146px

  &__title, &__description
    margin-bottom: 0

  &__title
    color: var(--color-primary-dark, #110B3E)
    text-align: center
    font-size: 1.6rem
    font-weight: 700

  &__description
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.2rem
    font-weight: 300

.stars
  path:nth-child(1)
    animation: starsIn .9s ease-out .6s infinite
  path:nth-child(2)
    animation: starsIn .8s ease-out .2s infinite
  path:nth-child(3)
    animation: starsIn .7s ease-out .4s infinite
