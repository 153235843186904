.filter-bar
    display: flex
    align-items: flex-end
    flex-wrap: wrap
    justify-content: space-between
    gap: 8px
    padding-bottom: 20px
    margin-bottom: 24px
    border-bottom: 1px solid var(--color-primary-lightest)
    width: 100%

    &--left
        justify-content: left

    &--borderless
        border-bottom: none
        padding-bottom: 0

    &--bread-crumbs
        @media (min-width: 784px)
            gap: 0

        .filter-bar__inner
            align-self: center
            @media (min-width: 784px)
                height: 44px
                overflow: hidden
                padding: 0 0 12px 0
                align-items: center
                flex-wrap: nowrap

            > .filter-bar__back:first-child
                align-self: center
                .card__icon-wrapper
                    display: none
                .filter-bar__back-container

        @media (min-width: 784px)
            border-bottom: none
            padding-bottom: 0
            padding-left: 45px
            margin-bottom: 0
            position: fixed
            z-index: 105
            left: 64px
            right: 0
            top: 32px
            width: calc(100vw - 324px - 64px)
            overflow: hidden

    &__row
        max-width: 100%

        &.disabled
            .filter-bar__label
                color: var(--color-neutral-light)

    &__hat
        margin-bottom: 0
        width: 100%
        color: var(--color-neutral, #8894AC)
        font-size: 1.1rem
        font-style: normal
        font-weight: 300

    &__inner
        display: flex
        align-items: flex-end
        flex-wrap: wrap
        gap: 8px 20px
        max-width: 100%

    &__label
        @extend .form__label

    &__select, .form__control--search-with-icon
        appearance: none
        width: 200px
        max-width: 100%
        height: 40px
        border: 1px solid var(--color-primary-lightest)
        border-radius: 6px
        font-size: 13px!important
        line-height: 1.5
        color: var(--color-neutral)
        padding: 8px 40px 8px 16px
        background-color: #fff
        background-repeat: no-repeat
        background-position: right 16px center
        background-size: 8px
        background-image: url("data:image/svg+xml,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M1.82904 0.313814C1.41062 -0.104605 0.732233 -0.104605 0.313814 0.313814C-0.104605 0.732233 -0.104605 1.41062 0.313814 1.82904L3.74239 5.25761C4.1608 5.67603 4.8392 5.67603 5.25761 5.25761L8.68619 1.82904C9.10461 1.41062 9.10461 0.732233 8.68619 0.313814C8.26777 -0.104605 7.58938 -0.104605 7.17096 0.313814L4.5 2.98477L1.82904 0.313814Z' fill='rgb(93, 106, 134)'/></svg>")
        transition: .15s

        &--calendar
            background-size: 16px
            background-image: url("data:image/svg+xml,<svg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M13.4583 3.33337H3.54167C2.75926 3.33337 2.125 3.96764 2.125 4.75004V14.6667C2.125 15.4491 2.75926 16.0834 3.54167 16.0834H13.4583C14.2407 16.0834 14.875 15.4491 14.875 14.6667V4.75004C14.875 3.96764 14.2407 3.33337 13.4583 3.33337Z' stroke='rgb(136, 148, 172)' stroke-linecap='round' stroke-linejoin='round'/><path d='M11.3334 1.91663V4.74996' stroke='rgb(136, 148, 172)' stroke-linecap='round' stroke-linejoin='round'/><path d='M5.66663 1.91663V4.74996' stroke='rgb(136, 148, 172)' stroke-linecap='round' stroke-linejoin='round'/><path d='M2.125 7.58337H14.875' stroke='rgb(136, 148, 172)' stroke-linecap='round' stroke-linejoin='round'/></svg>")

        &:hover
            color: var(--color-neutral-dark)
            border-color: var(--color-neutral-light)
            background-color: var(--color-bright)

        &:focus
            outline: none
            color: var(--color-neutral-dark)
            border-color: var(--color-neutral-dark)

        &:disabled
            pointer-events: none
            opacity: .5

    .form__control--search-with-icon
        background-size: 12px
        background-image: url("data:image/svg+xml,<svg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M6.75002 2C3.85053 2 1.50003 4.3505 1.50003 7.24999C1.50003 10.1495 3.85053 12.5 6.75002 12.5C9.64951 12.5 12 10.1495 12 7.24999C12 4.3505 9.64951 2 6.75002 2ZM3.05176e-05 7.24999C3.05176e-05 3.52207 3.0221 0.5 6.75002 0.5C10.4779 0.5 13.5 3.52207 13.5 7.24999C13.5 10.9779 10.4779 14 6.75002 14C3.0221 14 3.05176e-05 10.9779 3.05176e-05 7.24999Z' fill='rgb(136, 148, 172)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M10.4572 10.9572C10.7501 10.6643 11.225 10.6643 11.5178 10.9572L14.7803 14.2197C15.0732 14.5125 15.0732 14.9874 14.7803 15.2803C14.4875 15.5732 14.0126 15.5732 13.7197 15.2803L10.4572 12.0178C10.1643 11.7249 10.1643 11.2501 10.4572 10.9572Z' fill='rgb(136, 148, 172)'/></svg>")

        &::placeholder
            color: var(--color-neutral-light)

    &__multi-select
        width: 200px
        border-radius: 6px
        font-size: 13px
        line-height: 1.5
        color: var(--color-neutral)
        .react-multi-select__indicator svg
            width: 12px
            fill: rgb(93, 106, 134)
        .react-multi-select__control
            background-color: #fff !important
            border-color: var(--color-primary-lightest) !important

    .react-daterange-picker__wrapper
        appearance: none
        width: 100%
        border: 1px solid var(--color-primary-lightest)
        border-radius: 6px
        font-size: 13px
        line-height: 1.5
        color: var(--color-neutral)
        padding: 12px 16px
        background-color: #fff
        background-repeat: no-repeat
        background-position: right 16px center
        background-size: 8px

    &__input
        appearance: none
        border: 1px solid var(--color-primary-lightest)
        width: 1em
        height: 1em
        margin-top: .25em
        background-color: white
        background-repeat: no-repeat
        background-size: contain
        print-color-adjust: exact
        position: relative

        &:checked
            background-color: var(--color-primary)
            border-color: var(--color-primary)

        &[type=checkbox]
            border-radius: .2rem

            &:checked
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>")
                background-size: .6em
                background-position: center

        &[type=radio]
            border-radius: 5.0rem

            &:checked::before
                content: ""
                display: inline-block
                width: 0.6em
                height: 0.6em
                border-radius: 5.0rem
                background-color: white
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)

        &:focus
            outline: none
            border-color: var(--color-primary)

    &__check
        display: block
        padding-left: 1.5em
        margin-bottom: 10px

        .filter-bar__label
            margin-bottom: 0
            color: var(--color-neutral)
            font-weight: 300

        .filter-bar__input
            float: left
            margin-left: -1.5em
            margin-top: 2px

    &__back
        appearance: none
        padding: 0
        border: none
        background-color: transparent
        display: flex
        align-items: center
        gap: 12px
        text-align: left
        line-height: 1.2
        cursor: pointer
        transition: all .15s
        max-width: 100%

        .card__icon-wrapper
            transition: all .15s

        &:hover
            .card__icon-wrapper
                box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.2)

        &--disabled
            opacity: .5

        .card__icon-wrapper, svg
            flex-shrink: 0

    &__back-container
        display: flex
        flex-direction: column
        gap: 4px
        flex-grow: 1
        flex-shrink: 1
        min-width: 40px

    &__title
        @extend .u-text-truncate
        color: var(--color-primary-dark)
        font-weight: 800
        font-size: 1.6rem
        margin-bottom: 0

    &__description
        color: var(--color-neutral)
        font-size: 1.2rem

    &__img
        width: 44px
        height: 44px
        border-radius: 50px
        flex-grow: 0

        path
            fill: var(--color-primary-light)

    &__box
        @extend .u-box
        font-size: 1.2rem
        padding: 12px 16px
        display: flex
        align-items: flex-start
        gap: 12px

        a
            text-decoration: underline

    &__icon
        flex-shrink: 0
        display: inline-block
        background-color: #FDB462
        line-height: 1
        color: #fff
        padding: 4px
        border-radius: 50px

        svg
            width: 16px

    > .btn:first-of-type
        margin-left: auto

    &__actions
        display: flex
        align-items: flex-end
        flex-wrap: wrap
        gap: 20px

    &__alert
        max-width: 100%
        background: #FFF
        box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
        border-radius: 5px
        color: var(--color-neutral-dark)
        margin-bottom: 0
        padding: 12px
        display: flex
        align-items: center
        gap: 12px

        &-icon
            flex-shrink: 0
            display: flex
            align-items: center
            justify-content: center
            width: 32px
            height: 32px
            border-radius: 50px
            background-color: var(--color-danger)
            color: #fff
            svg
                min-width: 20px

            &--warning
                background-color: var(--color-warning)
