.calendar-task-modal
  border-radius: 4px
  padding: 16px 36px 36px 36px
  position: relative

  &--discuss
    border-left: 10px solid #FDB221

  &--revision
    border-left: 10px solid #B622FC

  &--learn
    border-left: 10px solid #21C9FE

  &--train
    border-left: 10px solid #E95852

  &__type
    font-weight: 300
    font-size: 1.4rem
    text-align: right
    color: var(--color-neutral)
    margin-bottom: 4px

  &__header
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 8px
    padding-bottom: 1em
    font-weight: 700
    position: relative

  &__delete-icon
    svg
      stroke: var(--color-danger)

  &__check-input
    appearance: none
    margin-right: .5em
    width: 25px
    height: 25px
    border: 1px solid var(--color-neutral)
    border-radius: 50%
    background-color: var(--color-primary-lightest)
    transition: all ease-in-out .2s
    background-size: 16px auto
    background-position: center
    cursor: pointer

    &:checked
      border-color: var(--color-secondary)
      background-color: var(--color-secondary)
      background-repeat: no-repeat
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>")
      background-size: 16px auto

  &__category
    font-weight: 300
    font-size: .8em
    padding: .3em 1em
    color: white
    border-radius: 50px
    margin-right: .3em

    &--discuss
      background-color: #FDB221

    &--revision
      background-color: #B622FC

    &--learn
      background-color: #21C9FE

    &--train
      background-color: #E95852

  &__body
    font-family: $text
    color: var(--color-neutral-dark)
    font-size: 1.4rem
    border-bottom: 1px solid var(--color-bright)
    padding-bottom: 24px
    margin-bottom: 24px
  label
    margin: 0

  &__description
    font-family: $text
    color: var(--color-neutral-dark)
    background: var(--color-bright)
    padding: 12px !important
    border-radius: 5px
    font-size: .9em
    width: 100%
    border: 1px solid var(--color-neutral-dark)
    resize: none
    box-shadow: none

    &:focus
      border: 1px solid var(--color-neutral-dark) !important

    &::placeholder
      color: var(--color-neutral)

  &__footer
    display: flex
    align-items: center
    flex-wrap: wrap
    gap: 20px

  &__footer-instructions
    font-size: 1.4rem
    color: var(--color-neutral)
    margin-bottom: 4px
