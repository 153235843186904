.section-article
  display: flex
  padding: 32px
  flex-direction: column
  gap: 20px
  border-radius: 10px
  background: #fff
  box-shadow: 0px 4px 10px 3px rgba(93, 106, 134, 0.20)

  &__hat, &__title
    margin-bottom: 0

  &__hat
    color: var(--color-neutral, #8894AC)
    font-size: 1.4rem
    font-weight: 700

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 2.4rem
    font-weight: 700

  &__content
    > *
      color: var(--color-neutral-darker)

    > p:not(.section-article__hat), > ul
      margin-bottom: 3.2rem

    img
      width: 512px
      border-radius: 10px

    ul
      list-style: disc
      padding-left: 2rem

  &__footer
    position: fixed
    right: 32px
    bottom: 32px
    background: #fff
    border-radius: 100px
