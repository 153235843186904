.concept-to-characteristic-container
  display: flex
  flex-direction: column
  align-items: center
  gap: 40px
  padding: 15px
  padding-top: 30px
  margin-bottom: 3.2rem

.concept-container
  position: relative

.concept-card
  background-color: #fff
  color: #000
  text-align: center
  font-size: 1.8rem
  font-weight: 700
  padding: 16px
  border-radius: 12px
  cursor: grab
  transition: transform 0.3s
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
  z-index: 2
  border: 1px solid var(--color-primary-light, #7B63BF)
  border-top-width: 8px
  width: 280px
  max-width: 100%
  min-height: 170px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  p
    margin: auto 0

  &-icon
    height: 16px
    color: var(--color-neutral)

.characteristics-container
  display: flex
  flex-wrap: wrap
  justify-content: center
  gap: 40px

.characteristic-card
  cursor: default
  background-color: var(--color-bright)
  border: 2px dashed var(--color-neutral, #8894AC)
  border-radius: 12px
  width: 280px
  max-width: 100%
  min-height: 170px
  display: flex
  align-items: center
  justify-content: center
  transition: background-color 0.3s, border 0.3s
  padding: 16px
  color: var(--color-neutral-darker, #5D6A86)
  text-align: center
  font-size: 1.4rem
  font-weight: 300

  &:-moz-drag-over
    background-color: yellow

  &.stacked
    background-color: #4caf50
    color: white
    border: 2px solid #4caf50
    position: relative
    top: -30px

.concept-card.shake
  animation: shake 0.5s

@keyframes shake
  0%,
  100%
    transform: translateX(0)

  20%,
  60%
    transform: translateX(-10px)

  40%,
  80%
    transform: translateX(10px)

.hidden
  display: none

.final-congrats-message
  color: #4caf50
  margin-top: 10px
  width: 100%
  padding: 20px
  text-align: center

  & > h2
    color: #110B3E
    font-size: 24px
    font-weight: 700
  & > p
    color: #5D6A86
  &
    background: #F1F5FA
    color: #4caf50

.characteristic-card
  transition: background-color 0.3s ease

.highlight-dropzone
  background-color: #555
  color: white /* Darker shade when hovered over */
