.review-card
  display: flex
  padding: 20px
  flex-direction: column
  align-items: center
  gap: 16px
  border-radius: 10px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: linear-gradient(180deg, #DDFAFF 0%, #E2F8FF 44%, #FFF8E1 87.5%)
  box-shadow: 0px 4px 10px 0px rgba(93, 106, 134, 0.05)
  text-align: center
  transition: .16s

  &:hover, &:focus
    box-shadow: 0px 4px 10px 0px rgba(93, 106, 134, 0.16)

  &__img
    max-height: 72px

    &-wrapper
      border-radius: 100px
      background: #fff
      width: 104px
      aspect-ratio: 1/1
      display: flex
      justify-content: center
      align-items: center
      position: relative

      &--sm
        width: 80px

  &__title, &__text
    margin-bottom: 0
    line-height: 1

  &__title
    color: var(--brand-primary-dark, #110B3E)
    font-size: 2.4rem
    font-weight: 700

  &__text
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.6rem
    font-weight: 700

  &__tag
    color: #fff
    font-size: 1.2rem
    font-weight: 700
    background: var(--color-neutral)
    padding: 2px 4px
    border-radius: 2px
    position: absolute
    left: -4px
    top: -8px

    &--danger
      background: var(--color-danger)

    &--third
      background: var(--color-third)

    &--warning
      background: var(--color-warning)

    &--success-light
      background: var(--color-success-light)

    &--success
      background: var(--color-success)
