.duel-card {
  position: relative;
  padding: 36px 24px 24px 24px !important;

  &__tag {
    display: inline-flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 6px 6px;
    border: 2px solid #fff;
    border-top: 0;
    background: #ffddde;
    box-shadow: 0px 4px 10px 0px rgba(68, 43, 140, 0.05);
    color: #ff4c6f;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &__title {
    text-align: center;
    font-size: 1.8rem;
    color: var(--color-primary-dark);
  }

  &__images {
    position: relative;
    height: 90px;
    width: 100%;
    margin: 32px 0;

    &-1 {
      width: 60px !important;
      height: 60px !important;
      position: absolute;
      bottom: 0;
      left: 20px;
      border: none !important;
    }

    &-2 {
      width: 90px !important;
      height: 90px !important;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      border: none !important;
    }

    &-3 {
      width: 60px !important;
      height: 60px !important;
      position: absolute;
      bottom: 0;
      right: 20px;
      border: none !important;
    }
  }

  p {
    color: var(--color-neutral-dark);
    text-align: center;
    font-weight: 700;
    font-size: 1.2rem;
    margin: 12px 0;
  }

  button {
    color: #fff;
    margin-top: auto;
    padding: 0.6em 1em !important;
    height: 36px !important;
  }
}
