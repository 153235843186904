.guide-card
  display: flex
  flex-wrap: wrap
  align-items: flex-start
  gap: 12px
  padding: 16px
  border-radius: 20px
  border: 2px solid var(--color-neutral-lightest, #ECE9F8)
  background: #fff
  cursor: pointer
  position: relative
  transition: .16s all

  &:hover, &:focus
    box-shadow: 0px 4px 10px 3px rgba(93, 106, 134, 0.20)

  &--is-current
    border-color: #F99153
    border-width: 1px
    background: linear-gradient(9.59deg, #FFF 20.02%, #F5F5F5 85.13%)
    box-shadow: 0px 4px 10px 0px rgba(93, 106, 134, 0.05)
    padding: 32px 16px

  &__check
    flex-shrink: 0
    appearance: none
    margin: 0
    width: 20px
    height: 20px
    border: 1px solid var(--color-neutral-light)
    border-radius: 50px
    background-color: #fff
    transition: all ease-in-out .2s
    background-position: center
    cursor: pointer
    outline: none

    .guide-card--is-current &
      margin-top: 4px

    &:checked
      border-color: var(--color-success)
      background-color: var(--color-success)
      background-repeat: no-repeat
      background-position: center
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>")
      background-size: 12px auto
      outline: none

  &__content
    flex-grow: 1
    display: flex
    flex-direction: column
    gap: 8px
    max-width: 100%

  &__hat
    color: var(--color-neutral-darker, #5D6A86)
    font-size: 1.2rem
    font-weight: 300
    width: 100%
    margin-bottom: 0

  &__title
    margin-bottom: 0
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.6rem
    font-weight: 700

    .guide-card--is-current &
      font-size: 2.4rem

  &__time
    flex-shrink: 0
    align-self: center
    color: var(--color-neutral-light)
    display: flex
    align-items: center
    gap: 4px

  &__icon
    width: 20px
