.profile-challenge
  display: flex
  flex-direction: column
  gap: 8px
  width: 204px
  min-width: 204px
  padding: 20px
  border-radius: 10px
  background: #FFF
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)

  .avatar
    margin: 0 auto

  &__header
    display: flex
    flex-direction: column
    gap: 8px
    margin-bottom: 20px

  &__hat, &__title
    margin-bottom: 0

  &__hat
    color: var(--color-neutral, #8894AC)
    font-size: 1.2rem
    font-weight: 300

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.4rem
    font-weight: 700

  &__body
    flex-grow: 1
    display: flex
    flex-direction: column
    gap: 4px
