.check-card
  padding: 20px
  background: #fff
  box-shadow: $dropshadow
  border-radius: 10px
  margin-bottom: 20px

  &__header
    display: flex
    align-items: center
    gap: 12px
    margin-bottom: 16px

  &__icon
    flex-shrink: 0
    display: inline-flex
    align-items: center
    justify-content: center
    color: var(--color-primary)
    background: var(--color-primary-lightest)
    width: 36px
    height: 36px
    border-radius: 50px
    svg
      width: 16px

  &__inner-header
    flex-grow: 1

  &__title
    color: var(--color-primary-dark)
    font-weight: 700
    font-size: 1.4rem
    margin-bottom: 0

  &__subtitle
    color: var(--color-neutral)
    font-weight: 300
    font-size: 1.2rem
    margin-bottom: 0

  &__check-wrapper
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr))
    gap: 0 20px

    &--with-icon
      margin-left: 48px
