.guide-card-dashboard-wrapper
    margin-bottom: 40px
    display: flex
    flex-wrap: wrap
    gap: 16px

.guide-card-dashboard
    flex-grow: 1
    display: flex
    flex-wrap: wrap-reverse
    align-items: center
    justify-content: space-between
    gap: 24px
    border-radius: 12px
    border: 2px solid var(--color-neutral-lightest, #ECE9F8)
    background: #fff
    cursor: pointer
    padding: 24px
    width: 220px
    max-width: 100%
    transition: .16s

    &:hover, &:focus
        box-shadow: 0px 4px 10px 3px rgba(93, 106, 134, 0.20)

    &__content
        flex-grow: 1
        display: flex
        flex-direction: column
        gap: 4px
        width: 220px
        max-width: 100%

    &__header
        display: flex
        flex-direction: column

    &__hat, &__title, &__text
        margin-bottom: 0

    &__hat
        @extend .u-text-truncate
        color: var(--color-neutral-darker, #5D6A86)
        font-size: 1.2rem
        font-weight: 300
        width: 100%

    &__title
        @extend .u-text-truncate
        color: var(--color-primary-dark, #110B3E)
        font-size: 1.6rem
        font-weight: 700
        width: 100%
        text-transform: lowercase
        &::first-letter
            text-transform: uppercase

    &__text
        color: var(--color-neutral-darker, #5D6A86)
        font-size: 1.4rem
        font-weight: 300

    &__img-wrapper
        flex-shrink: 0
        position: relative

    &__live-dot
        position: absolute
        right: -8px
        top: -8px
        width: 15px
        height: 15px
        border-radius: 50%
        background-color: var(--color-success)
        animation: pulse 2s infinite

    &__img
        width: 54px
        height: 54px
        object-fit: contain

@keyframes pulse
    0%
        opacity: 0
    50%
        opacity: 1
    100%
        opacity: 0
