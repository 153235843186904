.questions-sidebar
    font-size: 13px
    display: none
    gap: 1em
    +bp(md)
      display: block
      +g(full)
      +i(1/1)
    p
        margin-bottom: 0

    &__body
        +g(full)
        +i(1/1)
        gap: 1em

    &__section
        background-color: white
        border-radius: 10px
        padding: 2em
        border: 1px solid var(--color-neutral-lightest)
        box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
        +i(1/1)

    &__title
        color: var(--color-primary-dark)
        font-weight: 800
        margin-bottom: 20px
        font-size: 16px

        span
            font-weight: 300
            font-size: 13px
            color: var(--color-neutral)

    &__highlight
        color: var(--color-neutral-darker)
        background-color: var(--color-neutral-lightest)
        border-radius: 5px
        padding: 1em
        border: 1px solid var(--color-neutral-lighter)
        box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)


    &__time
        +g(full, middle, space-between)
        margin-bottom: 1em
    &__clock
        font-size: 16px
        font-weight: 800
        color: var(--color-primary)
        +g(full, center, middle)
        margin-bottom: .2em

        svg
          height: 20px
          width: 20px
          margin-right: .3em


    &__chart
        margin-bottom: 1em
        display: flex
        align-items: center

    &__chart-container
        .highcharts-container, .highcharts-root
            width: 80px !important
            height: 80px !important

    &__legends
        flex-grow: 1

    &__legend
        color: var(--color-neutral-darker)
        margin-bottom: 0

    &__count
        font-weight: 800
        &--success
            color: var(--color-secondary)
        &--error
            color: var(--color-danger)

    &__yesterday
        background: white
        box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
        border-radius: 12px
        padding: 12px
        color: var(--color-neutral)
        margin-bottom: 24px

        strong
            color: var(--color-primary-dark)

        p
            margin-bottom: 0

        .questions-sidebar__title
            font-size: 13px

    &__footer
        +i(1/1)
