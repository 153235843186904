.register
	background-image: url('../../images/login-bg.svg')
	background-size: cover
	background-position: center
	min-height: 100vh
	+g(center, middle)

	&__body
		+i(10/20, md 4/12)
		padding: 1em
		color: white
		max-width: 550px
		box-shadow: $dropshadow
		+bp(md)
			padding: 5em
			display: block

	&__item
		margin: 3em 0
		margin-left: 2em
		font-size: .9em

	&__title
		color: var(--color-secondary)
		font-size: 1.4em
		font-weight: 700
		margin-bottom: .5em
		position: relative
		&:after
			width: 15px
			height: 15px
			content: ""
			position: absolute
			left: -25px
			background: var(--color-primary-dark)
			border-radius: 30px
			top: 3px

	&__form
		padding: 2em
		+i(17/20, md 4/12)
		background: white
		border-radius: 20px
		margin-bottom: 2em
		+bp(md)
			padding: 5em

	&__brand

		img
			width: 150px
			+bp(md)
				margin-bottom: 2em
				width: 200px

	&__container
		+i(7/20)
		background: white
		border-radius: 20px
		box-shadow: $dropshadow
		padding: 4em
		padding-bottom: 10em
		position: relative
		overflow: hidden

	&__list
		display: none
		+bp(md)
			display: block

	&__footer
		+g(center)
		background: var(--color-secondary)
		color: white
		position: absolute
		left: 0px
		right: 0px
		bottom: 0px
		padding: 4em
		a
			color: var(--color-primary)
			font-weight: 800
			margin-left: .5em
