.round-dg
    font-size: 1.2rem
    min-width: 100%
    width: 100%
    color: var(--color-neutral)
    border-collapse: separate
    table-layout: fixed
    border-spacing: 0 .8em

    .c-dropdown
        background-color: var(--color-primary-lightest)
        border-radius: 5px
        transition: all ease-in-out .2s
        &:hover
            background-color: var(--color-primary-lighter)
            svg
                stroke: white

    .positive-class
        color: var(--color-success)
        font-weight: 700

    .negative-class
        color: var(--color-danger)
        font-weight: 700

    .blank-class
        font-weight: 700

    &-wrapper
        overflow-x: auto
        max-width: 100%

        &--modal
            height: 200px
            flex-grow: 1

    &__row
        border-radius: 10px

        &--clickable
            cursor: pointer

        &--highlight
            .round-dg__cell, .round-dg__cell-header, .round-dg__title
                background-color: var(--color-primary-light) !important
                color: #fff

            .card__icon
                color: #fff

        &--last
            .c-dropdown__content
                top: auto
                bottom: 100%

        &--danger
            .round-dg__cell, .round-dg__cell-header
                background-color: var(--color-danger-lightest) !important
                border-color: var(--color-danger-lighter) !important
            .btn
                background-color: #fff

    &__body &__row > &__cell, &__body &__row > &__cell-header
        background-color: #F1F5FA
        padding: 12px 20px
        font-size: 1em
        border-top: 1px solid var(--color-neutral-lightest)
        border-bottom: 1px solid var(--color-neutral-lightest)
        box-shadow: 2px 2px 2px 1px rgba(68, 43, 140, 0.03)

        &:first-child
            border-left: 1px solid var(--color-neutral-lightest)

        &:last-child
            border-right: 1px solid var(--color-neutral-lightest)

        .round-dg--light &
            background-color: #fff
            background-clip: padding-box

    &__body &__row
        & > :first-child
            border-top-left-radius: 10px
            border-bottom-left-radius: 10px
        & > :last-child
            border-top-right-radius: 10px
            border-bottom-right-radius: 10px

    &__row > &__cell:last-child
        width: 20px

    &__cell-header, &__cell
        text-align: left
        padding: 0 20px
        vertical-align: middle
        // word-break: break-word

        > *
            vertical-align: middle

    &__cell-header
        color: var(--color-neutral)
        font-weight: 700
        font-size: 1.2rem

        &--row
            text-align: left

        &--sm
            padding-right: 6px !important
            &:not(:first-of-type)
                padding-left: 6px !important

        &--wrap
            word-wrap: break-word

    &__cell
        color: var(--color-neutral-dark)
        font-weight: 300
        font-size: 1.4rem

        &--wrap
            word-wrap: break-word

        &--no-wrap
            white-space: nowrap

        &--sm
            padding-left: 6px !important
            padding-right: 6px !important
            &:last-of-type
                padding-right: 20px !important

        &--text-right
            text-align: right

        &.danger
            color: var(--color-danger)
            font-weight: 700

        .expanded-calendar__tag
            display: inline-flex

    &__avatar
        @extend .students-card__img
        width: 32px
        height: 32px
        margin-right: 8px

    &__user
        display: flex
        align-items: center
        gap: 12px
        max-width: 100%
        min-width: 100px

        .avatar
            flex-shrink: 0
            min-width: 24px

    &__user-inner
        width: 0
        flex-grow: 1
        gap: .2em
        display: flex
        align-items: center
        flex-wrap: wrap

    &__title
        @extend .u-text-truncate
        color: var(--color-primary-dark)
        font-weight: 700
        font-size: 1.2rem
        width: 100%
        line-height: 1.5
        margin-bottom: 0

    &__subtitle
        @extend .u-text-truncate
        margin-bottom: 0
        color: var(--color-neutral)
        font-weight: 300
        font-size: 1.2rem
        line-height: 1.5
        *
            margin-bottom: 0

    .card__header, .card__badges
        margin-bottom: 0

    &__flex
        display: flex
        align-items: center
        gap: 1em

.last-cell
    text-align: right
