.questions-options
	+i(9/10, md 3.5/20)
	position: fixed
	bottom: 0px
	+bp(md)
		top: 7em
		right: 3em
		padding-bottom: 1em
		overflow: scroll
		&::-webkit-scrollbar
			display: none

	select
		margin-bottom: .5em
		cursor: pointer

	&__filter
		margin-bottom: 1em

	&__results
		box-sizing: border-box
		border-radius: 5px
		padding: 1.5em 1.5em 1em
		background: var(--color-primary)
		color: white
		box-shadow: $dropshadow
		margin-bottom: 1em
		display: none
		+bp(md)
			display: block

		.btn
			margin-top: 1em

	b
		+i(1/1)
		font-size: 1em
		margin-bottom: .5em
