.panorama
  display: flex
  padding: 40px clamp(16px, 3.2vw, 40px)
  flex-direction: column
  gap: 32px
  border-radius: 12px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: #fff
  box-shadow: 0px 4px 10px 3px rgba(93, 106, 134, 0.20)
  position: relative

  &__header
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 16px

  &__title
    flex-grow: 1
    margin-bottom: 0
    color: var(--color-primary-dark, #110B3E)
    font-size: 3.2rem
    font-weight: 700

  &__body
    display: flex
    flex-direction: column
    gap: 40px
    position: relative

  &__bg
    width: 224px
    position: absolute
    top: 166px
    bottom: 920px
    @media (min-width: $md)
      bottom: 580px

  &__line
    width: 100%
    height: 100%
