.badge
    display: inline-block
    cursor: default
    background-color: var(--color-bright)
    border-radius: 5.0rem
    line-height: 2.2rem
    padding: 0 1.2rem
    font-weight: 700
    font-size: 1.2rem
    color: var(--color-neutral)
    @extend .u-text-truncate

    &--small
        line-height: 2rem
        padding: 0 8px
        &.badge--circle
            width: 1.6rem
            height: 1.6rem
            line-height: 1.6rem
            vertical-align: bottom
            padding: 0

    &--tiny
        line-height: 1.6rem
        padding: 0 8px
        font-weight: 300

    &--square
        width: 26px
        padding: 0
        text-align: center
        cursor: pointer

    &--redaction
        text-align: center
        font-size: 1.3rem
        padding: 7px 7px
        border-radius: 1.0rem
        background-color: var(--color-bright)
        flex-grow: 1
        height: 80px
        display: flex
        align-items: center
        justify-content: center

        strong
            font-size: 1.8rem

    &--t-2
        text-transform: none
        display: inline-block
        background-color: #83919E
        border-radius: 2rem 5.0rem 5.0rem 2rem
        padding: 0 1.4rem 0 .8rem
        font-weight: 700
        font-size: 1rem
        color: white

    &--primary
        background-color: var(--color-primary-light)
        color: white

    &--secondary
        background-color: var(--color-secondary)
        color: white

    &--success
        background-color: #23CA88
        color: white
    &--attention
        background-color: #4551AD
        color: white

    &--danger
        background-color: var(--color-danger)
        color: white

    &--warning
        background-color: #FDB462
        color: white

    &--info
        background-color: #32C9D3
        color: white

    &--neutral
        background-color: var(--color-neutral)
        color: white

    &--finished
        white-space: normal
        display: block
        width: fit-content
        margin-top: 0.8rem

    &--more
        background-color: var(--color-primary-lightest)
        color: var(--color-primary)
        cursor: help
        flex-shrink: 0

    &__btn
        appearance: none
        border: none
        padding: 0
        background-color: transparent
        color: white
        margin-left: 1rem
        cursor: pointer

        svg
            width: 1.2rem
            vertical-align: middle
            position: relative
            bottom: .1rem

    .form__row & + &
        margin-left: .2em
