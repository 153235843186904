.message-card
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 12px
  width: 100px
  flex-grow: 1
  @media (min-width: $xl)
    flex-direction: row

  &-wrapper
    display: flex
    align-items: flex-start
    gap: 24px
    padding: 16px
    border-radius: 12px
    background-color: #fff
    transition: .15s

    &:hover
      box-shadow: $dropshadow-md

  &__actions
    flex-shrink: 0
    display: flex
    flex-direction: column
    gap: 12px

  &__icon
    width: 20px
    color: var(--color-primary)
    cursor: pointer

    &--danger
      color: var(--color-danger)

  &__user
    flex-shrink: 0
    display: flex
    flex-direction: row
    gap: 8px
    @media (min-width: $xl)
      flex-direction: column
      width: 112px

    .avatar
      flex-shrink: 0

  &__info
    display: flex
    flex-direction: column

  &__name
    @extend .u-text-truncate
    min-width: 100%
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.4rem
    font-weight: 700
    margin-bottom: 0

  &__date-time
    color: var(--color-neutral, #8894AC)
    font-size: 1.2rem
    font-weight: 700
    margin-bottom: 0

  &__inside
    width: 200px
    flex-grow: 1
    word-wrap: break-word
    i
      font-style: italic
    ul
      list-style: disc
    ol
      list-style: decimal
    blockquote
      border-left: 5px solid #ccc
      font-style: italic
      margin-left: 0
      margin-right: 0
      overflow: hidden
      padding-left: 1.5em
      padding-right: 1.5em

  &__text
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.5rem
    margin-bottom: 0

  &__file
    display: flex
    align-items: center
    gap: 8px
    padding: 8px

    &-thumb
      width: 44px
      height: 32px
      object-fit: cover
      border-radius: 4px
      border: 1px solid var(--color-neutral-lightest, #ECE9F8)

    &-content
      width: 60px
      flex-grow: 1
      display: flex
      flex-direction: column

    &-title
      @extend .u-text-truncate
      color: var(--color-primary-dark, #110B3E)
      font-size: 1.2rem
      font-weight: 700
      margin-bottom: 0

    &-meta
      @extend .u-text-truncate
      color: var(--color-neutral, #8894AC)
      font-size: 1.2rem
      margin-bottom: 0
      svg
        width: 16px
