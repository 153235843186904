.activity-log
  background-color: #fff
  box-shadow: $dropshadow
  padding: 20px
  border-radius: 12px
  list-style: none
  +g(full)

  &__header
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    gap: 16px
    margin-bottom: 16px
    width: 100%

  &__header-title
    margin-bottom: 0
    font-weight: 700
    font-size: 1.6rem
    line-height: 1.5
    color: var(--color-primary-dark)

  &__count
    margin-bottom: 0
    background-color: var(--color-bright)
    padding: 10px
    border-radius: 4px
    color: var(--color-neutral)
    font-weight: 300
    font-size: 1.4rem
    strong
      color: var(--color-neutral-dark)

  .infinite-scroll-component
    overflow-x: hidden !important

    &__outerdiv
      width: 100%
      max-width: 100%

  &__item
    border-left: 1px solid transparent
    margin-left: 20px
    padding: 1em
    padding-left: 40px
    position: relative
    border-radius: 10px
    width: 100%
    transition: all ease-in-out .2s
    &:hover
      box-shadow: 0 3px 5px rgba(black, .07)
      background-color: var(--color-neutral-bright)

    &:not(:last-of-type)
      margin-bottom: 1em
      &::after
        transform: translateX(-1px)
        content: ""
        top: 0px
        bottom: -1em
        left: 0px
        width: 1px
        position: absolute
        border-left: dashed 1px var(--color-neutral-lighter)
        z-index: 0

    > :last-child
      margin-bottom: 0

    &.is-read
      opacity: .4
      transition: .15s

      &:hover
        opacity: 1

  &__datetime
    font-weight: 300
    font-size: 1.2rem
    color: var(--color-neutral)

  &__icon
    color: var(--color-primary)
    background-color: var(--color-primary-lightest)
    width: 40px
    height: 40px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50px
    position: absolute
    left: 0
    top: 0
    transform: translateX(-50%)
    z-index: 1
    svg
      width: 16px

    &--danger
      color: var(--color-danger)
      background-color: var(--color-danger-lightest)

    &--trophy
      left: auto
      top: 1em
      right: 1em
      border-radius: 0
      width: 16px
      height: 16px
      background-color: transparent

  &__title
    margin-bottom: 0
    font-weight: 700
    color: var(--color-primary-dark)
    font-size: 1.4rem
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 8px

  &__tag
    @extend .u-text-truncate
    display: inline-block
    font-size: 1.4rem
    font-weight: 300
    color: var(--color-neutral-dark)
    flex-grow: 0
    background-color: #ccc
    padding: 0 4px

    &--course
      background-color: #D6FDFF

    &--class
      background-color: #FFFCBE

    &--individual
      background-color: #EFEAFF

  &__description
    color: var(--color-neutral-dark)
    font-size: 1.4rem
    font-weight: 300

  &__link
    color: var(--color-primary)
    font-weight: 700
    text-decoration: underline

    &--danger
      color: var(--color-danger)

    &:hover
      text-decoration: none
