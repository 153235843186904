.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 32px;
  border-top: 1px solid var(--color-neutral-lightest);
}

.id {
  display: inline-flex;
  padding: 1px 3px;
  align-items: center;
  border-radius: 2px;
  background: var(--color-bright, #f1f5fa);
  color: var(--color-neutral-darker, #5d6a86);
  font-size: 1.2rem;
  font-weight: 300;
}

.cell {
  color: var(--color-primary-dark, #110b3e);
  font-size: 1.4rem;
  font-weight: 700;

  &.primary {
    color: var(--color-primary, #110b3e);
  }
}

.content {
  display: flex;
  padding: 12px;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  background: var(--color-neutral-bright, #fafafa);
  font-size: 1.2rem;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.title,
.text {
  margin-bottom: 0;
  color: var(--color-neutral-darker, #5d6a86);
}

.title {
  font-weight: 700;
}

.text {
  font-weight: 300;
}
