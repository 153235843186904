.study-block-page
  display: flex
  flex-wrap: wrap
  gap: 24px

  &__col
    width: min(100%, 300px)
    flex-grow: 1

  &__aside
    width: min(100%, 344px)
    flex-shrink: 0
    display: flex
    flex-direction: column
    gap: 16px

  &__materials
    height: 132px
    flex-grow: 1
    overflow-y: auto