.report-filter
  display: inline-flex
  flex-direction: column
  gap: 16px 4px
  max-width: 100%
  @media (min-width: $sm)
    flex-direction: row
    align-items: center

  &__inner
    display: inline-flex
    flex-direction: column
    max-width: 100%
    @media (min-width: $sm)
      flex-direction: row
      align-items: center

  > &__toggle
    border-top-right-radius: 100px
    border-bottom-right-radius: 100px

  &__toggle
    appearance: none
    cursor: pointer
    height: 40px
    max-width: 100%
    border-width: 1px
    border-style: solid
    border-color: var(--color-neutral-lightest)
    border-top-left-radius: 20px
    border-top-right-radius: 20px
    border-bottom-left-radius: 20px
    border-bottom-right-radius: 20px
    padding: 10px 20px
    font-size: 1.2rem
    background-color: var(--color-bright)
    color: var(--color-neutral-dark)
    transition: background-color .16s
    flex-shrink: 0
    display: inline-flex
    align-items: center
    gap: 16px
    @media (min-width: $sm)

    &:hover, &:focus
      background-color: #fff

    svg
      flex-shrink: 0
      width: 18px

  &--is-open
    .report-filter__toggle
      background-color: #fff
      color: var(--color-primary-dark)
      border-bottom-right-radius: 0
      border-bottom-left-radius: 0
      border-bottom-width: 0
      @media (min-width: $sm)
        border-bottom-width: 1px
        border-top-right-radius: 0
        border-bottom-right-radius: 0
        border-bottom-left-radius: 20px
        border-right: 0

  &__options
    flex-shrink: 1
    display: inline-flex
    flex-direction: column
    border-width: 1px
    border-style: solid
    border-color: var(--color-neutral-lightest)
    border-top-width: 0
    border-bottom-left-radius: 20px
    border-bottom-right-radius: 20px
    @media (min-width: $sm)
      flex-direction: row
      align-items: center
      border-left: 0
      border-top-width: 1px
      border-bottom-left-radius: 0
      border-top-right-radius: 20px

  &__btn
    appearance: none
    cursor: pointer
    height: 38px
    max-width: 100%
    padding: 0 24px
    font-size: 1.2rem
    background-color: #fff
    color: var(--color-neutral-dark)
    transition: .16s
    flex-shrink: 0
    display: inline-flex
    align-items: center
    justify-content: center

    &:last-of-type
      border-bottom-left-radius: 20px
      border-bottom-right-radius: 20px
      @media (min-width: $sm)
        border-bottom-left-radius: 0
        border-top-right-radius: 20px

    &:hover, &:focus
      background-color: var(--color-bright)

  &__value-container
    position: absolute
    top: calc(100% + 20px)
    width: 100%
    max-width: 100%
    overflow-x: auto
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 12px

  &__badge
    flex-shrink: 0
    display: inline-flex
    align-items: center
    gap: 8px
    padding: 4px 8px
    border-radius: 4px
    color: #fff
    font-size: 1.2rem
    font-weight: 700
    background-color: var(--color-success)
    cursor: default
    transition: .16s

    &:hover, &:focus
      color: var(--color-neutral-dark)
      background-color: var(--color-bright)

    &-icon
      width: 16px
      flex-shrink: 0
      cursor: pointer
