.login
	background-image: url($bg-login)
	background-size: cover
	min-height: 100vh
	padding: 20px
	+g(center, middle)

	&__screen
		+i(1/1)
		+g(full, center)

	&__brand
		+i(1/1)
		+g(full, center)
		margin-bottom: 2em

		img
			width: 120px
			+bp(md)
				width: 150px

	&__container
		+i(16/20, md 7/20)
		background: white
		border-radius: 20px
		box-shadow: $dropshadow
		padding: 2em
		padding-bottom: 6em
		position: relative
		overflow: hidden
		+bp(md)
			padding: 4em
			padding-bottom: 10em

	&__footer
		+g(center, middle)
		background: var(--color-secondary)
		color: white
		position: absolute
		left: 0px
		right: 0px
		bottom: 0px
		padding: 2em
		+bp(md)
			padding: 4em
		a
			color: var(--color-primary)
			font-weight: 800
			margin-left: .5em
