.post-form
	+i(1/1)
	+g(space-between)
	border-radius: 10px
	textarea
		+i(10/12, md 11/12)
		min-height: 80px
		margin-bottom: 1.5em

	.btn.loading
		animation: spin 4s linear infinite

	.magic-link
		@extend .fadeIn
		border-radius: 10px
		overflow: hidden
		transition: all ease-in-out .2s
		margin: -1em 0em 1em 0em
		background: var(--color-primary-lightest)
		&__body
			padding: 1em
			padding-bottom: 0em
			margin-bottom: 1em

.comment-form
	+i(1/1)
	input
		+i(1/1)
		background: var(--color-bright)
		font-size: 1.6rem
	.textarea-instructions
		+i(1/1)
		text-align: right
		font-size: .8em
		padding-left: .3em
		color: var(--color-neutral)

@keyframes spin
	100%
		transform: rotate(360deg)
