.player-modal {
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__section {
    flex-grow: 1;
    overflow: hidden;
    position: relative;
    overflow-y: auto;

    &--name {
      background: var(--color-bright);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      padding: 0 16px 16px 16px;
      border-radius: 25px;

      .avatar {
        border: 8px solid #fff;
      }
    }

    &--question {
      display: flex;
      padding: 28px max(16px, 2.6vw);
      flex-direction: column;
      gap: 20px;
      background: #fff;
      box-shadow: 0px 4px 10px 0px rgba(119, 119, 120, 0.15);
      animation: ModalSectionIn 0.16s ease-out;
      border-radius: 25px;
    }

    &--result {
      background: var(--color-bright, #f1f5fa);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      padding: 0 8px 8px 8px;
      animation: ModalSectionIn 0.16s ease-out;
    }
  }

  &__hat,
  &__name-section-title,
  &__name-section-subtitle,
  &__warning,
  &__title,
  &__subtitle {
    margin-bottom: 0;
  }

  &__hat {
    text-overflow: ellipsis;
    white-space: nowra;
    display: flex;
    flex-direction: column;
    min-width: 194px;
    max-width: 100%;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 0px 0px 12px 12px;
    border: 2px solid var(--color-neutral-lightest, #ece9f8);
    background: #fff;
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05);
    color: var(--color-primary, #442b8c);
    font-size: 1.4rem;
    font-weight: 700;
  }

  &__infos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 24px;
    margin: 0 auto 32px;

    @media (max-width: 768px) {
      gap: 8px;
    }

    &--values {
      font-size: 2.8rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 32px;
      margin: 0 auto;

      @media (max-width: 768px) {
        gap: 8px;
      }

      > svg {
        color: #f44336;
        width: 30px;
        height: 30px;
        flex: 1;
      }
    }
  }

  &__participant {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #5d6a86;
    z-index: 1;

    &-name {
      font-weight: 700;
      color: var(--color-primary-dark);
      font-size: 1.2rem;
    }

    &-score {
      background-color: var(--color-primary-light);
      padding: 4px 8px;
      border-radius: 4px;
      color: #fff;
      font-weight: 700;
      font-size: 1.2rem;
      border: 2px solid #fff;
    }

    &--img {
      position: relative;
      margin-bottom: 8px;

      &-crown {
        position: absolute;
        top: -20px;
        left: 0;
        transform: rotate(-20deg);
        width: 40px;
        height: 40px;
      }

      &-leaf {
        top: -20px;
        position: absolute;
        height: 120px;
        transform: none;
      }

      &-leaf {
        top: -20px;
        position: absolute;
        height: 120px;
        transform: none;
      }

      &-star {
        &-1 {
          top: -10px;
          left: -20px;
          position: absolute;
          height: 25px;
          width: 25px;
          animation: blink 1.5s infinite;
          animation-delay: 1s;
        }
        &-2 {
          top: 15px;
          left: -25px;
          position: absolute;
          height: 15px;
          width: 15px;
          animation: blink 1.5s infinite;
          animation-delay: 0s;
        }
        &-3 {
          top: -10px;
          right: -20px;
          position: absolute;
          height: 25px;
          width: 25px;
          animation: blink 1.5s infinite;
          animation-delay: 1.5s;
        }
        &-4 {
          top: 15px;
          right: -25px;
          position: absolute;
          height: 15px;
          width: 15px;
          animation: blink 1.5s infinite;
          animation-delay: 0.5s;
        }
      }

      span {
        position: absolute;
        bottom: -15px;
        left: 0;
        right: 0;
        width: 50px;
        margin: 0 auto;
        background-color: var(--color-neutral);
        border: 2px solid #fff;
        padding: 2px 5px;
        border-radius: 4px;
        color: #fff;
        font-weight: 700;
        font-size: 1.2rem;
        text-align: center;
      }

      &__star-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #74dbe1;
        border-radius: 50%;
        padding: 2px;
        border: #74dbe1;
        fill: #fff;
        color: #fff;
        height: 20px;
        width: 20px;
      }
    }
  }

  &__name-section {
    &-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      text-align: center;
    }

    &-title {
      color: var(--color-primary);
      font-size: 2.8rem;
      font-weight: 700;
    }

    &-subtitle {
      color: var(--color-neutral, #8894ac);
      font-size: 1.4rem;
      font-weight: 300;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &-icon {
      flex-shrink: 0;
      width: 16px;
    }
  }

  &__result-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 80%;

    @media (max-width: 768px) {
      width: 100%;
    }

    &--tab-link {
      padding: 0.3em 0.8em;
    }

    &-title {
      text-align: center;
      font-weight: 700;
      font-size: 1.6rem;
      padding: 12px 0;
      background-color: #fafafa;
      border-radius: 10px;
    }

    &-content {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      max-height: 300px;
      overflow-y: auto;
    }

    &-items {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 12px;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: center;

      &-bg {
        display: flex;
        flex-direction: column;
        background-color: #fafafa;
        padding: 8px;
        border-radius: 10px;
        margin-bottom: 12px;

        > div {
          display: grid;
          grid-template-columns: 1fr 1fr;

          @media (max-width: 768px) {
            grid-template-columns: 1fr;
            gap: 12px;
          }
        }

        span {
          width: fit-content;
          margin-left: 24px;
        }

        img {
          height: 25px;
          width: 25px;
          margin-right: 4px;
        }
      }

      span {
        width: fit-content;
        margin-left: 24px;
      }

      img {
        height: 25px;
        width: 25px;
        margin-right: 4px;
      }
    }

    &-link {
      text-decoration: underline;
      color: var(--color-primary);
      text-align: center;
      cursor: pointer;
    }
  }

  &__warning {
    display: inline-flex;
    align-items: center;
    gap: 16px;
    padding: 20px;
    border-radius: 0px 10px 10px 0px;
    background: #fafafa;
    box-shadow: 0px 4px 4px 0px rgba(114, 114, 114, 0.1);
    max-width: 204px;
    color: var(--color-neutral-dark, #5d6a86);
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.2;
    position: absolute;
    left: 0;
    top: 60px;
    animation: fadeInLeft 0.4s ease-out 0.5s 1 normal backwards;

    &-clock-img {
      width: 24px;
    }
    @media (min-width: 768px) {
      top: auto;
      bottom: 60px;
    }
  }

  &__effect-background {
    position: relative;
    height: 220px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    border: 8px solid #fff;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: repeating-conic-gradient(from 0deg, #ffffff 0deg, #ffffff 10deg, #f5f5f5 10deg, #f5f5f5 20deg);
      z-index: 0;
    }

    &-empty {
      background-color: #ccd2e0;
      width: 80px;
      height: 80px;
      font-size: 60px;
      font-weight: 700;
      color: #fff;
      border-radius: 50%;
      z-index: 1;
      text-align: center;
    }
  }
}

@keyframes ModalSectionIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
