.frequent-shortcut-widget
  display: flex
  flex-wrap: wrap
  gap: 12px
  color: #fff
  font-size: 1.4rem
  cursor: pointer

  &-wrapper
    margin-bottom: 40px
    display: flex
    flex-direction: column
    gap: 10px

    &__title
      color: var(--color-neutral-dark, #5D6A86)
      font-size: 1.4rem
      font-weight: 300
      margin-bottom: 0

  &__item
    flex-grow: 1
    width: 120px
    max-width: 100%
    border-radius: 56px
    border: 1.5px solid #FFF
    background-color: var(--color-primary)
    background-clip: padding-box
    box-shadow: $dropshadow
    padding: 10px 16px
    display: flex
    align-items: center
    justify-content: center
    gap: 8px
    transition: .16s

    &:hover, &:focus
      background-color: var(--color-primary-dark)

  &__icon
    flex-shrink: 0
    width: 20px

  &__link-text
    font-weight: 700
