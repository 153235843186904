.feedback
	column-count: 2
	column-gap: 2em
	padding: .5em
	font-size: .8em
	+i(1/1)

	&__item
		+g(full, space-between, middle)
		padding-left: .5em
		&:first-child
		&:nth-child(even)
			background: var(--color-bright)

	&__item-answer
		font-size: .8em

	&__item-response
		font-weight: 800
		width: 50px
		padding-left: .5em
		padding-right: .5em
		&--canceled
			color: var(--color-danger)
			width: 200px
		&.success
			color: var(--color-secondary)

		&.danger
			color: var(--color-danger)

	&__item-response-percentual
		color: var(--color-neutral)
		font-size: .9em
		border-left: 1px solid var(--color-primary-lightest)
		padding: .5em
		padding-right: 1em
