.report-kpi-card
  display: flex
  padding: 20px 20px 32px 20px
  flex-direction: column
  gap: 4px
  flex-grow: 1
  width: 260px
  max-width: 100%
  border-radius: 12px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: #FFF
  position: relative
  background-repeat: no-repeat
  background-position: right 16px bottom
  background-size: 100px

  &--check
    background-image: url("data:image/svg+xml,%3Csvg width='97' height='69' viewBox='0 0 97 69' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M80.8332 23L36.3748 65.1667L16.1665 46' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")

  &--user
    background-image: url("data:image/svg+xml,%3Csvg width='98' height='69' viewBox='0 0 98 69' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M85.7856 85.4283V76.6664C85.7856 72.0188 83.839 67.5615 80.3741 64.2752C76.9091 60.9888 72.2096 59.1426 67.3094 59.1426H30.357C25.4569 59.1426 20.7574 60.9888 17.2924 64.2752C13.8275 67.5615 11.8809 72.0188 11.8809 76.6664V85.4283' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M48.8326 41.6199C59.0368 41.6199 67.3088 33.7742 67.3088 24.0961C67.3088 14.4179 59.0368 6.57227 48.8326 6.57227C38.6285 6.57227 30.3564 14.4179 30.3564 24.0961C30.3564 33.7742 38.6285 41.6199 48.8326 41.6199Z' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")

  &--users
    background-image: url("data:image/svg+xml,%3Csvg width='98' height='69' viewBox='0 0 98 69' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_36379_19711)'%3E%3Cpath d='M69.9693 89.9969V80.219C69.9693 75.0324 68.1878 70.0583 65.0167 66.3909C61.8457 62.7234 57.5449 60.6631 53.0603 60.6631H19.2424C14.7579 60.6631 10.4571 62.7234 7.28601 66.3909C4.11497 70.0583 2.3335 75.0324 2.3335 80.219V89.9969' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M36.1506 41.1106C45.4892 41.1106 53.0596 32.3554 53.0596 21.5553C53.0596 10.7552 45.4892 2 36.1506 2C26.8121 2 19.2417 10.7552 19.2417 21.5553C19.2417 32.3554 26.8121 41.1106 36.1506 41.1106Z' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M95.3336 90V80.2221C95.3308 75.8891 94.0838 71.6799 91.7885 68.2554C89.4931 64.8309 86.2794 62.385 82.6519 61.3018' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M65.7437 2.6377C69.3808 3.71471 72.6046 6.16108 74.9068 9.59113C77.2089 13.0212 78.4585 17.2398 78.4585 21.5819C78.4585 25.924 77.2089 30.1426 74.9068 33.5727C72.6046 37.0027 69.3808 39.4491 65.7437 40.5261' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_36379_19711'%3E%3Crect width='97' height='92' fill='white' transform='translate(0.333496)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")

  &--currency
    background-image: url("data:image/svg+xml,%3Csvg width='98' height='69' viewBox='0 0 98 69' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M49.1665 3.83301V88.1663' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M69.3748 19.167H39.0623C35.3106 19.167 31.7126 20.5805 29.0597 23.0966C26.4069 25.6128 24.9165 29.0253 24.9165 32.5837C24.9165 36.142 26.4069 39.5546 29.0597 42.0707C31.7126 44.5868 35.3106 46.0003 39.0623 46.0003H59.2707C63.0224 46.0003 66.6204 47.4139 69.2733 49.93C71.9261 52.4461 73.4165 55.8587 73.4165 59.417C73.4165 62.9753 71.9261 66.3879 69.2733 68.904C66.6204 71.4201 63.0224 72.8337 59.2707 72.8337H24.9165' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")

  &--percent
    background-image: url("data:image/svg+xml,%3Csvg width='97' height='69' viewBox='0 0 97 69' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M76.7913 19.1666L20.208 72.8333' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M26.2712 34.5C31.8515 34.5 36.3753 30.2094 36.3753 24.9167C36.3753 19.624 31.8515 15.3334 26.2712 15.3334C20.6908 15.3334 16.167 19.624 16.167 24.9167C16.167 30.2094 20.6908 34.5 26.2712 34.5Z' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M70.7292 76.6667C76.3095 76.6667 80.8333 72.3761 80.8333 67.0833C80.8333 61.7906 76.3095 57.5 70.7292 57.5C65.1488 57.5 60.625 61.7906 60.625 67.0833C60.625 72.3761 65.1488 76.6667 70.7292 76.6667Z' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")

  &--edit
    background-image: url("data:image/svg+xml,%3Csvg width='97' height='69' viewBox='0 0 97 69' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M66.6875 13.4166C68.2954 11.8916 70.4761 11.0349 72.75 11.0349C73.8759 11.0349 74.9908 11.2452 76.031 11.6539C77.0712 12.0626 78.0164 12.6615 78.8125 13.4166C79.6086 14.1717 80.2402 15.0682 80.671 16.0548C81.1019 17.0413 81.3237 18.0988 81.3237 19.1666C81.3237 20.2345 81.1019 21.2919 80.671 22.2785C80.2402 23.2651 79.6086 24.1615 78.8125 24.9166L28.2917 72.8333L12.125 76.6666L16.1667 61.3333L66.6875 13.4166Z' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")

  &--file
    background-image: url("data:image/svg+xml,%3Csvg width='97' height='69' viewBox='0 0 97 69' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_43091_25723)'%3E%3Cpath d='M60.125 2H13.625C10.5419 2 7.58499 2.92714 5.40488 4.57746C3.22477 6.22778 2 8.46609 2 10.8V81.2C2 83.5339 3.22477 85.7722 5.40488 87.4225C7.58499 89.0729 10.5419 90 13.625 90H83.375C86.4581 90 89.415 89.0729 91.5951 87.4225C93.7752 85.7722 95 83.5339 95 81.2V28.4L60.125 2Z' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M60.125 2V28.4H95' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M71.75 50.3999H25.25' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M71.75 67.9995H25.25' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M36.875 32.7983H31.0625H25.25' stroke='%23F1F5FA' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_43091_25723'%3E%3Crect width='97' height='92' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")

  &-wrapper
    display: flex
    flex-wrap: wrap
    gap: 28px

  &__help-icon
    cursor: help
    color: var(--color-neutral)
    width: 20px
    position: absolute
    top: 8px
    right: 8px
    transition: all 0.15s

    &:hover
      color: var(--color-primary)

  &__title, &__value
    margin-bottom: 0

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.4rem
    font-weight: 300

  &__value
    @extend .u-text-truncate
    color: var(--color-neutral-darker, #5D6A86)
    font-size: 2.6rem
    font-weight: 800
    line-height: 1.2
    span
      font-size: .5em
