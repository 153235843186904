.instructions-container {
  font-weight: semi-bold;
  padding:4px;
  padding-left: 10px;

  border-radius: 10px;
  border: 1px solid var(--color-neutral-light);
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.instructions-container .full-width {
  width: 100%;
}