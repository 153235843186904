.quiz-data
  &:not(:last-of-type)
    border-bottom: 1px solid var(--color-neutral-lightest)
    margin-bottom: 40px
    padding-bottom: 40px

  &__question
    margin-bottom: 20px
    font-size: 1.6rem
    font-weight: 700
    color: var(--color-primary-dark)

  &__answer
    color: var(--color-neutral-darker)
    font-size: 1.4rem
    display: flex
    align-items: flex-start
    gap: 20px

  &__avatar
    @extend .students-card__img
    width: 60px
    height: 60px

  &__response-text
    flex-grow: 1

.quiz-answer-option
  display: flex
  align-items: center
  gap: 20px
  flex-wrap: wrap
  padding: 20px 0
  border-bottom: 1px solid var(--color-neutral-lightest)

  &__text
    flex-grow: 1
    font-weight: 300
    font-size: 1.6rem
    color: #000
    margin-bottom: 0

  .star-rating, .btn
    flex-shrink: 0

.center-empty-state
  padding-top: 130px
