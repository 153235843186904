.chart-content
	background: white
	box-shadow: $dropshadow
	border-radius: 10px
	padding: 2em
	margin: 1em 0
	+i(1/1)

	&__title
		font-size: .9em

	&__image
		background: var(--color-bright)
		min-height: 200px
