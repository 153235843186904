.post-list
	+i(1/1, md 12/20)

	.post
		margin: 1em 0

.question-list
	+i(1/1, md 14.5/20)

.infinite-scroll-component
	.post
		margin: 1em 0
