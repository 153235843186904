::placeholder,
[type=date][value=""],
[type=file][value=""] ~ .form__control
  color: #d8d8d8
  opacity: 1

.form
  &--step-by-step
    height: calc(100vh - (105px + 45px))
    overflow: hidden
    display: flex
    flex-direction: column

    .page__content
      flex-grow: 1
      height: 100px
      overflow-y: auto
      overflow-x: hidden

  &__title
    color: var(--color-primary-dark)
    margin-bottom: 16px
    font-weight: 700
    font-size: 16px

  &__row
    margin: 1.5em 0
    &--password
      position: relative
      span
        cursor: pointer
        position: absolute
        right: 0
        top: 0
        width: 10%
        height: 40px
        line-height: 38px
        display: inline-block
        text-align: center

    &--hr
      border-bottom: 1px solid var(--color-bright)
      padding-bottom: 32px

    &--columns
      display: flex
      flex-wrap: wrap
      gap: 20px

  &__col
    flex-basis: 0
    flex-grow: 1
    min-width: 150px

  &__columns
    display: flex
    flex-wrap: no-wrap
    gap: 10px

    &--wrap
      flex-wrap: wrap

    > *
      flex-basis: 300px
      flex-grow: 1

    .form__col
      min-width: auto

  &__custom-file-input
    display: block
    cursor: pointer

    .form__control
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-paperclip'><path d='M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48'></path></svg>")
      background-repeat: no-repeat
      background-position: right 16px center
      background-size: 16px
      padding-right: 42px
      overflow-wrap: anywhere

    input[type=file]
      display: none

  .rember-link
    color: var(--color-secondary)
    font-size: .9em

  .back-login
    color: var(--color-neutral)
    font-size: .9em
    a
      color: var(--color-primary)
      font-weight: 700

  .label-terms
    input
      display: inline
      width: 15px
      height: 15px
      margin: 0 5px 0 0
      -webkit-appearance: auto
      -moz-appearance: auto
      appearance: auto

  &__label
    font-weight: 700
    display: block
    width: 100%
    margin-bottom: .2em
    font-size: .9em
    color: var(--color-primary)

    &__label
      font-weight: 300

  &__label-wrapper
    display: flex
    align-items: flex-start
    gap: 12px
    margin-bottom: .2em
    width: 100%
    .form__label
      flex-basis: 0
      flex-grow: 1

  &__control
    -webkit-appearance: none
    -moz-appearance: none
    border-radius: 5px
    border: 1px solid var(--color-primary-lightest)
    padding: 8px 16px
    font-size: 1.6rem
    background: white
    color: var(--color-neutral)
    font-weight: 300
    display: block
    height: 40px
    +i(1/1)

    &:focus
      outline: none
      border: 1px solid var(--color-primary)

    &:disabled
      color: var(--color-neutral)
      pointer-events: none
      opacity: .24

    &--small
      padding: 4px 6px !important
      height: auto !important
      font-size: 1.2rem !important

    &--search
      border: 1px solid var(--color-primary)
      border-radius: 50px
      background-color: transparent

      &-with-icon
        background-image: url("data:image/svg+xml,<svg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M6.75002 2C3.85053 2 1.50003 4.3505 1.50003 7.24999C1.50003 10.1495 3.85053 12.5 6.75002 12.5C9.64951 12.5 12 10.1495 12 7.24999C12 4.3505 9.64951 2 6.75002 2ZM3.05176e-05 7.24999C3.05176e-05 3.52207 3.0221 0.5 6.75002 0.5C10.4779 0.5 13.5 3.52207 13.5 7.24999C13.5 10.9779 10.4779 14 6.75002 14C3.0221 14 3.05176e-05 10.9779 3.05176e-05 7.24999Z' fill='rgb(136, 148, 172)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M10.4572 10.9572C10.7501 10.6643 11.225 10.6643 11.5178 10.9572L14.7803 14.2197C15.0732 14.5125 15.0732 14.9874 14.7803 15.2803C14.4875 15.5732 14.0126 15.5732 13.7197 15.2803L10.4572 12.0178C10.1643 11.7249 10.1643 11.2501 10.4572 10.9572Z' fill='rgb(136, 148, 172)'/></svg>")
        background-repeat: no-repeat
        background-position: right 16px center
        background-size: 16px
        padding-right: 42px

      &-left
        background-image: url("data:image/svg+xml,<svg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M6.75002 2C3.85053 2 1.50003 4.3505 1.50003 7.24999C1.50003 10.1495 3.85053 12.5 6.75002 12.5C9.64951 12.5 12 10.1495 12 7.24999C12 4.3505 9.64951 2 6.75002 2ZM3.05176e-05 7.24999C3.05176e-05 3.52207 3.0221 0.5 6.75002 0.5C10.4779 0.5 13.5 3.52207 13.5 7.24999C13.5 10.9779 10.4779 14 6.75002 14C3.0221 14 3.05176e-05 10.9779 3.05176e-05 7.24999Z' fill='rgb(136, 148, 172)'/><path fill-rule='evenodd' clip-rule='evenodd' d='M10.4572 10.9572C10.7501 10.6643 11.225 10.6643 11.5178 10.9572L14.7803 14.2197C15.0732 14.5125 15.0732 14.9874 14.7803 15.2803C14.4875 15.5732 14.0126 15.5732 13.7197 15.2803L10.4572 12.0178C10.1643 11.7249 10.1643 11.2501 10.4572 10.9572Z' fill='rgb(136, 148, 172)'/></svg>")
        background-repeat: no-repeat
        background-position: left 16px center
        background-size: 16px
        padding-left: 42px

      &::placeholder
        color: var(--color-primary)
        font-weight: 700
        opacity: 1

    &-wrapper
      position: relative

      .list-alternatives__container &
        flex-grow: 1
        .form__control
          width: 100%

  &__counter
    position: absolute
    top: 50%
    right: 16px
    transform: translateY(-50%)
    color: var(--color-neutral-lighter, #CCD2E0)
    font-size: 1.2rem
    font-weight: 300
    background-color: #fff
    padding: 8px 0 8px 8px

    &--textarea
      top: 1px
      transform: translateY(0)

    &--danger
      color: #FDB462

  &__text
    display: block
    font-size: 1.2rem
    color: var(--color-neutral)
    margin-top: .5em

  &__description
    display: block
    font-weight: 300
    font-size: 1.2rem
    margin-bottom: 12px
    color: var(--color-neutral-dark)

  input[type='radio']
    -webkit-appearance: radio

  &__select
    height: 40px
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    border-radius: 5px
    border: 1px solid var(--color-primary-lightest)
    padding: 10px 1em
    font-size: 13px
    background: white
    background-image: url("data:image/svg+xml,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M1.82904 0.313814C1.41062 -0.104605 0.732233 -0.104605 0.313814 0.313814C-0.104605 0.732233 -0.104605 1.41062 0.313814 1.82904L3.74239 5.25761C4.1608 5.67603 4.8392 5.67603 5.25761 5.25761L8.68619 1.82904C9.10461 1.41062 9.10461 0.732233 8.68619 0.313814C8.26777 -0.104605 7.58938 -0.104605 7.17096 0.313814L4.5 2.98477L1.82904 0.313814Z' fill='rgb(136, 148, 172)'/></svg>")
    background-repeat: no-repeat
    background-position: right 16px center
    background-size: 10px
    padding-right: 42px
    color: var(--color-neutral)
    +i(1/1)
    box-shadow: $dropshadow

    &:focus
      outline: none
      border: 1px solid var(--color-primary)

    &--outline
      color: var(--color-primary)
      font-size: 1.2rem
      width: 128px
      height: 28px
      padding: 2px 28px 2px 12px
      box-shadow: none
      border-color: var(--color-primary)
      border-radius: 100px
      background-color: transparent
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 0.75L5 5.25L9.5 0.75' stroke='%23442B8C' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      background-repeat: no-repeat
      background-position: center right 12px
      background-size: 10px
      transition: .16s

      &:hover, &:focus
        background-color: var(--color-bright)

  .DraftEditor-editorContainer
    display: block
  textarea, .DraftEditor-editorContainer
    -webkit-appearance: none
    -moz-appearance: none
    width: 100%
    border-radius: 5px
    border: 1px solid var(--color-primary-lightest)
    height: 120px
    padding: 1em
    font-size: 1.6rem
    font-weight: 300
    background: white
    color: var(--color-neutral)
    resize: none
    font-family: $text
    box-shadow: $dropshadow
    &:focus
      outline: none
      border: 1px solid var(--color-primary-lighter)

  [type='submit']:not(.btn)
    cursor: pointer
    background: var(--color-third)
    color: white
    width: inherit
    transition: all ease-in-out .2s
    border-radius: 50px
    padding: 0.6em 1.5em !important
    border: 0px
    font-size: .9em
    box-shadow: $dropshadow
    -webkit-appearance: none
    -moz-appearance: none
    &:hover
      background: var(--color-primary)
    &:disabled
      cursor: not-allowed
      opacity: .3

  &__search
    appearance: none
    width: 100%
    height: 40px
    border: 1px solid var(--color-primary-lightest)
    border-radius: 6px
    font-size: 13px!important
    line-height: 1.5
    color: var(--color-neutral)
    padding: 12px 40px 12px 16px
    background-color: #fff
    background-repeat: no-repeat
    background-position: right 16px center
    background-size: 16px
    background-image: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z' stroke='rgb(93, 106, 134)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/><path d='M19.0004 19L14.6504 14.65' stroke='rgb(93, 106, 134)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>")

    &:focus
      outline: none
      border-color: var(--color-primary)

  // Checks and radios
  &__check-input
    width: 1em!important
    height: 1em!important
    margin-top: .25em
    vertical-align: top
    background-color: #fff
    background-repeat: no-repeat
    background-position: center
    background-size: contain
    border: 1px solid rgba(0,0,0,.25)
    appearance: none
    print-color-adjust: exact

    &[type=checkbox]
      border-radius: .25em

      &:checked
        border-color: var(--color-primary)
        background-color: var(--color-primary)
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>")
        background-size: .6em
        background-position: center
        background-repeat: no-repeat

    &[type=radio]
      border-radius: 50px
      appearance: none !important

      &:checked
        border-color: var(--color-primary)
        background-color: var(--color-primary)
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
        background-size: .6em
        background-position: center
        background-repeat: no-repeat

    &--round[type=checkbox]
      border-radius: 50%

  &__check
    display: block
    padding-left: 1.5em
    margin-bottom: .5em
    position: relative
    align-items: center

    input
      width: 90%

    .form__check-input
      float: left
      margin-left: -1.5em
      padding: 0
      &--rounded
        margin-top: .4em

    &--inline
      display: inline-block !important
      margin-right: 2rem

    &-label
      color: var(--color-neutral) !important
      font-weight: 300!important
      font-size: .9em

  &__switch
    padding-left: 32px
    font-size: 13px
    line-height: 100%
    align-items: center
    display: flex
    gap: 0em
    input
      margin: 0 .5em

    .form__check-input
      flex-shrink: 0
      width: 2em !important
      margin-left: -32px
      border-radius: 2em
      background-position: left center
      animation: switchMoveOff .3s forwards
      background-size: 12px 12px
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgba(0, 0, 0, 0.25)'/></svg>")

      &:checked
        background-color: #EBEDF4
        background-position: right center
        background-size: auto
        animation: switchMoveOn .3s forwards
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgb(115, 94, 244)'/></svg>")
        background-size: 12px 12px

  &__input-group
    position: relative
    display: flex
    flex-wrap: nowrap
    align-items: stretch
    width: 100%

    .form__control, input
      flex-shrink: 1
      flex-grow: 1

    .btn
      flex-shrink: 0
      display: flex
      align-items: center

    & > :last-child
      border-radius: 0 4px 4px 0

    & > :first-child
      border-radius: 4px 0 0 4px

  &__donate
    // ::placeholder
    //   color: var(--color-neutral)
    //   font-weight: normal

    // ::-ms-input-placeholder
    //   color: var(--color-neutral)
    //   font-weight: normal
    ::placeholder
      color: var(--color-neutral-light)
      font-weight: 300
      font-size: .95em
      font-family: $text

    #graduated, #cpf, #phone, #cep, #zip_code, #state, #placing, #modality
      +i(1/2)
      border-radius: 0 0 0 5px
    #institution, #phone, #state, #placing
      border-radius: 0 0 5px 0

    #phone, #complement
      border-left: 0px

    #address
      border-bottom: 0px

    #email, #card_holder_name
      border-radius: 5px 5px 0 0
      border-bottom: 0px

    #city, #complement, #neighborhood, #number
      border-radius: 0
      border-bottom: 0px
      +i(1/2)

    &__payment-totals
      margin-top: 2em
      padding-top: 1em
      border-top: 1px dashed var(--color-neutral)

    &__payment-info
      +g(full, middle, space-between)
      +i(1/1)
      color: var(--color-neutral-darker)
      font-weight: 700
      margin-top: .5em
      font-size: .9em
      &--total
        +g(full, middle, space-between)
        +i(1/1)
        font-weight: 700
        color: var(--color-primary-dark)
        margin-top: 2em

      &--crypto
        +g(full, middle, center)
        +i(1/1)
        color: var(--color-primary-light)
        font-weight: 300
        font-size: .9em
        margin-top: -1em
        img
          width: 15px
          height: 15px
          margin-right: .3em

    label
      font-weight: 700
      display: block
      margin-bottom: .2em
      font-size: 1em
      color: var(--color-primary)

    &--card-info
      border-top: 1px dashed var(--color-primary-lightest)
      margin-top: 2em
      padding-top: 2em
      input
        border-radius: 5px
        border: 1px solid var(--color-primary-lightest)
        height: 40px
        padding: 1em
        font-size: 1em
        background: white
        color: var(--color-neutral)
        +i(1/1)

        &:nth-child(2)
          +i(2.25/4)
          border-radius: 5px 0 0 5px
          border-right: none

        &:nth-child(3)
          +i(1/4)
          border-radius: 0
          border-left: none
          border-right: none
          text-align: center

          // ::placeholder
          //   color: var(--color-primary-dark)
          //   font-weight: normal

          // ::-ms-input-placeholder
          //   color: var(--color-primary-dark)
          //   font-weight: normal

        &:nth-child(4)
          +i(.75/4)
          border-radius: 0 5px 5px 0
          border-left: none
          text-align: center

          // ::placeholder
          //   color: var(--color-primary-dark)
          //   font-weight: normal

          // ::-ms-input-placeholder
          //   color: var(--color-primary-dark)
          //   font-weight: normal

        &:focus
          outline: none
          border: 1px solid var(--color-primary)

    &--address-info
      margin: 2em 0 2em 0
      select
        +i(2/4)
        border-radius: 0 0 5px 0
        border-left: none

      input
        border-radius: 5px
        border: 1px solid var(--color-primary-lightest)
        height: 40px
        padding: 1em
        font-size: 1em
        background: white
        color: var(--color-neutral)
        +i(1/1)

        &:nth-child(2)
          border-radius: 5px 5px 0 0

        &:nth-child(3)
          +i(2/4)
          border-radius: 0 0 0 5px

          // ::placeholder
          //   color: var(--color-primary-dark)
          //   font-weight: normal

          // ::-ms-input-placeholder
          //   color: var(--color-primary-dark)
          //   font-weight: normal

        &:nth-child(4)
          +i(2/4)
          border-radius: 0 0 5px 0
          border-left: none

          // ::placeholder
          //   color: var(--color-primary-dark)
          //   font-weight: normal

          // ::-ms-input-placeholder
          //   color: var(--color-primary-dark)
          //   font-weight: normal

    input
      border-radius: 5px
      border: 1px solid var(--color-primary-lightest)
      height: 40px
      padding: 1em
      font-size: .95em
      background: white
      color: var(--color-neutral-darker)
      +i(1/1)

      &:focus
        outline: none
        border: 1px solid var(--color-primary-lightest)

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button
      -webkit-appearance: none
      margin: 0

    select
      -webkit-appearance: none
      -moz-appearance: none
      appearance: none
      border-radius: 5px
      border: 1px solid var(--color-primary-lightest)
      height: 40px
      padding: 0 1em
      font-size: .9em
      background: white
      color: var(--color-neutral)
      font-weight: 300
      &:focus
        outline: none
        border: 1px solid var(--color-primary)

    textarea
      border-radius: 5px
      border: 1px solid var(--color-primary-lightest)
      min-height: 40px
      padding: 1em
      font-size: 1em
      background: white
      color: var(--color-neutral)
      resize: none
      font-family: $text
      box-shadow: $dropshadow
      &:focus
        outline: none
        border: 1px solid var(--color-primary-lighter)

    input[type='submit']
      width: 100% !important
      font-size: 1em
      cursor: pointer
      background: var(--color-primary)
      color: white
      width: inherit
      transition: all ease-in-out .2s
      line-height: 0px
      border-radius: 50px
      padding: 1.5em 2em
      border: 0px
      box-shadow: $dropshadow
      font-family: $text
      &:hover
        background: var(--color-primary)

  &__collapse
    height: 0
    transition: height 0.3s
    overflow: hidden

    &-content
      display: flex
      flex-direction: column
      gap: 20px

      .form__row
        margin: 0

      > :last-child
        margin-bottom: 20px

  &__ai-banner
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    gap: 12px
    padding: 12px 24px
    border-radius: 8px
    background: linear-gradient(90deg, #D8FAF8 0%, #FFF2F6 100%)

    &-title
      margin-bottom: 0
      color: var(--color-primary)
      font-size: 1.7rem
      font-weight: 700
      line-height: 1.2

textarea
  -webkit-appearance: none
  -moz-appearance: none
  width: 100%
  border-radius: 5px
  border: 1px solid var(--color-primary-lightest)
  min-height: 100px
  padding: 1em
  font-size: 13px
  font-weight: 300
  background: white
  color: var(--color-neutral)
  resize: none
  font-family: $text
  box-shadow: $dropshadow
  &:focus
    outline: none
    border: 1px solid var(--color-primary-lighter)

.wrs_modal_dialogContainer img, .ck-editor img
  width: initial

.wrs_modal_dialogContainer i, .ck-editor i
  font-style: italic

.wrs_modal_dialogContainer h2, .ck-editor h2
  font-size: 2em

.wrs_modal_dialogContainer h3, .ck-editor h3
  font-size: 1.6em

.wrs_modal_dialogContainer h4 .ck-editor h4
  font-size: 1.2em

.ck-editor__editable_inline
  padding: 0 30px !important
  h2
    font-size: 2em
    h3
      font-size: 1.6em
    h4
      font-size: 1.2em
    p
      margin: 0
    img
      width: auto
      max-width: 100%
      max-height: 550px
    i, .ck-editor i
      font-style: italic
    ul, ol
      padding: 0 30px !important
    ul
      list-style-type: disc
    ol
      list-style-type: decimal
