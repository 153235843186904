.encceja
  --color-primary-dark: #012A55
  --color-primary: #054F9C
  --color-primary-light: #84AFDB
  --color-primary-lighter: #B9C6D4
  --color-primary-lightest: #EFF4F9

  --color-secondary-dark: #006F39
  --color-secondary: #67C698
  --color-secondary-light: #B8DCCA
  --color-secondary-lighter: #FF9153
  --color-secondary-lightest: #FF9B63

.encceja .mock__content
  @media only screen and (min-width: 49em)
    background-image: url('../../images/encceja/horizontal-logo.svg')
    background-position: 85% center
