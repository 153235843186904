.modal
    display: flex
    align-items: flex-start
    justify-content: right
    position: fixed
    inset: 0
    background-color: rgba(0, 0, 0, .2)
    overflow-x: hidden
    overflow-y: auto
    outline: 0
    z-index: 1060
    opacity: 0
    animation: fadeIn .2s forwards
    transition: all .2s ease-in-out
    pointer-events: none

    &.active
        pointer-events: all
        opacity: 1

    &.enter-done, &.show
        opacity: 1
        pointer-events: visible

    &.exit
        opacity: 0

    &__content
        min-height: 100%
        margin-bottom: 0
        width: 564px
        max-width: 80vw
        padding: 4vh
        box-sizing: border-box
        background-color: white
        border-radius: 4px
        box-shadow: $dropshadow
        transform: translateX(300px)
        transition: all .2s ease-in-out .1s
        opacity: 0
        display: flex
        flex-direction: column

        &--sm
            width: 464px
        &--md
            width: 810px
        &--lg
            width: 1052px
        &--full
            width: 100%
        &--p-md
            @media (min-width: $md)
                padding: 40px

        .modal.enter-done &, .modal.show &
            transform: translateX(0)
            opacity: 1

        .modal.exit &
            transform: translateX(300px)
            opacity: 0

    &__header, &__body
        margin-bottom: 40px

    &__header
        background: var(--color-primary-dark)
        box-shadow: $dropshadow
        border-radius: 4px
        padding: 36px
        color: white
        text-align: center

    &__simple-header
        display: flex
        align-items: center
        gap: 12px
        margin-bottom: 24px

    &__title
        margin-bottom: 0
        color: white
        font-size: 2.8rem
        text-transform: uppercase

    &__simple-title
        color: var(--color-primary-dark)
        border-bottom: 1px solid var(--color-bright)
        padding-bottom: 4px
        font-weight: 700
        font-size: 1.6rem
        margin-bottom: 1em

        &--with-btn
            flex-grow: 1
            border-bottom: 0
            margin-bottom: 0
            padding-bottom: 0

    &__dialog-title
        color: var(--color-primary-dark)
        font-size: 1.6rem
        font-weight: 700
        text-align: center
        margin-bottom: 20px

    &__close-btn
        @extend .accessed-materials-widget__btn

    &__teacher
        margin-bottom: 0

    &__test
        color: var(--color-primary-lighter)
        display: block
        width: fit-content
        padding: 8px 24px
        border: 1px solid var(--color-neutral-lighter)
        line-height: 1
        border-radius: 4px
        margin: 0 auto 40px auto

    &__description
        width: 80%
        margin: 0 auto
        display: flex
        align-items: center
        gap: 16px

    &__progress
        flex: 0 0 auto

    &__description-text
        margin-bottom: 0

    &__footer
        text-align: center

    &__reminder
        color: var(--color-neutral)
        margin-bottom: 0
        font-size: 1.2rem

        &--finalize
            font-size: 1.6rem
            margin-bottom: 1.6rem
            color: black

    &__btns
        display: flex
        align-items: center
        flex-wrap: wrap
        justify-content: center
        gap: 12px
        width: 80%
        margin: 0 auto

        .btn
            flex-grow: 1
            justify-content: center

    // Schedule
    &__img-schedule
        display: block
        width: 100%
        height: 176px
        object-fit: cover
        border-radius: 8px
        margin-bottom: 28px

    &__title-schedule
        font-weight: 700
        font-size: 1.6rem
        line-height: 1.5
        color: var(--color-primary-dark)
        margin-bottom: 0

    &__date-schedule
        color: var(--color-neutral-dark)
        font-weight: 700
        font-size: 1.6rem
        margin-bottom: 0

    &__hour-schedule
        color: var(--color-neutral-dark)
        font-weight: 300
        font-size: 1.4rem
        margin-bottom: 0

    &__text-schedule
        color: var(--color-neutral-dark)
        font-weight: 300
        font-size: 1.4rem
        margin: 20px 0
        white-space: pre-line

    &__text
        color: var(--color-neutral-darker, #5D6A86)
        font-size: 1.5rem
        margin-bottom: 0

    &__body
        height: 0
        flex-grow: 1
        overflow-y: auto
