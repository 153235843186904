.canaleducacao
  --color-primary-dark: #0B4360
  --color-primary: #0F79B0
  --color-primary-light: #61B1DB
  --color-primary-lighter: #9ECFE9
  --color-primary-lightest: #E0F0F9

  --color-secondary-dark: #006B30
  --color-secondary: #01B251
  --color-secondary-light: #CAEFDB
  --color-secondary-lighter: #EFA91C
  --color-secondary-lightest: #FAC865

  --color-bright: #F2F5FD

.canaleducacao .mock__content
  @media only screen and (min-width: 49em)
    background-image: url('../../images/canaleducacao/horizontal-logo.svg')
    background-position: 85% center
