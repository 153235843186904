.show-course-sidebar
  flex-shrink: 0
  padding: 2vw
  width: 320px
  max-width: 100%

  &__avatar
    width: 60px
    height: 60px
    background-size: cover
    border-radius: 100%

  &__user-name
    font-size: 1em
    color: var(--color-primary-dark)
    margin: 1em 0 .5em
    font-weight: 800

  &__user-description
    color: var(--color-neutral-dark)
    font-size: .9em

