.intervention-proposal
  &:not(:last-of-type)
    margin-bottom: 36px

  .star-rating
    margin-bottom: 8px

  &__title
    color: var(--color-primary)
    font-weight: 700
    font-size: 2rem
    margin-bottom: 24px

  &__reply
    color: #000
    font-weight: 700
    font-size: 1.6rem
    margin-bottom: 4px

  &__text
    color: #2f2f2f
    font-size: 1.6rem
    margin-bottom: 0
