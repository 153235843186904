.new-questions
    display: flex
    flex-direction: column
    @media (min-width: $md )
        flex-direction: row

    &--student
        @media (min-width: 784px)
            margin-left: 64px

    .taxonomies__item
        background-color: white
        span
            font-size: 12px

    .main-content &
        width: 100%
        @media (min-width: $md )
            height: 100vh

    &__sidebar
        background: var(--color-neutral-bright-20)
        border-radius: 20px 0px 0px 20px
        flex-shrink: 0
        padding: 2em
        font-size: 1.2rem
        line-height: 1.2
        color: var(--color-primary-dark)
        overflow-x: hidden
        overflow-y: auto
        box-shadow: 1px 0 8px rgba(black, 0.04)
        @media (min-width: $md)
            width: 220px
            height: 100vh

        &::-webkit-scrollbar
            display: none

        .btn--primary
            margin-top: 20px

        &--with-main-nav
            padding-top: 150px
            +bp(md)
                padding-top: 40px

    &__question
        appearance: none
        padding: 16px 24px
        background: transparent
        border: 0
        display: block
        width: 100%
        text-align: left
        width: calc(100% + 32px)
        display: flex
        align-items: center
        justify-content: space-between
        gap: 12px

        &.is-selected
            color: #fff
            border-radius: 5px 0px 0px 5px
            background-color: var(--color-primary)

            .new-questions__icon svg
                color: var(--color-third)

    &__icon svg
        color: #8376A9
        width: 16px

    &__empty-state-text
        text-align: center

    &__content
        flex-grow: 1
        padding: 52px min(4vw, 52px)
        overflow-x: hidden
        overflow-y: auto
        position: relative
        @media (min-width: $md )
            height: 100vh

        .select-empty-state
            max-width: 288px
            margin: 60px auto 0 auto

        .main-nav
            +bp(md)
                left: calc(18% + 280px)

        &--with-main-nav
            padding-top: 105px

    &__header
        display: flex
        align-items: center
        gap: 12px
        border-bottom: 1px solid #EEEBF9
        padding-bottom: 12px
        margin-bottom: 32px

    &__group
        @extend .u-text-truncate
        flex-grow: 1
        min-width: 0

    &__title
        margin-bottom: 0
        font-size: 1.6rem
        font-weight: 700
        color: var(--color-primary-dark)

    &__date-time
        font-size: 1.2rem
        font-weight: 300
        color: var(--color-neutral-darker)

    &__date-time-group
        +g(full, middle)
        gap: .5em
        margin-top: .2em

    &__subtitle
        @extend .new-questions__title
        font-size: 1.4rem
        font-weight: 300
        color: var(--color-neutral)
        margin-bottom: 20px

    &__help
        color: #7763AE
        flex-shrink: 0
        display: flex
        align-items: center
        gap: 10px

        &-icon svg
            vertical-align: baseline

    &__page
        width: 100%
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        gap: 44px

    &__col
        flex-basis: 0
        flex-grow: 1
        min-width: 270px
        margin-bottom: 44px
        @media (min-width: $md)
            margin-bottom: 0

    &__aside
        //display: none
        flex: 0 0 100%
        @media (min-width: $md)
            flex: 0 0 240px

    &__info
        border: 1px solid var(--color-primary-lightest)
        background-color: var(--color-bright)
        color: black
        padding: 12px
        position: sticky
        top: 0

        p
            font-size: 1.2rem
            line-height: 1.2
            margin-bottom: 20px
            &:not(:last-child)
                padding-block-end: 20px
                border-bottom: 1px solid var(--color-primary-lightest)

        & > :last-child
            margin-bottom: 0

    &__tag-example
        color: #8894AC

    &__widget
        &:not(:last-of-type)
            margin-bottom: 36px

        & > :last-child
            margin-bottom: 0

    &__widget-header
        border: 1px solid var(--color-neutral-lightest)
        border-radius: 4px
        padding: 10px
        display: flex
        align-items: center
        gap: 20px
        margin-bottom: 10px

    &__widget-title
        width: 0
        flex-grow: 1
        font-size: 1.2rem
        font-weight: 700
        color: var(--color-primary-dark)
        margin-bottom: 0

    &__widget-box
        background: #fff
        box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
        border-radius: 4px
        padding: 16px
        display: flex
        align-items: center
        flex-wrap: wrap
        gap: 12px

        &:not(:last-of-type)
            margin-bottom: 10px

    &__widget-box-header
        flex-basis: 0
        flex-grow: 1
        margin-bottom: 0
        font-size: 1.4rem
        font-weight: 300
        color: var(--color-primary-dark)

    &__widget-box-body
        flex-basis: 0
        flex-grow: 1

    &__widget-box-btn
        all: unset
        font-size: 1rem
        font-weight: 300
        color: var(--color-neutral-darker)
        text-decoration: underline
        cursor: pointer

    &__text
        font-size: 13px

    .round-dg
        border-spacing: 0 .4em

// .draggable-table
//     display: table-caption
//     width: 100%

.draggable-table-cell
    display: table
    width: 100%
