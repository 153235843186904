.breadcrumb
  +g(full)
  font-size: .9em
  font-weight: 300

  &__link
    white-space: nowrap
    max-width: 100px
    overflow: hidden
    text-overflow: ellipsis
    color: var(--color-primary-lighter)
    margin-right: .5em
    transition: all ease-in-out .2s

    &:last-child
      color: var(--color-primary-light)
      font-weight: 700
      max-width: initial

    &:hover
      color: var(--color-primary)
