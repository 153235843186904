.question-chart
  padding: 20px
  border-bottom: 1px solid var(--color-primary-lightest)
  @media (min-width: $sm)
    border-bottom: none
    &:not(:nth-of-type(2n))
      border-right: 1px solid var(--color-primary-lightest)
  @media (min-width: 1060px)
    padding: 0 20px
    &:not(:nth-of-type(2n))
      border-right: none
    &:not(:nth-of-type(3n))
      border-right: 1px solid var(--color-primary-lightest)
  @media (min-width: $xxl)
    &:not(:nth-of-type(3n))
      border-right: none
    &:not(:nth-of-type(4n))
      border-right: 1px solid var(--color-primary-lightest)
  @media (min-width: 1900px)
    &:not(:nth-of-type(4n))
      border-right: none

  &-wrapper
    @extend .tab__cards
    gap: 72px 0
    text-align: left

  &:not(:nth-of-type(3n))

  &__header
    margin-bottom: 16px

  &__title
    margin-bottom: 0
    font-weight: 700
    font-size: 1.4rem
    color: var(--color-primary-dark)

  &__body
    margin-bottom: 16px

  &__footer
    display: flex
    align-items: center
    justify-content: center
