.feed-banner
  display: none
  +bp(md)
    display: block
  +i(5/20)
  right: 3em
  top: 7em
  position: fixed
  border-radius: 10px
  height: 20.4vw
  background-size: cover
  background-repeat: no-repeat
  background-position: center
  border-radius: 5px
  image-rendering: auto
