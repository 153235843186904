.check-in-wrapper
  width: 100%
  min-height: 100vh
  padding: 16px
  overflow-y: auto
  background-color: #fff
  background-image: url('./img/map.svg'), url('./img/cashew.svg'), url('./img/star.svg')
  background-position: left center, right -16px top, right bottom
  background-size: 120px, 100px, 189px
  background-repeat: no-repeat, no-repeat, no-repeat
  @media (min-width: 768px)
    background-position: left center, right 2vw top, right 5vw bottom
    background-size: 15vw, 10vw, 15vw

  &__nav
    margin-bottom: 48px
    position: sticky
    top: 0
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 16px
    justify-content: space-between

.check-in
  width: 548px
  max-width: 100%
  margin: 0 auto

  &__header
    display: flex
    flex-direction: column
    gap: 16px
    align-items: center
    text-align: center
    margin-bottom: 48px

  &__logo
    width: 144px
    @media (min-width: 768px)
      width: 288px

  &__title
    color: #071A4A
    font-size: 1.4rem
    font-weight: 700
    line-height: 1.2
    display: flex
    align-items: center
    gap: 4px
    margin-bottom: 0
    @media (min-width: 768px)
      font-size: 2rem

  .form .form__row--columns:first-child
    @media (max-width: 767px)
      display: flex
      flex-direction: column
      gap: 10px
      .text, .error-message
        height: auto
      .text
        align-self: center

    & + .form__row
      @media (max-width: 767px)
        display: flex
        flex-direction: column

  &__success
    aspect-ratio: 1/1
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 52px
    padding: 40px
    border-radius: 50px
    border: 1px solid var(--color-bright)
    background: #FFF
    box-shadow: 3px 22px 50px -2px rgba(121, 120, 130, 0.15)
    text-align: center
    @media (max-width: 767px)
      gap: 20px

    &-text
      color: #254AA5
      font-size: 2.8rem
      font-weight: 700
      line-height: 1.2
      margin-bottom: 0
      @media (max-width: 767px)
        font-size: 1.8rem

  .btn--review
    @media (max-width: 767px)
      width: 100%

.check-in__date
  position: absolute
  top: 20px
  left: calc(50% - 43px)
  padding: 5px
  border-radius: 10px
  background: #fafafa
  color: #071a4a
  font-size: 14px
  font-style: normal
  font-weight: 300
  line-height: 150%

  @media (max-width: 767px)
    font-size: 12px
