.menu
  position: relative
  color: #fff

  &__list
    display: flex
    flex-direction: column
    gap: clamp(0px, 2vh, 16px)

    &--no-gap
      gap: 0

  &__link
    display: inline-flex
    align-items: center
    gap: 12px
    width: 100%
    height: 40px
    padding: 12px
    border-radius: 100px
    cursor: pointer
    position: relative
    transition: background-color .1s

    &--is-active
      background-color: var(--color-primary-light)

    &--avatar
      width: 100%
      height: 64px

  &__icon
    flex-shrink: 0
    width: 16px
    stroke-width: 2

  @media (max-width: 783px) // mobile version
    position: fixed
    left: 0
    right: 0
    top: 0
    z-index: 105
    height: 46px

    &__header
      background-color: var(--color-primary-lighter)
      box-shadow: $dropshadow
      padding: 0 16px
      display: flex
      align-items: center
      justify-content: space-between
      gap: 16px
      height: 46px
      position: relative
      z-index: 107

    &__favicon
      cursor: pointer
      width: 32px
      height: 32px

    &__toggle-btn
      appearance: none
      cursor: pointer
      padding: 0
      color: var(--color-primary-dark)
      display: inline-flex
      align-items: center
      justify-content: center
      width: 36px
      height: 36px
      border-radius: 100px
      background-color: transparent
      transition: background-color .16s
      svg
        width: 20px
      &:hover, &:focus
        background-color: var(--color-primary-lightest)

    &__nav
      height: calc( 100vh - 46px )
      position: fixed
      inset: 46px 0 0 0
      z-index: 106
      overflow-y: auto
      padding: 16px
      background-color: var(--color-primary)
      display: flex
      flex-direction: column
      justify-content: space-between
      gap: 16px
      transform: translateY(-100%)
      transition: transform 0.2s ease-out

      &--is-expanded
        transform: translateY(0)

    &__item
      &--is-expanded
        color: var(--color-primary)
        background-color: #fff
        border-radius: 20px

        .menu__link--is-active
          background-color: transparent

        .menu__arrow
          rotate: 180deg

        .submenu
          display: flex
          flex-direction: column

    &__arrow
      width: 16px
      transition: .2s

  @media (min-width: 784px) // desktop version
    position: fixed
    top: 0
    z-index: 999
    display: flex
    flex-direction: column
    gap: clamp(12px, 5.5vh, 44px)
    width: 64px
    height: 100vh
    background-color: var(--color-primary)
    padding: clamp(12px, 3vh, 24px) 12px

    &__header
      display: flex

    &__favicon
      width: 28px
      margin: 0 auto
      cursor: pointer

    &__toggle-btn
      display: none

    &__nav
      flex-grow: 1
      height: 0
      width: 40px
      display: flex
      flex-direction: column
      justify-content: space-between

    &__link
      width: 40px

      &--avatar
        width: 64px
        margin-left: -12px

    &__item
      &:hover, &:focus
        .menu__link
          width: max-content
          background-color: #fff
          color: var(--color-primary)
          box-shadow: $dropshadow

          .menu__label
            opacity: 1

      &--has-submenu
        .menu__label, .menu__arrow
          display: none

        &:hover, &:focus
          .menu__link
            border-radius: 100px 0 0 100px
            width: 52px
            &::before, &::after
              content: ''
              width: 8px
              height: 8px
              position: absolute
              right: 0
            &::before
              top: -8px
              background-image: url("data:image/svg+xml,<svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M8 8H0C6.4 8 8 2.66667 8 0V8Z' fill='white'/></svg>")
            &::after
              bottom: -8px
              background-image: url("data:image/svg+xml,<svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M8 0H0C6.4 0 8 5.33333 8 8V0Z' fill='white'/></svg>")

          .submenu
            pointer-events: all
            opacity: 1

    &__label
      font-size: 1.4rem
      font-weight: 300
      opacity: 0

    .avatar
      flex-shrink: 0
      width: 40px
      height: 40px
      border: 0

.submenu
  color: var(--color-primary-dark)
  display: flex
  flex-direction: column
  gap: 16px

  &__header
    text-align: center
    display: flex
    flex-direction: column
    align-items: center
    padding: 20px 12px
    border-radius: 8px
    background: var(--color-neutral-bright)

    @media (max-width: 783px)
      display: none

  &__img
    width: 80px
    height: 68px
    margin-bottom: 12px

  &__title, &__subtitle
    margin-bottom: 0

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.6rem
    font-weight: 700

  &__subtitle
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.2rem
    font-weight: 300
    line-height: 1.2

  &__list
    display: flex
    flex-direction: column
    gap: 20px

    @media (max-width: 783px)
      gap: 0

  &__link
    display: flex
    padding: 8px
    align-items: flex-start
    gap: 8px
    border-radius: 8px
    background: #FFF
    transition: .15s
    &-title
      display: block
      color: var(--color-primary)
      font-size: 1.2rem
      font-weight: 700
      line-height: 1.5
    &-description
      display: block
      color: var(--color-neutral, #8894AC)
      font-size: 1.2rem
      font-weight: 300
      line-height: 1.5

    &:hover, &:focus
      background-color: var(--color-bright)

    &:active, &[aria-current=page]
      background-color: var(--color-neutral-lightest)

  &__icon
    width: 16px
    flex-shrink: 0
    color: var(--color-primary)

  @media (max-width: 783px) // mobile version
    display: none
    padding: 0 16px 16px 16px

  @media (min-width: 784px) // desktop version
    background: #fff
    box-shadow: 8px 4px 10px 0px rgba(119, 119, 120, 0.15)
    width: 188px
    height: 100vh
    padding: 32px 16px
    position: fixed
    left: 64px
    top: 0
    opacity: 0
    pointer-events: none
    overflow-y: auto
    transition: opacity .1s
