.study-plan-timeline
  display: flex
  align-items: center
  gap: 12px
  padding: 12px
  background-color: #fff
  border: 1px solid var(--color-neutral-lightest)
  border-radius: 12px
  height: 120px

  &__date
    flex-shrink: 0
    align-self: flex-end
    color: var(--color-neutral-dark)
    font-size: 1.2rem
    margin-bottom: 0
    display: flex
    flex-direction: column
    align-items: center
    gap: 8px

  &__icon
    min-width: 24px
    color: var(--color-neutral)

  .slider-wrapper
    position: relative
    width: 120px
    flex-grow: 1

  .progress-circle
    position: absolute
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    font-size: 14px
    font-weight: 700
    width: 20px
    height: 20px
    transform: scale(0.5) translateY(30px)
    cursor: pointer

    &.default
      background-color: var(--color-neutral-lightest)
      color: white

    &.completed
      background-color: var(--color-success)
      color: white

    &.incomplete
      background-color: var(--color-danger)
      color: white

  .tooltip
    position: absolute
    bottom: 100%
    left: 50%
    transform: translateX(-50%)
    margin-bottom: 5px
    background-color: var(--color-neutral-light)
    color: white
    padding: 8px !important
    border-radius: 4px
    font-size: 14px
    white-space: nowrap
    opacity: 1
    transition: opacity 0.3s ease-in-out

    &.completed
      background-color: var(--color-success)

    &.incomplete
      background-color: var(--color-danger)

  .week-slider
    width: 100%
    border-top: 2px dashed var(--color-neutral-lighter)

  .week-slider-thumb
    background-color: #ccc
    border-radius: 20px
    width: 20px
    height: 20px
    +g(full, center, middle)
    color: white
    transform: translate(0px, -11px)
    box-shadow: 0px 4px 10px 3px rgba(68, 43, 140, 0.2)
    border: 2px solid white

    &.completed
      background-color: var(--color-success)

    &.incomplete
      background-color: var(--color-danger)

.study-blocks-container
  +g(full, center, middle)
  color: white
  font-size: 16px
  +i(1/1)
  flex-wrap: nowrap
  position: relative

  .week-navigation-button
    flex-shrink: 0
    all: unset
    background: #ffffff
    background-clip: padding-box
    border: 1px solid var(--color-neutral-lightest)
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
    width: 40px
    height: 40px
    border-radius: 50px
    color: var(--color-primary)
    display: flex
    align-items: center
    justify-content: center
    font-size: 24px
    cursor: pointer
    transition: .15s
    z-index: 1
    position: absolute

    &--prev
      left: -22px

    &--next
      right: -22px

    &:hover
      color: #fff
      background-color: var(--color-primary)

  .study-blocks-wrapper
    width: 100%
    margin-top: 28px
