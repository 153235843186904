.survey
  &__header
    margin-bottom: 32px

  &__progress
    display: flex
    gap: 4px
    margin-bottom: 16px
    width: 100%

    &-item
      width: 60px
      height: 10px
      background-color: var(--color-primary-lightest)
      border-radius: 3px

      &.answered
        background-color: var(--color-primary-lighter)

  &__title
    color: var(--color-primary-dark)
    font-weight: 700
    font-size: 1.8rem
    line-height: 1.2
    margin-bottom: 0

  &__description
    color: var(--color-primary-dark)
    font-size: 1.2rem
    font-weight: 300

  &__footer
    text-align: center
    padding: 20px

.survey-card
  background: #FFFFFF
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.1)
  border-radius: 10px
  padding: 24px

  &:not(:first-of-type)
    margin-top: 8px

  &__header
    margin-bottom: 24px

  &__title
    color: var(--color-primary-dark)
    font-weight: 700
    font-size: 1.6rem

  &__body
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 24px

  &__legend
    color: var(--color-neutral)
    margin-bottom: 0
    font-size: 1.2rem
    text-align: center
