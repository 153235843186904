.course-slider, .warning-slider
  .swiper
    padding-bottom: 32px

  .swiper-pagination
    bottom: 0 !important

  .swiper-pagination-bullet
    background-color: var(--color-primary-light)
    margin: 0 10px !important

    &-active
      background-color: var(--color-primary-light)

  .swiper-slide
    padding: 8px 16px

  .swiper-button
    &-next, &-prev
      width: 40px
      height: 40px
      color: var(--color-primary)
      box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
      background-color: #fff
      border: 1px solid var(--color-primary-lightest)
      border-radius: 100px
      transform: translateY(-50%)

      &::after
        font-size: 16px

    &-next
      right: 0

    &-prev
      left: 0

    &-disabled
      display: none
