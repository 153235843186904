.empty-state
	cursor: default
	color: var(--color-neutral)
	text-align: center
	font-size: .9em
	width: min(360px, 100%)
	margin: 0 auto
	padding: 20px
	background-color: #fff
	border-radius: 10px
	box-shadow: $dropshadow

	&--transparent
		box-shadow: none
		background-color: transparent

	&__icon
		width: 120px
		margin-bottom: 16px

	&__title
		color: var(--color-neutral)
		font-size: 1.6rem
		line-height: 1.5
		margin-bottom: 0

	&__text
		font-size: 1.6rem
		line-height: 1.5
		margin-bottom: 0
		a
			color: var(--color-neutral-darker, #5D6A86)
			font-weight: 700
			text-decoration-line: underline
