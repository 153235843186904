.dialog {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  z-index: 1060;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  pointer-events: none;
  padding: 16px 0;

  &.active {
    pointer-events: all;
    opacity: 1;
  }

  &.active .content {
    transform: translateY(0);
    opacity: 1;
  }

  .content {
    margin-top: auto;
    margin-bottom: auto;
    width: 420px;
    max-width: 80vw;
    padding: 36px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05);
    transform: translateY(-100px);
    transition: all 0.2s ease-in-out 0.1s;
    opacity: 0;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .header {
    background: var(--color-primary-dark);
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05);
    border-radius: 4px;
    padding: 36px;
    color: white;
    text-align: center;
  }

  .title {
    color: var(--color-primary, #442b8c);
    font-size: 1.6rem;
    font-weight: 700;
  }

  .description {
    color: var(--color-neutral-darker, #5d6a86);
    font-size: 1.4rem;
    font-weight: 300;

    strong {
      font-weight: 700;
    }
  }

  .footer {
    text-align: center;
  }

  .btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    width: 80%;
    margin: 0 auto;

    .btn {
      flex-grow: 1;
      justify-content: center;
    }
  }
}
