.medal-wrapper
  padding: 10px 20px
  background: linear-gradient(9.59deg, #FFFFFF 20.02%, #F5F5F5 85.13%)
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
  border-radius: 10px
  display: flex
  flex-wrap: wrap
  align-items: center
  gap: 10px

  &__info
    margin-bottom: 0
    font-size: 1.4rem
    font-weight: 300
    color: var(--color-neutral-dark)
    strong
      font-weight: 700
      color: var(--color-primary)
      text-decoration: underline

  &__title
    display: block
    color: var(--color-neutral-dark)
    margin-bottom: 0
    font-weight: 700

.medal
  position: relative
  display: inline-block
  cursor: default
  height: 32px

  &--md
    height: 60px
    .medal__img
      min-width: 60px
    .medal__value
      font-size: 2.2rem

  &--gold
    height: 60px
    .medal__img
      min-width: 60px
      height: 60px
    .medal__value
      font-size: 2rem
      top: 10px

  &--sm
    height: 32px
    width: 32px
    .medal__img
      min-width: 32px
    .medal__value
      font-size: 1.4rem

  &--challenge
    height: 44px
    width: 44px
    .medal__img
      min-width: 44px
    .medal__value
      font-size: 2.2rem

  &--tia
    height: 22px
    width: 22px
    .medal__img
      min-width: 22px
    .medal__value
      font-size: 1.2rem
    svg
      min-width: 12px !important
      margin-top: 2px !important

  &__img
    width: 32px
    min-width: 32px
    display: block

  &__value
    position: absolute
    inset: 0
    display: flex
    justify-content: center
    align-items: center
    font-size: 1.6rem
    font-weight: 700
    color: #fff
    svg
      min-width: 16px
      margin-top: 4px

  &__stars
    width: 40px
    position: absolute
    right: 0
    bottom: 0
    translate: 70% 30%

.medal-stack
  display: flex
  align-items: center
  list-style: none
  min-width: 0
  cursor: pointer

  .medal
    cursor: pointer

  &__item
    min-width: 0
    max-width: 20px
    position: relative

    &:last-of-type
      max-width: initial

    &:not(:last-of-type)
      .medal::before
        content: ''
        position: absolute
        inset: 3px 3px 0 3px
        background-color: rgba(255,255,255, .6)
        border-radius: 50px

  &__img
    width: 56px
    position: absolute
    left: 0
