.schedule-alert
  text-align: center
  padding: 20px

  &--tia
    text-align: left
    padding: 0
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    gap: 16px

    .avatar
      flex-shrink: 0

  &__content
    width: 200px
    max-width: 100%
    flex-grow: 1

  &__header
    margin-bottom: 8px

  &__title
    margin-bottom: 0
    color: var(--color-primary, #442B8C)
    font-size: 1.4rem
    font-weight: 700

  &__close
    color: var(--color-primary, #442B8C)
    padding: 4px
    cursor: pointer
    position: absolute
    top: 20px
    right: 20px
    svg
      width: 16px
      display: block

  &__body
    color: var(--color-neutral, #8894AC)
    font-size: 1.4rem
    font-weight: 300
    margin-bottom: 32px
    p
      margin-bottom: 0

  &__footer
    display: flex
    flex-wrap: wrap
    gap: 20px
    .btn
      width: 120px
      flex-grow: 1

    .schedule-alert--tia &
      .btn
        flex-grow: 0
