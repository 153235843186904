.student-response
  &__header
    border-bottom: 1px solid var(--color-neutral-lighter)
    margin-bottom: 20px
    padding-bottom: 20px
    
  &__title
    font-weight: 700
    font-size: 1.6rem
    color: var(--color-primary-dark)
    margin-bottom: 0
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden