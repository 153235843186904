.react-calendar-wrapper
    display: flex
    flex-direction: column
    padding: 20px
    gap: 20px
    border-radius: 10px
    border: 1px solid var(--color-neutral-lightest, #ECE9F8)
    background: #FFF
    box-shadow: 0px 4px 10px 0px rgba(93, 106, 134, 0.05)

.react-calendar
    width: 350px
    max-width: 100%
    background: transparent
    border: 0
    font-family: "Nunito", sans-serif
    font-size: 1.2rem
    line-height: 1.125em

    .react-daterange-picker__calendar &
        background: #fff
        border: 1px solid var(--color-neutral)
        padding: 4px
        border-radius: 4px

    &--doubleView
        width: 700px

        &__viewContainer
            display: flex
            margin: -.5em

        > *
            width: 50%
            margin: .5em

    &, & *, & *:before, & *:after
        -moz-box-sizing: border-box
        -webkit-box-sizing: border-box
        box-sizing: border-box

    button
        margin: 0
        border: 0
        outline: none

        &:enabled
            &:hover
                background-color: var(--color-primary-lightest)
                cursor: pointer

    &__navigation
        display: flex
        height: 28px
        margin-bottom: 1em
        font-size: 1.4rem
        color: red

        button
            min-width: 44px
            background: none

            &:disabled
                background-color: rgb(240, 240, 240)

            &:enabled
                &:hover, &:focus
                    background-color: var(--color-primary-lightest)

        &__prev2-button, &__next2-button
            display: none

        &__label
            color: var(--color-primary)
            font-weight: 700
            text-transform: uppercase

            &:disabled
                background-color: transparent !important

        &__arrow
            border: 1px solid transparent !important
            border-radius: 4px
            color: var(--color-neutral)
            min-width: 28px !important
            height: 28px
            font-size: 24px

        &__prev-button, &__next-button
            display: flex
            align-items: center
            justify-content: center
            color: var(--color-neutral-darker)

    &__month-view
        &__weekdays
            text-align: center
            text-transform: uppercase
            font-weight: 700
            font-size: .75em

            &__weekday
                padding: .5em
                color: var(--color-primary)
                abbr
                    text-decoration: none

        &__weekNumbers

            &__tile
                display: flex
                align-items: center
                justify-content: center
                font-size: .75em
                font-weight: 700

        &__days
            gap: 4px

            &__day
                position: relative

                &.active::before
                    content: ''
                    width: 8px
                    height: 8px
                    background: #FD6B62
                    position: absolute
                    right: 1px
                    top: 1px
                    border-radius: 100px

                &.active-item::before
                    content: ''
                    width: 8px
                    height: 8px
                    position: absolute
                    right: 1px
                    top: 1px
                    border-radius: 100px

                &--weekend
                    color: var(--color-neutral-darker)

                &--neighboringMonth
                    color: rgb(153, 153, 153)

    &__year-view,
    &__decade-view,
    &__century-view

        &__tile
            padding: 2em .5em

    &__tile
        max-width: 100%
        padding: 6px
        background: #fff
        text-align: center
        line-height: 16px
        border: 1px solid transparent !important
        border-radius: 4px
        flex-basis: calc(14.2857% - 4px) !important
        max-width: calc(14.2857% - 4px) !important

        &:disabled
            background-color: rgb(240, 240, 240)

        &:enabled
            &:hover, &:focus
                background-color: rgb(230, 230, 230)

        &--now
            $bgcolor: lighten(rgb(220, 220, 0), 30%)
            background: $bgcolor

            &:enabled
                &:hover, &:focus
                    background: lighten($bgcolor, 10%)

        &--hasActive
            $bgcolor: lighten(rgb(0, 110, 220), 30%)
            background: $bgcolor

            &:enabled
                &:hover, &:focus
                    background: lighten($bgcolor, 10%)

        &--active
            $bgcolor: var(--color-primary)
            background: $bgcolor
            color: white
            border: 1px solid var(--color-neutral-darker) !important

            &:enabled
                &:hover, &:focus
                    background-color: var(--color-neutral) !important

    &--selectRange
        .react-calendar__tile
        &--hover
            background-color: rgb(230, 230, 230)

.bg-red::before
    background: #FD6B62

.bg-green::before
    background: var(--color-success)
