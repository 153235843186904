.study-block
  display: flex
  flex-direction: column
  gap: 16px

  &__header
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    gap: 16px

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 2.4rem
    line-height: 1.5
    font-weight: 700
    margin-bottom: 0

  &__btn
    appearance: none
    padding: 0
    background: none
    display: flex
    align-items: center
    gap: 8px
    color: var(--color-primary)
    font-size: 1.4rem
    font-weight: 300
    cursor: pointer
    svg
      flex-shrink: 0
      width: 24px
      stroke-width: 1px

  &__video
    iframe
      aspect-ratio: 16 / 9

  &__content
    display: flex
    flex-direction: column
    gap: 42px
    @media (min-width: $lg)
      flex-direction: row
      gap: 84px

  .nav-section
    flex-shrink: 0

    @media (min-width: $lg)
      width: 236px

  .section-article
    flex-grow: 1
