.dropdown {
  position: relative;
}

.content {
  position: absolute;
  top: calc(100% + 4px);
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 10px 3px rgba(93, 106, 134, 0.2);
  border-radius: 10px;
}

.role {
  display: flex;
  padding: 16px 20px;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--color-neutral-lightest, #ece9f8);
  background: #fff;
  cursor: pointer;
  color: var(--color-primary, #442b8c);
  font-size: 1.6rem;
  font-weight: 300;
  text-decoration: none;
  transition: 0.16s;

  &:hover,
  &:focus {
    background: var(--color-bright);
  }

  &:first-of-type {
    border-radius: 10px 10px 0px 0px;
    border-bottom: 0;
  }

  &:last-of-type {
    border-radius: 0px 0px 10px 10px;
  }
}
