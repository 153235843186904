.chart-performance
  border-radius: 5px
  padding: 4px 12px
  font-size: 1rem
  display: flex
  align-items: center
  gap: 8px
  cursor: pointer
  transition: all .15s

  &.active, &:hover
    background-color: var(--color-bright)

  &__name
    @extend .u-text-truncate
    display: inline-block
    width: 0
    flex-grow: 1
    color: #5D6A86

  &__bars, &__percentage-text, &__efficiency
    flex-shrink: 0

  &__bars
    display: inline-block
    width: 48px
    height: 4px
    background-color: #ECE9F8
    position: relative

  &__percentage-bar
    display: inline-block
    height: 4px
    background-color: var(--color-primary)
    position: absolute
    top: 0
    left: 0

  &__percentage-text, &__efficiency
    display: inline-block
    width: 28px
    text-align: center

  &__efficiency
    font-weight: 700
    width: 42px
    display: flex
    align-items: center
    justify-content: center
    gap: 4px

    svg
      flex-shrink: 0
      width: 8px

    &--down
      color: #FD6B62
      svg
        transform: rotate(180deg)

    &--up
      color: #6FCF97
