.react-daterange-picker
    display: inline-flex
    position: relative

    &,
    & *,
    & *:before,
    & *:after
        -moz-box-sizing: border-box
        -webkit-box-sizing: border-box
        box-sizing: border-box

    &--disabled
        background-color: #f0f0f0
        color: #6d6d6d

    &__wrapper
        height: 40px
        display: flex
        flex-grow: 1
        flex-shrink: 0
        align-items: center
        appearance: none
        border-radius: 5px
        border: 1px solid var(--color-primary-lightest)
        padding: 8px 12px
        background: white
        font-size: 13px!important

    &__inputGroup
        min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2)
        height: 100%
        flex-grow: 1
        padding: 0 2px
        box-sizing: content-box

    &__inputGroup__divider
        padding: 1px 0
        white-space: pre

    &__inputGroup__input
        min-width: 0.54em
        height: 100%
        position: relative
        padding: 0 1px
        border: 0
        background: none
        font: inherit
        box-sizing: content-box
        -moz-appearance: textfield

    &__inputGroup__input::-webkit-outer-spin-button,

    &__inputGroup__input::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0

    &__inputGroup__input:invalid
        background: rgba(255, 0, 0, 0.1)

    &__inputGroup__input--hasLeadingZero
        margin-left: -0.54em
        padding-left: calc(1px + 0.54em)

    &__button
        border: 0
        background: transparent
        padding: 4px 6px

    &__button:enabled
        cursor: pointer

    &__button:enabled:hover .react-daterange-picker__button__icon,

    &__button:enabled:focus .react-daterange-picker__button__icon
        stroke: #0078d7

    &__button:disabled .react-daterange-picker__button__icon
        stroke: #6d6d6d

    &__button svg
        display: inherit

    &__calendar
        width: 350px
        max-width: 100vw
        position: absolute
        top: 100%
        left: 0
        z-index: 1

    &__calendar--closed
        display: none

    &__calendar .react-calendar
        border-width: thin
