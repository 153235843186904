// --------------------------------------
//
//   #objects.containers
//   Width-limiting blocks
//
// --------------------------------------

@keyframes progress-p43u5e
  100%
    background-size: 100% 100%

@keyframes progress-p43u5e-2
  0%
    opacity: 0
  10%
    opacity: 0.15
  70%
    opacity: 0.15
  100%
    opacity: 0
    background-size: 100% 100%

.loader-text
  transform: translateY(20px)
  font-size: .85em
  font-weight: 700
  color: var(--color-primary-light)
  display: none

.loader-container
  @extend .fadeIn
  background: transparent
  min-height: 40px
  width: 100%
  +g(full, middle, center)

.loader
  border: 1px solid var(--color-neutral-lighter)
  width: 100px
  height: 12px
  border-radius: 100px
  background: linear-gradient(135deg, var(--color-bright) 0px,var(--color-primary-lightest) 100px) left/0% 100% no-repeat
  animation: progress-p43u5e 1.5s infinite
  position: relative

  &:after
    position: absolute
    border-radius: 100px
    content: ""
    left: 0px
    right: 0px
    top: 0px
    bottom: 0px
    opacity: 0
    background: repeating-linear-gradient(-45deg, rgba(#8894AC, 0),rgba(#8894AC, 0), 10px,#8894AC 10px,#8894AC 20px) left/0% 100% no-repeat
    animation: progress-p43u5e-2 3s infinite

// Spinner
@keyframes spinner
  100%
    transform: rotate(1turn)

.loader-spinner
  width: 32px
  aspect-ratio: 1
  border-radius: 50%
  background: radial-gradient(farthest-side,#8894AC 94%,#0000) top/4px 4px no-repeat, conic-gradient(#0000 30%,#8894AC)
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0)
  animation: spinner 1s infinite linear

  &-wrapper
    text-align: center
    display: flex
    flex-direction: column
    align-items: center
    gap: 20px

    &__text
      margin-bottom: 0
      color: var(--color-neutral-darker, #5D6A86)
      font-size: 1.6rem
      font-weight: 700
      line-height: 1.2
