.disclaimer-wrapper
    display: flex
    align-items: center
    margin-bottom: 40px
    padding-bottom: 22px

    & > .swiper-pagination
        bottom: 0 !important

        .swiper-pagination-bullet
            opacity: 1
            background-color: var(--color-primary-lightest, #ECE9F8)

            &.swiper-pagination-bullet-active
                background-color: var(--color-primary, #442B8C)

.disclaimer
    display: flex
    width: 100%
    background-color: #fff
    border-radius: 10px
    color: var(--color-danger, #FD6B62)
    align-items: center
    padding: 10px 20px
    gap: 1rem
    line-height: 1.2
    border: solid 1px #FF4C6F
    transition: .16s
    cursor: pointer

    &:hover, &:focus
        box-shadow: 0px 4px 10px 3px rgba(93, 106, 134, 0.2)

    &__title
        font-weight: 800
        color: var(--color-danger, #FD6B62)

    &__icon
        flex-shrink: 0
        width: 34px
        aspect-ratio: 1/1
        background-color: #fff
        border-radius: 100px
        color: var(--color-primary)
        display: flex
        align-items: center
        justify-content: center
        color: var(--color-danger, #FD6B62)
        border: 1px solid var(--color-neutral-lightest, #ECE9F8)
        box-shadow: 0px 4px 10px 3px rgba(93, 106, 134, 0.1)

        svg
            width: 20px

    &__content
        width: 0
        flex: 1
        display: flex
        flex-direction: column

    &__title, &__text
        @extend .u-text-truncate
        width: 100%
        margin-bottom: 0

    &__title
        font-weight: 700
        color: var(--color-danger, #FD6B62)
        font-size: 1.4rem

    &__text
        color: var(--color-danger, #FD6B62)
        font-size: 1.4rem
        font-weight: 300
