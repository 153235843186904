.notebook-sidebar
    &__nav
        position: relative

        .taxonomies__item
            background-color: white

        .filter-bar__back-container
            width: 100px

        .filter-bar__back
            width: 100%

    &__link
        appearance: none
        border: none
        background: transparent
        width: 100%
        font-size: 1.2rem
        text-align: left
        color: var(--color-neutral)
        display: flex
        align-items: center
        gap: 12px
        padding: 8px 16px
        border-radius: 50px

        &.is-selected
            background-color: var(--color-primary-lightest)
            color: var(--color-primary)
            font-weight: 800

    &__link-name
        @extend .u-text-truncate
        flex: 1

    &__count
        flex-shrink: 0
        font-weight: 300

.side-box
    flex-grow: 1
    display: flex
    flex-direction: column
    padding: 16px
    gap: 20px
    border-radius: 12px
    background: #fff
    box-shadow: $dropshadow-md

    &__header
        text-align: center

    &__img
        width: 108px

    &__body
        flex-grow: 1
        display: flex
        flex-direction: column
        gap: 16px

        p
            font-size: 1.4rem
            margin-bottom: 0
