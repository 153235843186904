.my-progress-widget
  display: flex
  flex-direction: column

  &__header
    display: flex
    align-items: center
    justify-content: space-between
    gap: 16px

  &__title
    margin-bottom: 0
    color: var(--color-neutral-darker, #5D6A86)
    font-size: 1.4rem
    font-weight: 300
