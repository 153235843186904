.lesson-widget
  padding: 12px
  border-radius: 12px
  border: 1px solid var(--color-neutral-lightest, #ECE9F8)
  background: #FFF
  box-shadow: $dropshadow
  min-height: 200px
  flex-grow: 1
  display: flex
  flex-direction: column
  gap: 12px

  &__header
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    gap: 16px
    border-radius: 4px
    background: #FFF
    box-shadow: $dropshadow
    padding: 12px
    border-bottom: 2px solid var(--color-neutral-lightest)

  &__title
    color: var(--color-primary, #442B8C)
    font-size: 1.6rem
    font-weight: 700
    margin-bottom: 0

  &__body
    height: 400px
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: flex-end

    .empty-state
      margin: auto

  &__messages
    padding: 12px
    overflow-y: auto
    display: flex
    flex-direction: column
    gap: 12px
    max-width: 100%

  &__divider
    display: flex
    padding: 4px 8px
    justify-content: center
    align-items: center
    gap: 4px
    border-radius: 4px
    background: var(--color-neutral-bright, #F1F5FA)
    margin-bottom: 0
    color: var(--color-neutral-dark, #5D6A86)
    font-size: 1.2rem
    svg
      width: 16px

  &__footer
    border-top: 1px solid var(--color-neutral-lightest)
    margin-top: 8px
    padding-top: 20px

  &__message-form
    display: flex
    align-items: flex-start
    gap: 8px

    .btn
      flex-shrink: 0

  &__rich-text-editor
    width: 100px
    flex-grow: 1
    display: flex
    flex-direction: column
    gap: 8px

  &__file
    display: flex
    align-items: center
    gap: 8px

    .form__custom-file-input
      flex-shrink: 0

    &-preview
      max-width: calc(100% - 124px)
      flex-shrink: 1
      display: flex
      align-items: center
      gap: 12px
      padding: 4px 8px
      border-radius: 4px
      border: 1px solid var(--color-neutral-lightest, #ECE9F8)
      background: #FFF
      color: var(--color-neutral-light, #BCC4D6)
      font-size: 1.2rem

      .card__icon-wrapper
        padding: 4px

    &-name
      @extend .u-text-truncate
      flex-shrink: 1
