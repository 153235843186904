
$tiny-blank-space: .5em
$small-blank-space: 1em
$medium-blank-space: 2em
$large-blank-space: 3em
$huge-blank-space: 6em

$color-black-light: #4A4A49

.modal_taxonomy
  position: fixed
  top: 0px
  left: 0px
  bottom: 0px
  right: 0px
  z-index: 1061
  +g(full, center, middle)
  animation: fadeIn ease-in-out .2s
  overflow-y: auto
  padding: 40px 0

  &__actions
    +i(10/10)
    margin-top: $medium-blank-space

  &--open
    background: rgba(black, .80)
  &--overlay
    background: rgba(black, .80)
  &--done
    background: rgba(black, 1)

  &__dismiss
    background: rgba(black, .80)
    position: absolute
    top: 0px
    left: 0px
    bottom: 0px
    right: 0px

  &__content
    z-index: 210
    background: white
    position: relative
    +i(11/12, md 7/12, lg 5/12)
    padding: $medium-blank-space
    +g(full, middle)
    +bp(md)
      padding: $medium-blank-space*1.5
      min-width: 625px
    border-radius: 5px

    &--open
      padding: 0

  &--warning
    .modal_taxonomy__title
      margin-bottom: 20px
    .modal_taxonomy__content
      +bp(md)
        min-width: inherit

  &--video
    .modal_taxonomy__content
      +i(12/12, md 8/12)
      padding: 0
      height: 450px
    .ember-view
      width: 100%
    iframe
      width: 100%
      height: 350px
      +bp(md)
        height: 450px

  &__title-text
    +i(1/1)

  &__title
    font-family: $title
    font-size: 1.1em
    font-weight: 300
    color: $color-black-light

  &__subtitle
    font-size: .9em
    font-weight: 300
    color: var(--color-neutral)
    span
      color: var(--color-secondary)
      cursor: pointer

  &__close
    float: right
    cursor: pointer
    display: block
    right: 30px
    position: absolute
    top: 30px

  &__header
    margin: -11px
    margin-bottom: $tiny-blank-space
    background: $color-black-light
    color: white
    font-size: .8em
    font-family: $title
    padding: 15px 0px 10px 25px
    border: none

  &__collumn
    +i(12/12, md 4/12)
    padding: $tiny-blank-space
    font-size: .9em
    font-weight: 300
    max-height: 360px
    overflow-x: scroll

    &__advice
      text-align: center
      width: 145px
      height: 60px
      z-index: 2
      margin: 50px auto 0

    &__advice-icon
      width: 25px
      height: 25px
      margin: 0 auto

    &__advice-text
      margin-top: $tiny-blank-space
      font-size: .85em
      color: var(--color-neutral)
      font-weight: 300
      a
        color: $color-black-light
        text-decoration: underline

  &__form, form
    margin: $tiny-blank-space auto $small-blank-space
    +i(12/12, md 10/12)
    textarea
      width: 100%
    &.full
      +i(12/12)

  &__body
    background: #f8f8f8
    border: 1px solid #E6E6E6
    border-radius: 5px
    position: relative
    overflow: hidden
    width: 100%
    min-height: 360px

  &__wrap
    width: 100%
    position: relative
    transition: all .2s ease-in-out
    left: 0
    +g(full)
    align-items: stretch
    @media (min-width: $md)
      position: absolute
      width: 150%
      &.active
        left: -50%

  &__body-collumn
    padding: $tiny-blank-space
    border-left: 1px solid #E6E6E6
    overflow-x: hidden
    &:first-child
      border-left: none

  &__back-button
    position: absolute
    top: 9px
    cursor: pointer
    display: none
    &.active
      display: block

  &__list-item
    width: 100%
    padding: 7px 6px
    padding-right: 10px
    border-radius: 5px
    margin: 1px 0
    &::after
      content: ''
      display: table
      clear: both
    &__icon
      width: 12px
      margin-right: 8px
      float: left
      cursor: pointer
      text-align: left
    &__text
      width: 90%
      display: inline-block
      float: left
    &__name
      float: left
      font-size: .85em
      cursor: pointer
      &.active
        color: var(--color-primary)
    &__arrow
      float: right
      transform: translateY(-10%)
      cursor: pointer
      svg
        height: 8px
    &:hover
      background: #EDEDED
    &.active
      background: #EDEDED

  &__warning
    background: var(--color-danger)
    color: white
    padding: $tiny-blank-space $small-blank-space
    margin: $small-blank-space 0
    border-radius: 3px

  &__cta
    text-align: center
    margin: $small-blank-space 0 0

  .login-form__footer
    margin-top: $medium-blank-space
    +i(10/12, md 6/12, lg 6/12)

.confirm-modal
  .modal_taxonomy__content
    +i(10/12, md 6/12, lg 4/12)

  .btn
    +i(5/12)
    float: left
    margin: $medium-blank-space $small-blank-space
    margin-bottom: 0px
    margin-left: 0px
