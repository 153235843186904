.metrics-shortcut
    padding: 20px
    border-radius: 10px
    display: flex
    flex-wrap: wrap-reverse
    justify-content: space-between
    gap: 16px
    border: 1px solid var(--color-neutral-lightest)
    box-shadow: $dropshadow
    min-height: 100px
    background-color: #fff

    &__content
        flex-grow: 1
        display: flex
        flex-direction: column
        justify-content: space-between
        width: 120px
        max-width: 100%

    .card__icon-wrapper
        flex-shrink: 0
        height: fit-content
        background-color: var(--color-bright)
        align-self: flex-end

.metrics-shortcut-wrapper
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 10px
