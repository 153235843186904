.question-card
	@extend .fadeIn
	+g(space-between)
	margin-bottom: 2em
	border-bottom: 1px solid var(--color-primary-lightest)
	padding: 1em
	border: 1px solid var(--color-bright)
	+bp(md)
		padding: 2em
	background: white
	border-radius: 10px
	box-shadow: $dropshadow
	+i(1/1)

	.tag-list
		margin-top: 0
		.tag
			margin-top: 0

	&__introduction
		font-weight: 800

	&__statement
		+i(1/1)
		color: var(--color-primary-dark)
		font-weight: 700
		border-top: 1px solid var(--color-primary-lightest)
		margin-top: 1em
		padding-top: 1em

	&__answers
		box-sizing: border-box
		margin-top: 1em
		padding: 1em
		padding-top: 0px
		font-size: 1.05em
		+i(1/1)
		i, .ck-editor i
			font-style: italic
		ul, ol
			padding: 0 30px !important
		ul
			list-style-type: disc
		ol
			list-style-type: decimal
		h2
			font-size: 2em
		h3
			font-size: 1.6em
		h4
			font-size: 1.2em

	&__answer
		+g(full, middle)
		flex-wrap: nowrap
		min-width: 0
		margin: 1em 0
		+i(1/1)

		label
			line-height: auto
			font-size: .88em
			line-height: 1.2em
			color: var(--color-neutral-darker)
			cursor: pointer

			&::first-letter
				text-transform: capitalize

			& > :last-child
				margin-bottom: 0

		input
			+i(.5/12)
			margin: 0
			margin-right: .3em
			height: 16px

		&--correct label, &--correct
			color: var(--color-secondary)

	&__answer-letter
		width: 15px
		font-size: .7em
		font-weight: 800
		transform: translate(-5px, 0)
		height: 15px

	&__name
		font-weight: 700

	&__role
		color: var(--color-neutral)

	&__title
		margin: 1em 0

	&__print
		color: var(--color-neutral)
		font-size: .8em
		font-style: italic

	&__description
		margin-top: 1em
		font-size: .9em

	&__motivator-text
		background: rgba(var(--color-bright), .5)
		padding: 1em
		+i(1/1)
		border: 1px solid var(--color-bright)
		margin: 1em 0
		font-size: .9em
		border-radius: 5px
		word-break: break-word
		p
			margin: 0
		img
			width: auto
			max-width: 100%
			max-height: 550px
		i, .ck-editor i
			font-style: italic
		ul, ol
			padding: 0 30px !important
		ul
			list-style-type: disc
		ol
			list-style-type: decimal
		h2
			font-size: 2em
		h3
			font-size: 1.6em
		h4
			font-size: 1.2em

	&__comment
		+i(1/1)
		background: white
		border-radius: 10px
		margin-top: 2em
		margin-bottom: 2em

	&__cta
		margin-bottom: 1em

	&__nav
		margin-top: 40px
		display: flex
		flex-wrap: wrap
		align-items: center
		justify-content: space-between
		gap: 16px
