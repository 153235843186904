.header
	display: flex
	align-items: center
	position: fixed
	inset: 0 0 auto 0
	z-index: 104
	padding: 4.2em 1em 1em 1em
	background: var(--color-neutral-bright)
	+bp(md)
		left: 64px
		padding: 36px 0 0 0
		height: 88px

	&__container
		display: flex
		align-items: center
		justify-content: flex-end
		width: 100%
		+bp(md)
			height: 52px
			margin: 0 45px
			border-bottom: 1px solid var(--color-primary-lightest)

	&__actions
		display: flex
		align-items: center
		gap: 20px
		margin-right: 36px
		@media (min-width: $sm)
			gap: 24px
		@media (min-width: 784px)
			margin-right: 0
