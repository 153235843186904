.result-challenge
  display: flex
  flex-wrap: wrap
  gap: 28px
  padding: 20px
  border-radius: 10px
  background: #fff
  box-shadow: 0px 4px 10px 3px rgba(93, 106, 134, 0.20)
  margin: 28px 0
  position: relative
  @media (min-width: $xl)
    flex-wrap: nowrap

  &__col
    flex-shrink: 0
    display: flex
    flex-direction: column
    max-width: 100%

    &:first-of-type
      flex-grow: 1
      flex-shrink: 1

  &__trophy
    width: 56px
    position: absolute
    transform: translate(-68%, -67%)

  &__hat, &__title
    margin-bottom: 0

  &__hat
    color: var(--color-neutral, #8894AC)
    font-size: 1.2rem
    font-weight: 700

  &__profile
    flex-grow: 1
    display: flex
    flex-wrap: wrap
    gap: 24px
    @media (min-width: $xl)
      flex-wrap: nowrap

  &__avatar
    flex-shrink: 0
    position: relative
    align-self: flex-start

  &__branch
    width: 100%
    position: absolute
    bottom: 0
    z-index: 1

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.8rem
    font-weight: 700

  &__info
    display: flex
    flex-direction: column
    justify-content: space-between
    gap: 16px

  &__description
    color: var(--color-eutral, #8894AC)
    font-size: 1.4rem
    font-weight: 700

    > *
      margin-bottom: 0

  &__kpi
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 5px 10px
    border-radius: 14px
    border: 1px solid var(--color-neutral-lightest, #ECE9F8)
    background: #FFF
    box-shadow: 0px 4px 10px 0px rgba(119, 119, 120, 0.15)
    width: 120px
    min-height: 156px

    .medal
      margin-bottom: 6px

    &--more
      background: #FAFAFA
      cursor: pointer

    &-wrapper
      display: flex
      flex-wrap: wrap
      gap: 12px

    &-title, &-value
      margin-bottom: 0

    &-title
      color: var(--color-neutral-dark, #5D6A86)
      text-align: center
      font-size: 1.4rem
      font-weight: 700

      .result-challenge__kpi--more &
        color: var(--color-primary)
        font-weight: 700

    &-value
      color: var(--color-primary)
      text-align: center
      font-size: 1.8rem
      font-weight: 700

      &--success
        color: var(--color-success)

      &--warning
        color: var(--color-warning)
