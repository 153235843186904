/// @group settings
/// @name global

// base border radius
$base-radius: 10px

body
  font-size: 14px
  font-weight: 300
  font-smoothing: subpixel-antialiased

img
  width: 100%

iframe
  margin-bottom: -6px !important

.clearfix
  +i(1/1)
