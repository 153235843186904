.home-content
	+g(space-between)
	width: 100%
	display: flex
	flex-direction: row

	&__banner
		min-height: 40vw
		+i(1/1)
		border-radius: 10px
		background-position: center
		background-size: cover
		margin: 1em 0 1em 0
		box-shadow: $dropshadow
		transition: all ease-in-out .2s
		&:hover
			transform: scale(1.01)
		+bp(md)
			border-radius: 20px
			min-height: 180px

	&__side-calendar
		@extend .fadeIn
		text-align: center
		background: white
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
		padding: 20px 0
		border-radius: 20px
		font-weight: 700

		&__section
			padding: 20px 40px

		&__expand_button
			margin-top: 10px
			padding: 10px 20px
			background: linear-gradient(147.6deg, #63B4FF 6.13%, #4470B2 88.97%)
			border-radius: 100px
			color: white
			cursor: pointer

	&__main-calendar
		@extend .fadeIn
		background: white
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
		padding: 20px 0
		border-radius: 20px
		width: 80%

		&__section
			padding: 20px 40px
			display: flex
			justify-content: space-between
			align-items: center

	&__higlight
		@extend .fadeIn
		text-align: center
		+bp(md)
			text-align: left

		+i(1/1, md 5/16)

		&__description
			color: var(--color-primary)
			font-size: 1.1em
			margin: 1em 0
			line-height: 1.3em

		&__body
			color: var(--color-neutral)
			line-height: 1.4em
			font-size: .9em

		&__cta
			margin-top: 2em

	&__feed
		margin-top: 2em
		+bp(md)
			margin-top: 0em
		@extend .fadeIn

		+i(1/1, md 10/16)
