.expanded-calendar
    @extend .fadeIn
    box-shadow: $dropshadow
    padding: 12px 12px 0 12px
    border-radius: 10px
    background-color: #fff
    min-height: calc( 100vh - 150px )
    transition: all ease-in-out .2s
    display: flex
    flex-direction: column
    .empty-state
        height: 550px
        box-shadow: none
        padding-top: 130px

    &__progress
        font-size: 1.2rem
        font-weight: 300
        color: var(--color-primary-lighter)
        display: flex
        align-items: center
        gap: 8px

        &-container
            flex-grow: 1
            height: 4px
            background-color: var(--color-neutral-lightest)

        &-bar
            height: 100%
            background-color: var(--color-primary)

        &-text
            flex-shrink: 0

    &__tags
        display: flex
        align-items: center
        flex-wrap: wrap
        gap: 8px

    &__tag
        @extend .u-text-truncate
        display: inline-block
        font-size: 1.2rem
        color: var(--color-neutral-dark)
        flex-grow: 0
        background-color: #ccc
        padding: 0 4px

        &--course
            background-color: #D6FDFF

        &--class
            background-color: #FFFCBE

        &--individual
            background-color: #EFEAFF

        &--bright
            background-color: #F1F5FA

        &--success
            background-color: var(--color-success-lightest)

    &__title
        display: block
        margin-bottom: 0
        text-align: center
        font-size: 1.8rem
        font-weight: 700
        color: var(--color-primary)
        padding-top: 20px

    &__header
        display: flex
        align-items: center
        justify-content: space-between
        gap: 12px
        padding: 20px 0
        position: relative
        border-bottom: 1px solid var(--color-neutral-lighter)

    &__btn
        @extend .btn--none
        padding: 8px 38px
        color: var(--color-neutral)
        display: block
        align-items: center
        gap: 8px
        transition: all ease-in-out .2s

        &--equal-fill
            padding: 8px

        &:hover
            color: var(--color-primary-dark)

        &:disabled
            pointer-events: none
            opacity: .24

        svg
            width: 16px

    &__btn-text
        @media (max-width: $sm)
            display: none

    &__plus-btn
        +i(1/1)
        background-image: url('../../images/icons/white-plus-icon.svg')
        background-position: center
        background-repeat: no-repeat
        background-size: 16px 16px
        border-radius: 5px
        height: 40px
        transition: all ease-in-out .2s
        background-color: var(--color-primary-lighter)
        cursor: pointer
        &:hover
            background-color: var(--color-primary)

    &__selected-week
        color: var(--color-primary-dark)
        font-weight: 700
        font-size: 14px
        margin-bottom: 0
        position: absolute
        left: 50%
        transform: translateX(-50%)

    &__body
        flex-grow: 1
        display: flex
        overflow: auto

    &__loader
        +i(1/1)

    &__day
        flex-grow: 1
        flex-shrink: 0
        width: 40%
        padding: 32px 4px
        border-left: 1px solid transparent
        border-right: 1px solid transparent
        @media (min-width: $lg)
            flex-basis: 0
            max-width: calc( 100% / 7 )

        &.current
            background-color: var(--color-bright)
            border-left-color: #EAEAEA
            border-right-color: #EAEAEA

    &__day-header
        display: flex
        align-items: center
        flex-wrap: wrap
        gap: 4px
        margin-bottom: 24px
        font-size: 1.2rem

    &__day-number
        color: var(--color-neutral-dark)
        font-weight: 700
        font-size: 1.2rem

        .expanded-calendar__day.current &
            color: var(--color-primary-dark)

    &__weekday
        color: var(--color-neutral)
        font-size: 1.2rem
        font-weight: 300

        &--current
            color: var(--color-primary-dark)
            font-weight: 300

    &__hours-container
        display: flex
        flex-direction: column
        gap: 10px

    &__hour
        min-height: 40px
        flex-grow: 1

    .home-content &
        width: 100%

.calendar-task
    @extend .btn--none
    width: 100%
    border: 1px solid var(--color-primary-lightest)
    border-right-width: 4px
    border-radius: 4px 0 0 4px
    padding: 30px 8px 8px 8px
    margin-left: 1px
    font-size: 1.1rem
    background-color: white
    box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05)
    display: flex
    flex-direction: column
    gap: 4px
    position: relative
    min-height: 45px
    outline: none
    position: relative

    &--teacher
        padding-top: 8px

    &__check-input
        appearance: none
        margin: 0
        position: absolute
        top: 8px
        left: 8px
        width: 16px
        height: 16px
        border: 1px solid var(--color-neutral)
        border-radius: 50%
        background-color: #E5E5E5
        transition: all ease-in-out .2s
        background-position: center
        cursor: pointer
        outline: none

        &--checked, &:checked
            border-color: var(--color-secondary)
            background-color: var(--color-secondary)
            background-repeat: no-repeat
            background-position: center
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>")
            background-size: 9px auto
            outline: none

        &--excess-tasks
            top: auto
            bottom: 8px

    &__container
        display: flex
        flex-direction: column

    &__header
        margin-bottom: 4px
        display: flex
        align-items: center
        gap: 4px

        .c-dropdown
            position: absolute
            top: 6px
            right: 8px

    &__title
        @extend .u-text-truncate
        flex-grow: 1
        line-height: 1
        color: var(--color-primary-dark)
        font-weight: 700
        display: block
        font-size: 1.2rem

    &__description
        flex-grow: 1
        display: block
        max-width: 100%
        font-weight: 300
        margin-bottom: 8px
        display: block

    &__description-text
        color: #000
        display: block
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
        text-overflow: ellipsis
        max-height: calc(2em * 1.5)

    &__time
        margin-top: -.5em
        display: flex
        align-items: center
        gap: 3px
        color: var(--color-neutral)

.calendar-dg
    text-align: left
    width: 100%

    &__data, &__day, &__head
        vertical-align: middle
        padding: 20px 16px

    &__day
        padding-bottom: 0

        &.current-day
            .expanded-calendar__day-number, .expanded-calendar__weekday
                color: var(--color-primary-dark)

            .expanded-calendar__weekday
                font-weight: 700

    &__current-day
        background-color: var(--color-bright)

    &__body
        .calendar-dg__row
            border-bottom: 1px solid var(--color-primary-lightest)

    &__head
        color: #000
        font-weight: 700
        font-size: 1.4rem
        display: flex
        align-items: center
        flex-wrap: wrap
        gap: 8px

        .avatar
            flex-shrink: 0

    &__tag
        font-weight: 300
        font-size: 1.4rem
        color: var(--color-primary-dark)
        padding: 1px 2px
        border-radius: 4px
        background-color: #eee

        &--success
            background-color: #D1FFD0

        &--warning
            background-color: #FFFCBE

        &--danger
            background-color: #FFCECE
