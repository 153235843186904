.essay-card {
  display: flex;
  gap: 8px;

  &-icon {
    background-color: #f1f5fa;
    padding: 4px;
    border-radius: 4px;
    flex-shrink: 0;
    align-self: flex-start;
  }

  &-info {
    color: #8894ac;
    font-size: 1.2rem;

    span {
      margin-left: 4px;
    }
  }
}

.timeline {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    border-top: 1px solid var(--color-neutral-lightest);
  }

  &::before {
    border-left: 1px dashed var(--color-neutral-light);
    bottom: 0;
    content: '';
    left: 22px;
    position: absolute;
    top: 0;
    width: 0;
    margin-top: 8px;
    margin-bottom: 40px;
    z-index: 0;
  }

  &-title {
    font-weight: 700;
    padding: 24px 0;
    display: block;
  }

  &-card {
    display: flex;
    color: var(--color-neutral-light);
    gap: 12px;
    font-size: 1.2rem;
    align-items: center;
    border-radius: 10px;
    padding: 8px;
    width: 100%;
    position: relative;
    cursor: pointer;

    &--active {
      background-color: var(--color-primary-lightest);
      color: var(--color-primary);
    }

    &-position {
      background-color: var(--color-neutral-light);
      color: #fff;
      padding: 8px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      align-self: flex-start;

      &--active {
        background-color: var(--color-primary);
      }
    }

    &-description {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &-score {
      background-color: #fff;
      border-radius: 10px;
      padding: 8px;
      margin-left: auto;
      text-align: center;

      &--success {
        color: var(--color-success);
      }

      &--warning {
        color: var(--color-warning);
      }
      &--danger {
        color: var(--color-danger);
      }

      strong {
        line-height: 2rem;
        font-size: 2rem;
        display: block;
      }
    }
  }
}
