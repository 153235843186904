.classroom-video-content
  +g(full, space-between)
  background-color: #fff
  border-radius: 10px
  padding: 12px
  box-shadow: $dropshadow
  border: 1px solid var(--color-neutral-lightest)

  &__video-frame
    border-radius: 10px
    overflow: hidden
    box-shadow: $dropshadow
    background: black
    iframe
      border-radius: 10px
      overflow: hidden
      width: 100%
      height: 100%
      box-shadow: $dropshadow
    +intrinsic-ratio

.classroom-video-content-sidebar
  flex-shrink: 0
  width: 100%
