.aside {
  width: 320px;

  &-competences {
    display: none;
    @media (min-width: 1280px) {
      display: block;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;

    P {
      color: var(--color-neutral-light);
      font-size: 1.2rem;
    }
  }
}

.page-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 44px;
  flex-wrap: wrap;
  @media (min-width: 1440px) {
    gap: 88px;
  }

  &__flex-start {
    justify-content: flex-start;
  }

  &-main {
    flex: 1;

    @media (max-width: 1024px) {
      flex: auto;
    }
  }
}

.new-send-btn {
  margin-bottom: 24px;
  display: block;
  width: 100%;
  font-weight: 700;

  svg {
    margin-right: 8px;
    height: 15px;
    width: 15px;
  }
}

.competences {
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(68, 43, 140, 0.05);
  border-radius: 10px;
  padding: 24px;

  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  &-card {
    border: 1px solid var(--color-neutral-lightest);
    max-width: 300px;
    padding: 24px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;

    &-score {
      font-size: 1.6rem;
      line-height: 28px;
      margin: 8px 0;
    }

    &-level {
      background-color: #fafafa;
      padding: 8px;
      border-radius: 10px;
      flex-grow: 1;

      span {
        font-weight: 700;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

.tia-container {
  display: flex;
  gap: 24px;
  justify-content: center;

  &-avatar {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
  }

  &-title {
    font-weight: 700;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      color: #46b0c3;
      margin-bottom: 0;
    }

    &-icon {
      width: 15px;
      height: 20px;
    }
  }

  &-text-balloon {
    flex-grow: 1;
    background-color: #fff;
    border-radius: 0 10px 10px 10px;
    padding: 12px 24px;
    position: relative;
    border: 1px solid var(--color-neutral-lightest);

    &::before,
    &::after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      border: 16px solid transparent;
      rotate: 136deg;
    }

    &::before {
      border-right-color: var(--color-neutral-lightest);
      top: -17px;
      left: -16px;
    }

    &::after {
      border-right-color: #fff;
      top: -16px;
      left: -14px;
    }
  }
}

.score-card {
  border: 2px solid var(--color-neutral-bright, #f1f5fa);
  background: linear-gradient(10deg, #fff 20.02%, #f5f5f5 85.13%);
  box-shadow: 0px 4px 10px 0px rgba(119, 119, 120, 0.15);
  padding: 32px;
  text-align: center;
  margin-bottom: 12px;
  border-radius: 10px;

  &-value {
    font-size: 3.5rem;
    font-weight: 800;

    &--success {
      color: var(--color-success);
    }

    &--warning {
      color: var(--color-warning);
    }
    &--danger {
      color: var(--color-danger);
    }
  }
}

.tab-only-xxl {
  display: none;

  @media (max-width: 1465px) {
    display: flex;
  }
}
