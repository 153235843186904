.answer-comment
  width: 100%
  border-top: 1px solid var(--color-neutral-lightest, #ECE9F8)
  padding-top: 20px

  &__header
    display: flex
    align-items: center
    justify-content: space-between
    gap: 16px
    margin-bottom: 20px

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.6rem
    font-weight: 700
    margin-bottom: 0

.comments-modal
  flex-grow: 1
  display: flex
  flex-direction: column
  gap: 20px

  &__header
    display: flex
    align-items: center
    justify-content: space-between
    gap: 20px
    padding-bottom: 8px
    border-bottom: 1px solid var(--color-neutral-lightest)

  &__title
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.6rem
    font-weight: 700

  &__count
    display: flex
    align-items: center
    gap: 4px
    font-size: 1.4rem
    font-weight: 700
    color: var(--color-neutral-dark)

    svg
      color: var(--color-neutral)
      width: 20px

  &__body
    height: 220px
    overflow-y: auto
    flex-grow: 1
    display: flex
    flex-direction: column

    .empty-state
      margin: auto

  &__pinned
    border-bottom: 1px solid var(--color-neutral-lightest)
    margin-bottom: 20px
    padding-bottom: 20px