.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(228px, 1fr));
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 16px;
  border-radius: 10px;
  border: 1px solid var(--color-neutral-lightest, #ece9f8);
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(93, 106, 134, 0.05);
  cursor: pointer;
  position: relative;
  transition: 0.3s;

  &:hover,
  &:focus {
    background: var(--color-bright);
  }
}

.tag {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.header {
  display: flex;
  max-width: 100%;
}

.title {
  color: var(--color-primary-dark, #110b3e);
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0;
}
