.panorama-section
  display: flex
  flex-direction: column
  gap: 40px
  z-index: 1

  &__header
    display: flex
    flex-wrap: wrap
    align-items: center
    width: 728px
    max-width: 100%
    padding: 16px 32px
    gap: 32px

  &__img
    flex-shrink: 0
    width: 128px

  &__title, &__description
    margin-bottom: 0

  &__title
    width: 200px
    max-width: 100%
    flex-grow: 1
    color: var(--color-primary-dark, #110B3E)
    font-size: 1.8rem
    font-weight: 700

  &__body
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    gap: 40px

  &__description
    color: var(--color-eutral-darker, #5D6A86)
    background: #fff
    font-size: 1.6rem
    font-weight: 300
    width: 200px
    max-width: 100%
    padding: 20px 0

  &__card-list
    flex-grow: 1
    display: flex
    flex-wrap: wrap
    gap: 20px
